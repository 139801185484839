import { Directive, Input } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../controller';

@Directive()
export class BaseViewItem<T = any> extends UnsubscribeOnDestroyAdapter {
  private _data: T;
  get data() {
    return this._data;
  }
  @Input() set data(data: T) {
    this._data = data;
    this.onSetData();
  }
  @Input() translationKeyPrefix: string;
  onSetData() {}
}
// namespace Modules {
//   export type ModuleAppsEnum =
//     | 'FRAMEWORK_APP'
//     | 'ISSUES_APP'
//     | 'RISK_APP'
//     | 'ENTITY_APP'
//     | 'USER_OPERATIONS_APP'
//     | 'DATA_STRUCTURE_APP'
//     | 'BRANDING_APP'
//     | 'ACKNOWLEDGMENT_APP'
//     | 'USER_APP'
//     | 'DOCUMENT_APP'
//     | 'AUDIT_APP'
//     | 'HUB_APP'
//     | 'TRANSPARENCY_APP'
//     | 'PERFORMANCE_APP'
//     | 'BCM_APP'
//     | 'RELATION_APP'
//     | 'QUESTION_APP'
//     | 'ADMIN_APP'
//     | 'CONFIG_APP'
//     | 'ALL';

//   export const ModuleAppsEnum = {
//     AdminApp: 'ADMIN_APP' as ModuleAppsEnum,
//     ConfigApp: 'CONFIG_APP' as ModuleAppsEnum,

//     FrameworkApp: 'FRAMEWORK_APP' as ModuleAppsEnum,
//     IssuesApp: 'ISSUES_APP' as ModuleAppsEnum,
//     RiskApp: 'RISK_APP' as ModuleAppsEnum,
//     EntityApp: 'ENTITY_APP' as ModuleAppsEnum,
//     UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleAppsEnum,
//     DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleAppsEnum,
//     BrandingApp: 'BRANDING_APP' as ModuleAppsEnum,
//     AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleAppsEnum,
//     UserApp: 'USER_APP' as ModuleAppsEnum,
//     DocumentApp: 'DOCUMENT_APP' as ModuleAppsEnum,
//     AuditApp: 'AUDIT_APP' as ModuleAppsEnum,
//     HubApp: 'HUB_APP' as ModuleAppsEnum,
//     TransparencyApp: 'TRANSPARENCY_APP' as ModuleAppsEnum,
//     PerformanceApp: 'PERFORMANCE_APP' as ModuleAppsEnum,
//     BcmApp: 'BCM_APP' as ModuleAppsEnum,
//     QuestionApp: 'QUESTION_APP' as ModuleAppsEnum,
//     RelationApp: 'RELATION_APP' as ModuleAppsEnum,
//     All: 'ALL' as ModuleAppsEnum,
//   };

//   export type ModuleKeywordsEnum =
//     | 'QUESTION'
//     | 'QUESTIONNAIRE'
//     | 'QUESTIONNAIRE_VALUE'
//     | 'MY_QUESTIONNAIRE_VALUE'
//     | 'RISK_ITEM'
//     | 'RISK_PROJECT'
//     | 'RISK_STATEMENT'
//     | 'RISK_STATEMENT_CATEGORY'
//     | 'EVENT'
//     | 'GEOGRAPHY'
//     | 'RESPONSIBILITY'
//     | 'ASSET'
//     | 'PROCESS'
//     | 'OBJECTIVE'
//     | 'ENTITY_TYPE'
//     | 'FILTER'
//     | 'POLICY'
//     | 'CONTROL'
//     | 'CONTROL_OBJECTIVE'
//     | 'CONTROL_GROUP'
//     | 'CONTROL_CATEGORY'
//     | 'CONTROL_PROCEDURE'
//     | 'CITATION_TYPE'
//     | 'CITATION'
//     | 'AUTHORITY_DOCUMENT'
//     | 'ACKNOWLEDGMENT_REQUEST'
//     | 'ACKNOWLEDGMENT'
//     | 'MY_TASK'
//     | 'TASK'
//     | 'ATTESTATION'
//     | 'EXCEPTION'
//     | 'ISSUE'
//     | 'ISSUE_TYPE'
//     | 'REMEDIATION'
//     | 'NOTIFICATION'
//     | 'MY_NOTIFICATION'
//     | 'RISK_METHODOLOGY'
//     | 'SUBSCRIPTION'
//     | 'POLICY_BODY'
//     | 'FIELD'
//     | 'RISK_ASSESSMENT_REQUEST'
//     | 'DASHBOARD'
//     | 'CRITERIA_FILTER'
//     | 'FIELD_SET'
//     | 'FORM_COMPONENT'
//     | 'RELATION_TYPE'
//     | 'ORGANIZATION'
//     | 'ENTITY_GROUP'
//     | 'EXPORT_PACKAGE'
//     | 'IMPORT_PACKAGE'
//     | 'EXEMPTION'
//     | 'SETTING'
//     | 'COMMENT'
//     | 'RELATION'
//     | 'RELATION_TYPE_ITEM'
//     | 'POLICY_BODY_SECTION'
//     | 'GLOBAL_VALUE_LIST'
//     | 'COMPLIANCE_REPORT_RESULT'
//     | 'COMPLIANCE_REPORT'
//     | 'AUDIT_TRAIL'
//     | 'CITATION_COMPLIANCE_REPORT_RESULT'
//     | 'CITATION_COMPLIANCE_REPORT'
//     | 'STATIC_VALUE'
//     | 'EMAIL_TEMPLATE'
//     | 'TASK_TEMPLATE'
//     | 'GROUP'
//     | 'USER'
//     | 'ROLE'
//     | 'MY_ACKNOWLEDGMENT'
//     | 'DOCUMENT'
//     | 'DOCUMENT_VERSION'
//     | 'BUCKET'
//     | 'AUDIT_UNIVERSE'
//     | 'AUDIT_PROGRAM'
//     | 'AUDIT_PROGRAM_TYPE'
//     | 'AUDIT_PROJECT'
//     | 'AUDIT_CASE'
//     | 'AUDIT_CASE_RESULT'
//     | 'AUDIT_CASE_TEMPLATE'
//     | 'AUDIT_REQUEST'
//     | 'AUDIT_PROJECT_REPORT'
//     | 'AUDIT_PROGRAM_REPORT'
//     | 'EXPORT_GLOBAL_PACKAGE'
//     | 'IMPORT_GLOBAL_PACKAGE'
//     | 'CONTENT_VIEW'
//     | 'RISK_VULNERABILITY'
//     | 'RISK_THREAT'
//     | 'REPORT'
//     | 'FIELD_TEMPLATE'
//     | 'USER_PREFERENCE'
//     | 'AUDIT_EXPENSE'
//     | 'LANGUAGE'
//     | 'TRANSPARENCY_CASE_CATEGORY'
//     | 'TRANSPARENCY_CASE'
//     | 'GENERATED_REPORT'
//     | 'PUBLISHED_POLICIES'
//     | 'GUIDELINE'
//     | 'STANDARD'
//     | 'POLICY_BODY_SECTION_TEMPLATE'
//     | 'PUBLISHED_GUIDELINE'
//     | 'PUBLISHED_STANDARD'
//     | 'STATISTIC_COLLECTOR'
//     | 'GENERATED_STATISTIC'
//     | 'REPORT_CONFIGURATION_TEMPLATE'
//     | 'REPORT_SCHEDULER'
//     | 'TAG'
//     | 'METRIC'
//     | 'METRIC_VALUE'
//     | 'METRIC_CATEGORY'
//     | 'ACTIVATED_PLAN'
//     | 'BIA_CAMPAIGN'
//     | 'ACTIVATED_RESPONSE_PROCEDURE'
//     | 'BIA_METHODOLOGY'
//     | 'CONTACT'
//     | 'DISRUPTIVE_INCIDENT'
//     | 'INCIDENT'
//     | 'INCIDENT_CATEGORY'
//     | 'INCIDENT_TYPE'
//     | 'INVESTIGATION'
//     | 'INVESTIGATION_EVIDENCE_REQUEST'
//     | 'INVESTIGATION_TYPE'
//     | 'INVOLVED_PEOPLE'
//     | 'LESSON_LEARNED'
//     | 'LOG_TYPE'
//     | 'NOTIFICATION_CALL_TREE'
//     | 'NOTIFICATION_HISTORY'
//     | 'PLAN'
//     | 'PLAN_TYPE'
//     | 'RECOVERY_STRATEGY'
//     | 'RECOVERY_TASK'
//     | 'RECOVERY_TOOL'
//     | 'RESPONSE_PROCEDURE'
//     | 'TESTING_EXERCISE'
//     | 'TESTING_TYPE'
//     | 'CAMPAIGN'
//     | 'QUESTIONNAIRE_REQUEST'
//     | 'CONTROL_GAP_REPORT_RESULT'
//     | 'CONTROL_GAP_REPORT'
//     | 'WORKSPACE'
//     | 'REMEDIATION_ACTION'
//     | 'QUESTIONNAIRE_TYPE'
//     | 'RISK_METHODOLOGY_IMPACT_FACTOR'
//     | 'RISK_METHODOLOGY_IMPACT_VALUE'
//     | 'RISK_METHODOLOGY_LIKELIHOOD_VALUE'
//     | 'RISK_METHODOLOGY_THRESHOLD_VALUE'
//     | 'DRAFT_RISK'
//     | 'GLOBAL_VALUE_LIST_ITEM'
//     | 'AUDIT_OBJECTIVE'
//     | 'REPORT_CONTROL_ITEM'
//     | 'REPORT_CONTROL_GROUP_ITEM'
//     | 'CONTROL_GAP_REPORT_RESPONSE'
//     | 'COMPLIANCE_REPORT_RESULT_ITEM'
//     | 'REPORT_CONTROL_OBJECTIVE_ITEM'
//     | 'JOB_LOCK'
//     | 'JOB_ERROR'
//     | 'EVIDENCE'
//     | 'ESCALATION_HISTORY'
//     | 'ESCALATION'
//     | 'RECORD_DOCUMENT'
//     | 'RISK_METHODOLOGY_IMPACT_FACTOR_OPTION'
//     | 'POLICY_BODY_TEMPLATE';

//   export const ModuleKeywordsEnum = {
//     Question: 'QUESTION' as ModuleKeywordsEnum,
//     Questionnaire: 'QUESTIONNAIRE' as ModuleKeywordsEnum,
//     QuestionnaireValue: 'QUESTIONNAIRE_VALUE' as ModuleKeywordsEnum,
//     MyQuestionnaireValue: 'MY_QUESTIONNAIRE_VALUE' as ModuleKeywordsEnum,
//     RiskItem: 'RISK_ITEM' as ModuleKeywordsEnum,
//     RiskProject: 'RISK_PROJECT' as ModuleKeywordsEnum,
//     RiskStatement: 'RISK_STATEMENT' as ModuleKeywordsEnum,
//     RiskStatementCategory: 'RISK_STATEMENT_CATEGORY' as ModuleKeywordsEnum,
//     Event: 'EVENT' as ModuleKeywordsEnum,
//     Geography: 'GEOGRAPHY' as ModuleKeywordsEnum,
//     Responsibility: 'RESPONSIBILITY' as ModuleKeywordsEnum,
//     Asset: 'ASSET' as ModuleKeywordsEnum,
//     Process: 'PROCESS' as ModuleKeywordsEnum,
//     Objective: 'OBJECTIVE' as ModuleKeywordsEnum,
//     EntityType: 'ENTITY_TYPE' as ModuleKeywordsEnum,
//     Filter: 'FILTER' as ModuleKeywordsEnum,
//     Policy: 'POLICY' as ModuleKeywordsEnum,
//     Control: 'CONTROL' as ModuleKeywordsEnum,
//     ControlObjective: 'CONTROL_OBJECTIVE' as ModuleKeywordsEnum,
//     ControlGroup: 'CONTROL_GROUP' as ModuleKeywordsEnum,
//     ControlCategory: 'CONTROL_CATEGORY' as ModuleKeywordsEnum,
//     ControlProcedure: 'CONTROL_PROCEDURE' as ModuleKeywordsEnum,
//     CitationType: 'CITATION_TYPE' as ModuleKeywordsEnum,
//     Citation: 'CITATION' as ModuleKeywordsEnum,
//     AuthorityDocument: 'AUTHORITY_DOCUMENT' as ModuleKeywordsEnum,
//     AcknowledgmentRequest: 'ACKNOWLEDGMENT_REQUEST' as ModuleKeywordsEnum,
//     Acknowledgment: 'ACKNOWLEDGMENT' as ModuleKeywordsEnum,
//     MyTask: 'MY_TASK' as ModuleKeywordsEnum,
//     Task: 'TASK' as ModuleKeywordsEnum,
//     Attestation: 'ATTESTATION' as ModuleKeywordsEnum,
//     Exception: 'EXCEPTION' as ModuleKeywordsEnum,
//     Issue: 'ISSUE' as ModuleKeywordsEnum,
//     IssueType: 'ISSUE_TYPE' as ModuleKeywordsEnum,
//     Remediation: 'REMEDIATION' as ModuleKeywordsEnum,
//     Notification: 'NOTIFICATION' as ModuleKeywordsEnum,
//     MyNotification: 'MY_NOTIFICATION' as ModuleKeywordsEnum,
//     RiskMethodology: 'RISK_METHODOLOGY' as ModuleKeywordsEnum,
//     Subscription: 'SUBSCRIPTION' as ModuleKeywordsEnum,
//     PolicyBody: 'POLICY_BODY' as ModuleKeywordsEnum,
//     Field: 'FIELD' as ModuleKeywordsEnum,
//     RiskAssessmentRequest: 'RISK_ASSESSMENT_REQUEST' as ModuleKeywordsEnum,
//     Dashboard: 'DASHBOARD' as ModuleKeywordsEnum,
//     CriteriaFilter: 'CRITERIA_FILTER' as ModuleKeywordsEnum,
//     FieldSet: 'FIELD_SET' as ModuleKeywordsEnum,
//     FormComponent: 'FORM_COMPONENT' as ModuleKeywordsEnum,
//     RelationType: 'RELATION_TYPE' as ModuleKeywordsEnum,
//     Organization: 'ORGANIZATION' as ModuleKeywordsEnum,
//     EntityGroup: 'ENTITY_GROUP' as ModuleKeywordsEnum,
//     ExportPackage: 'EXPORT_PACKAGE' as ModuleKeywordsEnum,
//     ImportPackage: 'IMPORT_PACKAGE' as ModuleKeywordsEnum,
//     Exemption: 'EXEMPTION' as ModuleKeywordsEnum,
//     Setting: 'SETTING' as ModuleKeywordsEnum,
//     Comment: 'COMMENT' as ModuleKeywordsEnum,
//     Relation: 'RELATION' as ModuleKeywordsEnum,
//     RelationTypeItem: 'RELATION_TYPE_ITEM' as ModuleKeywordsEnum,
//     PolicyBodySection: 'POLICY_BODY_SECTION' as ModuleKeywordsEnum,
//     GlobalValueList: 'GLOBAL_VALUE_LIST' as ModuleKeywordsEnum,
//     ComplianceReportResult: 'COMPLIANCE_REPORT_RESULT' as ModuleKeywordsEnum,
//     ComplianceReport: 'COMPLIANCE_REPORT' as ModuleKeywordsEnum,
//     AuditTrail: 'AUDIT_TRAIL' as ModuleKeywordsEnum,
//     CitationComplianceReportResult: 'CITATION_COMPLIANCE_REPORT_RESULT' as ModuleKeywordsEnum,
//     CitationComplianceReport: 'CITATION_COMPLIANCE_REPORT' as ModuleKeywordsEnum,
//     StaticValue: 'STATIC_VALUE' as ModuleKeywordsEnum,
//     EmailTemplate: 'EMAIL_TEMPLATE' as ModuleKeywordsEnum,
//     TaskTemplate: 'TASK_TEMPLATE' as ModuleKeywordsEnum,
//     Group: 'GROUP' as ModuleKeywordsEnum,
//     User: 'USER' as ModuleKeywordsEnum,
//     Role: 'ROLE' as ModuleKeywordsEnum,
//     MyAcknowledgment: 'MY_ACKNOWLEDGMENT' as ModuleKeywordsEnum,
//     Document: 'DOCUMENT' as ModuleKeywordsEnum,
//     DocumentVersion: 'DOCUMENT_VERSION' as ModuleKeywordsEnum,
//     Bucket: 'BUCKET' as ModuleKeywordsEnum,
//     AuditUniverse: 'AUDIT_UNIVERSE' as ModuleKeywordsEnum,
//     AuditProgram: 'AUDIT_PROGRAM' as ModuleKeywordsEnum,
//     AuditProgramType: 'AUDIT_PROGRAM_TYPE' as ModuleKeywordsEnum,
//     AuditProject: 'AUDIT_PROJECT' as ModuleKeywordsEnum,
//     AuditCase: 'AUDIT_CASE' as ModuleKeywordsEnum,
//     AuditCaseResult: 'AUDIT_CASE_RESULT' as ModuleKeywordsEnum,
//     AuditCaseTemplate: 'AUDIT_CASE_TEMPLATE' as ModuleKeywordsEnum,
//     AuditRequest: 'AUDIT_REQUEST' as ModuleKeywordsEnum,
//     AuditProjectReport: 'AUDIT_PROJECT_REPORT' as ModuleKeywordsEnum,
//     AuditProgramReport: 'AUDIT_PROGRAM_REPORT' as ModuleKeywordsEnum,
//     ExportGlobalPackage: 'EXPORT_GLOBAL_PACKAGE' as ModuleKeywordsEnum,
//     ImportGlobalPackage: 'IMPORT_GLOBAL_PACKAGE' as ModuleKeywordsEnum,
//     ContentView: 'CONTENT_VIEW' as ModuleKeywordsEnum,
//     RiskVulnerability: 'RISK_VULNERABILITY' as ModuleKeywordsEnum,
//     RiskThreat: 'RISK_THREAT' as ModuleKeywordsEnum,
//     Report: 'REPORT' as ModuleKeywordsEnum,
//     FieldTemplate: 'FIELD_TEMPLATE' as ModuleKeywordsEnum,
//     UserPreference: 'USER_PREFERENCE' as ModuleKeywordsEnum,
//     AuditExpense: 'AUDIT_EXPENSE' as ModuleKeywordsEnum,
//     Language: 'LANGUAGE' as ModuleKeywordsEnum,
//     TransparencyCaseCategory: 'TRANSPARENCY_CASE_CATEGORY' as ModuleKeywordsEnum,
//     TransparencyCase: 'TRANSPARENCY_CASE' as ModuleKeywordsEnum,
//     GeneratedReport: 'GENERATED_REPORT' as ModuleKeywordsEnum,
//     PublishedPolicies: 'PUBLISHED_POLICIES' as ModuleKeywordsEnum,
//     Guideline: 'GUIDELINE' as ModuleKeywordsEnum,
//     Standard: 'STANDARD' as ModuleKeywordsEnum,
//     PolicyBodySectionTemplate: 'POLICY_BODY_SECTION_TEMPLATE' as ModuleKeywordsEnum,
//     PublishedGuideline: 'PUBLISHED_GUIDELINE' as ModuleKeywordsEnum,
//     PublishedStandard: 'PUBLISHED_STANDARD' as ModuleKeywordsEnum,
//     StatisticCollector: 'STATISTIC_COLLECTOR' as ModuleKeywordsEnum,
//     GeneratedStatistic: 'GENERATED_STATISTIC' as ModuleKeywordsEnum,
//     ReportConfigurationTemplate: 'REPORT_CONFIGURATION_TEMPLATE' as ModuleKeywordsEnum,
//     ReportScheduler: 'REPORT_SCHEDULER' as ModuleKeywordsEnum,
//     Tag: 'TAG' as ModuleKeywordsEnum,
//     Metric: 'METRIC' as ModuleKeywordsEnum,
//     MetricValue: 'METRIC_VALUE' as ModuleKeywordsEnum,
//     MetricCategory: 'METRIC_CATEGORY' as ModuleKeywordsEnum,
//     ActivatedPlan: 'ACTIVATED_PLAN' as ModuleKeywordsEnum,
//     BiaCampaign: 'BIA_CAMPAIGN' as ModuleKeywordsEnum,
//     ActivatedResponseProcedure: 'ACTIVATED_RESPONSE_PROCEDURE' as ModuleKeywordsEnum,
//     BiaMethodology: 'BIA_METHODOLOGY' as ModuleKeywordsEnum,
//     Contact: 'CONTACT' as ModuleKeywordsEnum,
//     DisruptiveIncident: 'DISRUPTIVE_INCIDENT' as ModuleKeywordsEnum,
//     Incident: 'INCIDENT' as ModuleKeywordsEnum,
//     IncidentCategory: 'INCIDENT_CATEGORY' as ModuleKeywordsEnum,
//     IncidentType: 'INCIDENT_TYPE' as ModuleKeywordsEnum,
//     Investigation: 'INVESTIGATION' as ModuleKeywordsEnum,
//     InvestigationEvidenceRequest: 'INVESTIGATION_EVIDENCE_REQUEST' as ModuleKeywordsEnum,
//     InvestigationType: 'INVESTIGATION_TYPE' as ModuleKeywordsEnum,
//     InvolvedPeople: 'INVOLVED_PEOPLE' as ModuleKeywordsEnum,
//     LessonLearned: 'LESSON_LEARNED' as ModuleKeywordsEnum,
//     LogType: 'LOG_TYPE' as ModuleKeywordsEnum,
//     NotificationCallTree: 'NOTIFICATION_CALL_TREE' as ModuleKeywordsEnum,
//     NotificationHistory: 'NOTIFICATION_HISTORY' as ModuleKeywordsEnum,
//     Plan: 'PLAN' as ModuleKeywordsEnum,
//     PlanType: 'PLAN_TYPE' as ModuleKeywordsEnum,
//     RecoveryStrategy: 'RECOVERY_STRATEGY' as ModuleKeywordsEnum,
//     RecoveryTask: 'RECOVERY_TASK' as ModuleKeywordsEnum,
//     RecoveryTool: 'RECOVERY_TOOL' as ModuleKeywordsEnum,
//     ResponseProcedure: 'RESPONSE_PROCEDURE' as ModuleKeywordsEnum,
//     TestingExercise: 'TESTING_EXERCISE' as ModuleKeywordsEnum,
//     TestingType: 'TESTING_TYPE' as ModuleKeywordsEnum,
//     Campaign: 'CAMPAIGN' as ModuleKeywordsEnum,
//     QuestionnaireRequest: 'QUESTIONNAIRE_REQUEST' as ModuleKeywordsEnum,
//     ControlGapReportResult: 'CONTROL_GAP_REPORT_RESULT' as ModuleKeywordsEnum,
//     ControlGapReport: 'CONTROL_GAP_REPORT' as ModuleKeywordsEnum,
//     Workspace: 'WORKSPACE' as ModuleKeywordsEnum,
//     RemediationAction: 'REMEDIATION_ACTION' as ModuleKeywordsEnum,
//     QuestionnaireType: 'QUESTIONNAIRE_TYPE' as ModuleKeywordsEnum,
//     RiskMethodologyImpactFactor: 'RISK_METHODOLOGY_IMPACT_FACTOR' as ModuleKeywordsEnum,
//     RiskMethodologyImpactValue: 'RISK_METHODOLOGY_IMPACT_VALUE' as ModuleKeywordsEnum,
//     RiskMethodologyLikelihoodValue: 'RISK_METHODOLOGY_LIKELIHOOD_VALUE' as ModuleKeywordsEnum,
//     RiskMethodologyThresholdValue: 'RISK_METHODOLOGY_THRESHOLD_VALUE' as ModuleKeywordsEnum,
//     DraftRisk: 'DRAFT_RISK' as ModuleKeywordsEnum,
//     GlobalValueListItem: 'GLOBAL_VALUE_LIST_ITEM' as ModuleKeywordsEnum,
//     AuditObjective: 'AUDIT_OBJECTIVE' as ModuleKeywordsEnum,
//     ReportControlItem: 'REPORT_CONTROL_ITEM' as ModuleKeywordsEnum,
//     ReportControlGroupItem: 'REPORT_CONTROL_GROUP_ITEM' as ModuleKeywordsEnum,
//     ControlGapReportResponse: 'CONTROL_GAP_REPORT_RESPONSE' as ModuleKeywordsEnum,
//     ComplianceReportResultItem: 'COMPLIANCE_REPORT_RESULT_ITEM' as ModuleKeywordsEnum,
//     ReportControlObjectiveItem: 'REPORT_CONTROL_OBJECTIVE_ITEM' as ModuleKeywordsEnum,
//     JobLock: 'JOB_LOCK' as ModuleKeywordsEnum,
//     JobError: 'JOB_ERROR' as ModuleKeywordsEnum,
//     Evidence: 'EVIDENCE' as ModuleKeywordsEnum,
//     EscalationHistory: 'ESCALATION_HISTORY' as ModuleKeywordsEnum,
//     Escalation: 'ESCALATION' as ModuleKeywordsEnum,
//     RecordDocument: 'RECORD_DOCUMENT' as ModuleKeywordsEnum,
//     RiskMethodologyImpactFactorOption: 'RISK_METHODOLOGY_IMPACT_FACTOR_OPTION' as ModuleKeywordsEnum,
//     PolicyBodyTemplate: 'POLICY_BODY_TEMPLATE' as ModuleKeywordsEnum,
//   };
// }
// // const ModuleKeywords = { ...Modules.ModuleKeywordsEnum };
// // // Define the final type
// // type ModuleKeywordsType = (typeof ModuleKeywords)[keyof typeof ModuleKeywords];

// // export type ModuleKeywords = ModuleKeywordsType;

// // export { ModuleKeywords };

// const ModuleApps = { ...Modules.ModuleAppsEnum };
// // Define the final type
// type ModuleAppsType = (typeof ModuleApps)[keyof typeof ModuleApps];

// export type ModuleApps = ModuleAppsType;

// export { ModuleApps };

// const modules = {
//   [ModuleApps.QuestionApp]: {
//     QST: ModuleKeywords.Question,
//     QSR: ModuleKeywords.Questionnaire,
//     QSV: ModuleKeywords.QuestionnaireValue,
//     MQV: ModuleKeywords.MyQuestionnaireValue,
//     CAM: ModuleKeywords.Campaign,
//     QRQ: ModuleKeywords.QuestionnaireRequest,
//     QTY: ModuleKeywords.QuestionnaireType,
//   },
//   [ModuleApps.RiskApp]: {
//     RIT: ModuleKeywords.RiskItem,
//     RPR: ModuleKeywords.RiskProject,
//     RST: ModuleKeywords.RiskStatement,
//     RSC: ModuleKeywords.RiskStatementCategory,
//     RIV: ModuleKeywords.RiskMethodologyImpactValue,
//     RLV: ModuleKeywords.RiskMethodologyLikelihoodValue,
//     RTV: ModuleKeywords.RiskMethodologyThresholdValue,
//     DRI: ModuleKeywords.DraftRisk,
//     RIF: ModuleKeywords.RiskMethodologyImpactFactor,
//     RAR: ModuleKeywords.RiskAssessmentRequest,
//     VUL: ModuleKeywords.RiskVulnerability,
//     THR: ModuleKeywords.RiskThreat,
//   },
//   [ModuleApps.EntityApp]: {
//     EVE: ModuleKeywords.Event,
//     GEO: ModuleKeywords.Geography,
//     RES: ModuleKeywords.Responsibility,
//     AST: ModuleKeywords.Asset,
//     PRO: ModuleKeywords.Process,
//     OBJ: ModuleKeywords.Objective,
//     ETY: ModuleKeywords.EntityType,
//     ORG: ModuleKeywords.Organization,
//     EGB: ModuleKeywords.EntityGroup,
//   },
//   [ModuleApps.DataStructureApp]: {
//     FIL: ModuleKeywords.Filter,
//     DSH: ModuleKeywords.Dashboard,
//     CFI: ModuleKeywords.CriteriaFilter,
//     FST: ModuleKeywords.FieldSet,
//     FCO: ModuleKeywords.FormComponent,
//     RTY: ModuleKeywords.RelationType,
//     CVW: ModuleKeywords.ContentView,
//     GVL: ModuleKeywords.GlobalValueList,
//     RCT: ModuleKeywords.ReportConfigurationTemplate,
//     RSR: ModuleKeywords.ReportScheduler,
//     TAG: ModuleKeywords.Tag,
//     WSP: ModuleKeywords.Workspace,
//     FLD: ModuleKeywords.Field,
//     LGT: ModuleKeywords.LogType,
//     LNG: ModuleKeywords.Language,
//     GRE: ModuleKeywords.GeneratedReport,
//     GST: ModuleKeywords.GeneratedStatistic,
//     SCO: ModuleKeywords.StatisticCollector,
//     CRR: ModuleKeywords.ComplianceReportResult,
//     CRE: ModuleKeywords.ComplianceReport,
//     CCR: ModuleKeywords.CitationComplianceReportResult,
//     CCE: ModuleKeywords.CitationComplianceReport,
//     RCI: ModuleKeywords.ReportControlItem,
//     RCG: ModuleKeywords.ReportControlGroupItem,
//     GVI: ModuleKeywords.GlobalValueListItem,
//     ROB: ModuleKeywords.ReportControlObjectiveItem,
//     RDO: ModuleKeywords.RecordDocument,
//   },
//   [ModuleApps.FrameworkApp]: {
//     POL: ModuleKeywords.Policy,
//     CON: ModuleKeywords.Control,
//     COB: ModuleKeywords.ControlObjective,
//     CGR: ModuleKeywords.ControlGroup,
//     CCA: ModuleKeywords.ControlCategory,
//     CPR: ModuleKeywords.ControlProcedure,
//     CTY: ModuleKeywords.CitationType,
//     CIT: ModuleKeywords.Citation,
//     AUD: ModuleKeywords.AuthorityDocument,
//     ATS: ModuleKeywords.Attestation,
//     EXP: ModuleKeywords.Exception,
//     GAR: ModuleKeywords.ControlGapReport,
//     GRR: ModuleKeywords.ControlGapReportResult,
//     PBS: ModuleKeywords.PolicyBodySection,
//     PBD: ModuleKeywords.PolicyBody,
//     PTE: ModuleKeywords.PolicyBodyTemplate,
//     GUI: ModuleKeywords.Guideline,
//     STA: ModuleKeywords.Standard,
//     PBT: ModuleKeywords.PolicyBodySectionTemplate,
//     PGU: ModuleKeywords.PublishedGuideline,
//     PST: ModuleKeywords.PublishedStandard,
//     AOB: ModuleKeywords.AuditObjective,
//     EVI: ModuleKeywords.Evidence,
//     FTE: ModuleKeywords.FieldTemplate,
//   },
//   [ModuleApps.AcknowledgmentApp]: {
//     AKR: ModuleKeywords.AcknowledgmentRequest,
//     ACK: ModuleKeywords.Acknowledgment,
//     MAK: ModuleKeywords.MyAcknowledgment,
//   },
//   [ModuleApps.UserOperationsApp]: {
//     MTK: ModuleKeywords.MyTask,
//     TSK: ModuleKeywords.Task,
//     NOT: ModuleKeywords.Notification,
//     MNO: ModuleKeywords.MyNotification,
//     SUB: ModuleKeywords.Subscription,
//     UPR: ModuleKeywords.UserPreference,
//     ADT: ModuleKeywords.AuditTrail,
//     ETM: ModuleKeywords.EmailTemplate,
//     TTM: ModuleKeywords.TaskTemplate,
//   },
//   [ModuleApps.IssuesApp]: {
//     ISS: ModuleKeywords.Issue,
//     IST: ModuleKeywords.IssueType,
//     RMD: ModuleKeywords.Remediation,
//     EXM: ModuleKeywords.Exemption,
//     ESC: ModuleKeywords.Escalation,
//     ESH: ModuleKeywords.EscalationHistory,
//     RAC: ModuleKeywords.RemediationAction,
//   },
//   [ModuleApps.AuditApp]: {
//     AUU: ModuleKeywords.AuditUniverse,
//     APG: ModuleKeywords.AuditProgram,
//     APT: ModuleKeywords.AuditProgramType,
//     APJ: ModuleKeywords.AuditProject,
//     ACS: ModuleKeywords.AuditCase,
//     ACR: ModuleKeywords.AuditCaseResult,
//     ACT: ModuleKeywords.AuditCaseTemplate,
//     ARE: ModuleKeywords.AuditRequest,
//     APR: ModuleKeywords.AuditProjectReport,
//     ARR: ModuleKeywords.AuditProgramReport,
//     AEX: ModuleKeywords.AuditExpense,
//   },
//   [ModuleApps.HubApp]: {
//     EGP: ModuleKeywords.ExportGlobalPackage,
//     IGP: ModuleKeywords.ImportGlobalPackage,
//   },
//   [ModuleApps.BcmApp]: {
//     APL: ModuleKeywords.ActivatedPlan,
//     BIC: ModuleKeywords.BiaCampaign,
//     ARP: ModuleKeywords.ActivatedResponseProcedure,
//     BIA: ModuleKeywords.BiaMethodology,
//     CNT: ModuleKeywords.Contact,
//     DIN: ModuleKeywords.DisruptiveIncident,
//     IND: ModuleKeywords.Incident,
//     INC: ModuleKeywords.IncidentCategory,
//     INT: ModuleKeywords.IncidentType,
//     INV: ModuleKeywords.Investigation,
//     IER: ModuleKeywords.InvestigationEvidenceRequest,
//     IVT: ModuleKeywords.InvestigationType,
//     INP: ModuleKeywords.InvolvedPeople,
//     LEL: ModuleKeywords.LessonLearned,
//     NCT: ModuleKeywords.NotificationCallTree,
//     NHI: ModuleKeywords.NotificationHistory,
//     PLN: ModuleKeywords.Plan,
//     PLT: ModuleKeywords.PlanType,
//     RCS: ModuleKeywords.RecoveryStrategy,
//     RCT: ModuleKeywords.RecoveryTask,
//     RCO: ModuleKeywords.RecoveryTool,
//     RCD: ModuleKeywords.ResponseProcedure,
//     TEX: ModuleKeywords.TestingExercise,
//     TET: ModuleKeywords.TestingType,
//   },
//   [ModuleApps.TransparencyApp]: {
//     TCC: ModuleKeywords.TransparencyCaseCategory,
//     TCA: ModuleKeywords.TransparencyCase,
//   },
//   [ModuleApps.PerformanceApp]: {
//     MTR: ModuleKeywords.Metric,
//     MTV: ModuleKeywords.MetricValue,
//     MTC: ModuleKeywords.MetricCategory,
//   },
//   [ModuleApps.BrandingApp]: {
//     STV: ModuleKeywords.StaticValue,
//   },
//   [ModuleApps.DocumentApp]: {
//     DOC: ModuleKeywords.Document,
//     DOV: ModuleKeywords.DocumentVersion,
//     BUK: ModuleKeywords.Bucket,
//   },
//   [ModuleApps.RelationApp]: {
//     CMT: ModuleKeywords.Comment,
//     REL: ModuleKeywords.Relation,
//     RTI: ModuleKeywords.RelationTypeItem,
//   },
//   [ModuleApps.All]: {
//     FLD: ModuleKeywords.Field,
//     EPK: ModuleKeywords.ExportPackage,
//     IKG: ModuleKeywords.ImportPackage,
//     JOB_LOCK: ModuleKeywords.JobLock,
//     JOB_ERROR: ModuleKeywords.JobError,
//   },
//   [ModuleApps.UserApp]: {
//     GRP: ModuleKeywords.Group,
//     USR: ModuleKeywords.User,
//     CLI: ModuleKeywords.Role,
//   },
// };
// const toBeDeleted = {
//   EGB: ModuleKeywords.EntityGroup, // deleted
//   CCR: ModuleKeywords.CitationComplianceReportResult,
//   CCE: ModuleKeywords.CitationComplianceReport,
// }
// const uncertainCodes = {

//   FLD: ModuleKeywords.Field, //ds / All

//   LGT: ModuleKeywords.LogType,

//   RCI: ModuleKeywords.ReportControlItem, //No page
//   RCG: ModuleKeywords.ReportControlGroupItem, //No page
//   GVI: ModuleKeywords.GlobalValueListItem,
//   ROB: ModuleKeywords.ReportControlObjectiveItem, //No page

//   RDO: ModuleKeywords.RecordDocument, //future

//   GAR: ModuleKeywords.ControlGapReport, //has page
//   GRR: ModuleKeywords.ControlGapReportResult, //has page

//   UPR: ModuleKeywords.UserPreference, //no page

//   ESC: ModuleKeywords.Escalation,//has page
//   ESH: ModuleKeywords.EscalationHistory,//has page

//   DOV: ModuleKeywords.DocumentVersion,//future
//   BUK: ModuleKeywords.Bucket,//future

//   JOB_LOCK: ModuleKeywords.JobLock, // no use for front
//   JOB_ERROR: ModuleKeywords.JobError, // no use for front
// }
