import { Injectable } from '@angular/core';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root'
})
export class RiskMethodologyThresholdValueMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Value',
      key: 'value',
      dataType: DataTypeEnum.Number,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
    {
      name: 'Color',
      key: 'color',
      dataType: DataTypeEnum.Text,
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      required: false,
      showInTable: true
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
