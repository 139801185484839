import { Directive, Input } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../controller';
import { getFileViewerType } from '../helpers';
import { FileViewerType } from './view-enums';

@Directive()
export class BaseFileViewerItem extends UnsubscribeOnDestroyAdapter {
  fileViewerType: FileViewerType;
  private _data: FileViewerItem;
  get data() {
    return this._data;
  }
  @Input() set data(data: FileViewerItem) {
    this._data = data;
    this.fileViewerType = getFileViewerType(data?.fileName);
    this.onSetData();
  }
  onSetData() { }
}
export interface FileViewerItem {
  file: Blob;
  fileName: string;
}