/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseExceptionEvent { 
    status?: number;
    data?: ApiResponseExceptionEvent.DataEnum;
    error?: boolean;
    message?: string;
}
export namespace ApiResponseExceptionEvent {
    export type DataEnum = 'TO_APPROVED' | 'TO_EXCEPTED' | 'TO_CANCELLED' | 'TO_ENDED' | 'TO_STARTED' | 'TO_SUBMITTED' | 'TO_END';
    export const DataEnum = {
        Approved: 'TO_APPROVED' as DataEnum,
        Excepted: 'TO_EXCEPTED' as DataEnum,
        Cancelled: 'TO_CANCELLED' as DataEnum,
        Ended: 'TO_ENDED' as DataEnum,
        Started: 'TO_STARTED' as DataEnum,
        Submitted: 'TO_SUBMITTED' as DataEnum,
        End: 'TO_END' as DataEnum
    };
}


