import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  RiskItemDto,
  TargetTypeEnum,
  getEnumOptions
} from '@shared/classes';
import { RiskMeterBarComponent } from '@shared/components/components/risk-meter-bar/risk-meter-bar.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class DraftRiskMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Strategy',
      key: 'strategy',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Strategy of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RiskItemDto.StrategyEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(RiskItemDto.StrategyEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RiskItemDto.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(RiskItemDto.StatusEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Entity',
      key: 'entityId',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Entity linked to the record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Project',
      key: 'project',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadRiskProject,
      description: 'Project that the risk belongs to',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.RiskProject,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['RISK_PROJECT'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Methodology',
      key: 'methodology',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Risk Methodology linked to the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    {
      name: 'Inherited Likelihood',
      key: 'inheritedLikelihoodDto.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Inherited Impact',
      key: 'inheritedRiskImpactDto.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Inherited',
      key: 'inheritedRisk',
      dataType: DataTypeEnum.RiskItemCell,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        options: {}, //TODO: fix risk item cell component to request the methodology on it's own similar to CodeLink component
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'gte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: RiskMeterBarComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Inherited Classification',
      key: 'inheritedClassification',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Residual Likelihood',
      key: 'residualLikelihoodDto.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Residual Impact',
      key: 'residualImpact.name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Residual',
      key: 'residualRisk',
      dataType: DataTypeEnum.RiskItemCell,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        options: {}, //TODO: fix risk item cell component to request the methodology on it's own similar to CodeLink component
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'gte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: RiskMeterBarComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
        }
      }
    },
    {
      name: 'Residual Classification',
      key: 'residualClassification',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
