import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { mapLicenseStatusToMessage } from '@core/services/license-handler/license-handler.service';
import { RequestHandlerOptions, toQueryParams } from 'app/shared/classes';
import { replace } from 'lodash-es';
import has from 'lodash-es/has';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  baseUrl = ''; //@TODO FIX BASE URL
  static loadingCounter = 0;
  constructor(
    private http: HttpClient,
    private cache: CacheService,
    private snackBar: ToastService,
    private loaderService: LoaderService,
    private router: Router
  ) {}
  subscribePostData(subURL, body, options?: RequestHandlerOptions) {
    let url = this.baseUrl + subURL;

    return this.http.post(url, body, {
      params: toQueryParams(options.params),
    });
  }
  download(subURL, params = null) {
    let url = this.baseUrl + subURL;
    const header = new HttpHeaders({
      Accept: 'application/json',
      'content-type': 'application/octet-stream',
    });
    return this.http.get(url, { headers: header, params: params, responseType: 'blob', observe: 'response' });
  }
  getAsText(subURL, params = null) {
    let url = this.baseUrl + subURL;
    const header = new HttpHeaders({
      Accept: 'text/csv',
    });
    return this.http.get(url, { headers: header, params: params, responseType: 'text' });
  }
  downloadWithProgress(subURL, params = null) {
    let url = this.baseUrl + subURL;
    const header = new HttpHeaders({
      Accept: 'application/json',
      'content-type': 'application/octet-stream',
    });
    return this.http.get(url, {
      headers: header,
      reportProgress: true,
      observe: 'events',
      params: params,
      responseType: 'blob',
    });
  }
  uploadWithProgress<T = any>(subURL, body, options?: RequestHandlerOptions) {
    let url = this.baseUrl + subURL;
    const header = new HttpHeaders({
      Accept: 'application/json',
    });
    return this.http.post<T>(url, body, {
      headers: header,
      reportProgress: true,
      observe: 'events',
      params: toQueryParams(options.params),
    });
  }
  //not used and idk what is this style but it's half broken just to make sure the return type is a blob
  downloadV2(subURL, params = null): Observable<HttpResponse<Blob>> {
    let url = this.baseUrl + subURL;
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  }
  postData<T>(subURL, body, options?: RequestHandlerOptions) {
    if (!options.extras) {
      options.extras = {};
    }
    if (has(options.extras, 'cacheKey') && this.cache.has(options.extras.cacheKey)) {
      //try to fetch from the cache
      const cacheObservable = new Observable<T>((observer) => {
        // synchronously delivercache value then complete
        observer.next(this.cache.get(options.extras.cacheKey));
        observer.complete();

        // unsubscribe function doesn't need to do anything in this
        // because values are delivered synchronously
        return { unsubscribe() {} };
      });
      return cacheObservable;
    } else {
      if (options.showLoading) {
        this.loaderService.toggleLoader(true);
        DataService.loadingCounter++;
      }
      let url = this.baseUrl + subURL;

      return this.http
        .post<T>(url, body, {
          params: toQueryParams(options.params),
        })
        .pipe(
          tap((response) => {
            if (has(options.extras, 'cacheKey')) {
              //store to cache
              this.cache.set(options.extras.cacheKey, response);
            }

            if (options.showMsg) this.snackBar.success('operation_successful');
          }),
          catchError(this.handleError.bind(this, options)), // then handle the error
          finalize(() => {
            if (options.showLoading) {
              DataService.loadingCounter--;
              if (DataService.loadingCounter <= 0) {
                DataService.loadingCounter = 0;
                this.loaderService.toggleLoader(false);
              }
            }
          })
        );
    }
  }
  putData<T>(subURL, body, options?: RequestHandlerOptions) {
    if (options.showLoading) {
      this.loaderService.toggleLoader(true);
      DataService.loadingCounter++;
    }
    let url = this.baseUrl + subURL;

    return this.http
      .put<T>(url, body, {
        params: toQueryParams(options.params),
      })
      .pipe(
        tap((response) => {
          if (options.showMsg) this.snackBar.success('operation_successful');
        }),
        catchError(this.handleError.bind(this, options)), // then handle the error
        finalize(() => {
          if (options.showLoading) {
            DataService.loadingCounter--;
            if (DataService.loadingCounter <= 0) {
              DataService.loadingCounter = 0;
              this.loaderService.toggleLoader(false);
            }
          }
        })
      );
  }
  patchData<T>(subURL, body, options?: RequestHandlerOptions) {
    if (options.showLoading) {
      this.loaderService.toggleLoader(true);
      DataService.loadingCounter++;
    }
    let url = this.baseUrl + subURL;

    return this.http
      .patch<T>(url, body, {
        params: toQueryParams(options.params),
      })
      .pipe(
        tap((response) => {
          if (options.showMsg) this.snackBar.success('operation_successful');
        }),
        catchError(this.handleError.bind(this, options)), // then handle the error
        finalize(() => {
          if (options.showLoading) {
            DataService.loadingCounter--;
            if (DataService.loadingCounter <= 0) {
              DataService.loadingCounter = 0;
              this.loaderService.toggleLoader(false);
            }
          }
        })
      );
  }
  getData<T>(subURL, options?: RequestHandlerOptions) {
    if (!options.extras) {
      options.extras = {};
    }
    if (has(options.extras, 'cacheKey') && this.cache.has(options.extras.cacheKey)) {
      //try to fetch from the cache
      const cacheObservable = new Observable<T>((observer) => {
        // synchronously delivercache value then complete
        observer.next(this.cache.get(options.extras.cacheKey));
        observer.complete();

        // unsubscribe function doesn't need to do anything in this
        // because values are delivered synchronously
        return { unsubscribe() {} };
      });
      return cacheObservable;
    } else {
      if (options.showLoading) {
        this.loaderService.toggleLoader(true);
        DataService.loadingCounter++;
      }
      let url = this.baseUrl + subURL;

      return this.http
        .get<T>(url, {
          params: toQueryParams(options.params),
        })
        .pipe(
          tap((response) => {
            if (has(options.extras, 'cacheKey')) {
              //store to cache
              this.cache.set(options.extras.cacheKey, response);
            }
            // return response;

            // if (showMsg) this.snackBar.success("operation_successful");
          }),
          catchError(this.handleError.bind(this, options, options)), // then handle the error
          finalize(() => {
            if (options.showLoading) {
              DataService.loadingCounter--;
              if (DataService.loadingCounter <= 0) {
                DataService.loadingCounter = 0;
                this.loaderService.toggleLoader(false);
              }
            }
          })
        );
    }
  }
  getTextData(subURL, options?: RequestHandlerOptions) {
    if (!options.extras) {
      options.extras = {};
    }
    if (has(options.extras, 'cacheKey') && this.cache.has(options.extras.cacheKey)) {
      //try to fetch from the cache
      const cacheObservable = new Observable((observer) => {
        // synchronously delivercache value then complete
        observer.next(this.cache.get(options.extras.cacheKey));
        observer.complete();

        // unsubscribe function doesn't need to do anything in this
        // because values are delivered synchronously
        return { unsubscribe() {} };
      });
      return cacheObservable;
    } else {
      if (options.showLoading) {
        this.loaderService.toggleLoader(true);
        DataService.loadingCounter++;
      }
      let url = this.baseUrl + subURL;

      return this.http
        .get(url, {
          params: toQueryParams(options.params),
          responseType: 'text',
        })
        .pipe(
          tap((response) => {
            if (has(options.extras, 'cacheKey')) {
              //store to cache
              this.cache.set(options.extras.cacheKey, response);
            }
            // return response;

            // if (showMsg) this.snackBar.success("operation_successful");
          }),
          catchError(this.handleError.bind(this, options)), // then handle the error
          finalize(() => {
            if (options.showLoading) {
              DataService.loadingCounter--;
              if (DataService.loadingCounter <= 0) {
                DataService.loadingCounter = 0;
                this.loaderService.toggleLoader(false);
              }
            }
          })
        );
    }
  }
  deleteData<T>(subURL, options?: RequestHandlerOptions) {
    if (options.showLoading) {
      this.loaderService.toggleLoader(true);
      DataService.loadingCounter++;
    }
    let url = this.baseUrl + subURL;

    return this.http
      .delete<T>(url, {
        params: toQueryParams(options.params),
      })
      .pipe(
        tap((response) => {
          // if (showMsg) this.snackBar.success("operation_successful");
        }),
        catchError(this.handleError.bind(this, options)), // then handle the error
        finalize(() => {
          if (options.showLoading) {
            DataService.loadingCounter--;
            if (DataService.loadingCounter <= 0) {
              DataService.loadingCounter = 0;
              this.loaderService.toggleLoader(false);
            }
          }
        })
      );
  }

  public handleError(options: RequestHandlerOptions, error: HttpErrorResponse) {
    let err = 'Request Error';
    if (typeof error === 'string') {
      err = error;
    }
    if (typeof error?.error?.errorMessage === 'string') {
      if (error?.error?.errorMessage == '' && typeof error?.error?.error === 'string') {
        // not sure about this check
        err = 'Server Error'; // error?.error?.error;
      } else {
        err = error?.error?.errorMessage;
      }
    } else if (typeof error?.error?.message === 'string') {
      if (error?.error?.message == '' && typeof error?.error?.error === 'string') {
        // not sure about this check
        err = 'Server Error'; // error?.error?.error;
      } else {
        err = error?.error?.message;
      }
    } else if (typeof error.message === 'string') {
      // Use a regular expression to replace the query parameters
      const modifiedErrorMessage = replace(error.message, /\?.*$/, '');

      err = modifiedErrorMessage ? modifiedErrorMessage : error.message;
      console.log('in message setting 3');
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //     `Backend returned code ${error.status}, ` +
      //     `body was: ${error.error}`);
    }
    if (error.status == 0) {
      err = 'Network Connection Error';
    }
    if (error.status == 404) {
      this.router.navigateByUrl('/notfound');
    }
    if (error.status == 406) {
      err = mapLicenseStatusToMessage(error?.headers?.get('x-license-status')) || 'License Has Issues';
    }
    // if (error.status >= 500) {
    //   this.router.navigateByUrl('/error');
    // }
    // return an observable with a user-facing error message
    if (options.showError) {
      switch (err) {
        case ErrorMessageTypes.DUPLICATE_KEY_VIOLATION:
          //TODO: make better error message by passing the module to locate translations
          this.snackBar.error(err);
          break;
        case ErrorMessageTypes.PERMANENT_DELETE_REQUIRED:
          //TODO: make better error message by passing the module to locate translations
          this.snackBar.error(err);
          break;
        default:
          this.snackBar.error(err);
          break;
      }
      // this.snackBar.error(err);
    }
    // console.log(err);
    return throwError(() => new Error(err));
  }
}
export enum ErrorMessageTypes {
  DUPLICATE_KEY_VIOLATION = 'DUPLICATE_KEY_VIOLATION',
  PERMANENT_DELETE_REQUIRED = 'PERMANENT_DELETE_REQUIRED',
}
