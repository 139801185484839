<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
          </label>
        }
      }
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
          </label>
        }
      }
      <p-rating
        class="w-full"
        [formControl]="fControl"
        [stars]="maxNumber"
        [cancel]="cancel"
        [iconOnClass]="iconOnClass"
        [iconOffClass]="iconOffClass"
        [iconCancelClass]="iconCancelClass"
      ></p-rating>
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
</app-input-view-switch>
