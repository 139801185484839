// import { AttachmentInputComponent } from "@shared/components/ui/input-fields/attachment-input/attachment-input.component";
// import { BasicInputComponent } from "@shared/components/ui/input-fields/basic-input/basic-input.component";
// import { DateInputComponent } from "@shared/components/ui/input-fields/date-input/date-input.component";
// import { DropDownInputComponent } from "@shared/components/ui/input-fields/drop-down-input/drop-down-input.component";
// import { MaskedInputComponent } from "@shared/components/ui/input-fields/masked-input/masked-input.component";
// import { NumberInputComponent } from "@shared/components/ui/input-fields/number-input/number-input.component";
// import { RatingInputComponent } from "@shared/components/ui/input-fields/rating-input/rating-input.component";
// import { SwitchInputComponent } from "@shared/components/ui/input-fields/switch-input/switch-input.component";

import { FieldDto } from '../model';
import { NumberInputModes } from './view-enums';

export enum DynamicFieldConfig {
  GENERAL_MIN = 'GENERAL_MIN',
  GENERAL_MAX = 'GENERAL_MAX',
  GENERAL_REQUIRED = 'GENERAL_REQUIRED',
  GENERAL_REQUIRED_TRUE = 'GENERAL_REQUIRED_TRUE',
  GENERAL_EMAIL = 'GENERAL_EMAIL',
  GENERAL_MIN_LENGTH = 'GENERAL_MIN_LENGTH',
  GENERAL_MAX_LENGTH = 'GENERAL_MAX_LENGTH',
  GENERAL_PATTERN = 'GENERAL_PATTERN',
  ATTACHMENT_MULTIPLE = 'ATTACHMENT_MULTIPLE',
  ATTACHMENT_MAX_FILE_SIZE = 'ATTACHMENT_MAX_FILE_SIZE',
  ATTACHMENT_ACCEPT = 'ATTACHMENT_ACCEPT',
  ATTACHMENT_FOLDER = 'ATTACHMENT_FOLDER',
  LIST_OPTIONS = 'LIST_OPTIONS',
  NUMBER_MAX_FRACTIONS = 'NUMBER_MAX_FRACTIONS',
  MIN_DATE = 'MIN_DATE',
  MAX_DATE = 'MAX_DATE',
  DATE_SELECTION_MODE = 'DATE_SELECTION_MODE',
  DATE_VIEW_MODE = 'DATE_VIEW_MODE',
  INPUT_MASK = 'INPUT_MASK',
  ATTACHMENT_FILE_LIMIT = 'ATTACHMENT_FILE_LIMIT',
  MULTI_SELECT_DEFAULT_OPTION = 'MULTI_SELECT_DEFAULT_OPTION',
  MAINTAIN_ASPECT_RATIO = 'MAINTAIN_ASPECT_RATIO',
  OUTPUT_IMAGE_FORMAT = 'OUTPUT_IMAGE_FORMAT',
  ASPECT_RATIO = 'ASPECT_RATIO',
  RELATION_TYPE = 'RELATION_TYPE',
  RELATION_TARGET_TYPE = 'RELATION_TARGET_TYPE',
  RATING_MAX_NUMBER = 'RATING_MAX_NUMBER',
  ORDER = 'ORDER',
  MCQ_SELECTION_MODE = 'MCQ_SELECTION_MODE',
  MCQ_PASSING_SCORE = 'MCQ_PASSING_SCORE',
  MCQ_REQUIRE_SCORE = 'MCQ_REQUIRE_SCORE',
  MCQ_SELECTION_OPTIONS = 'MCQ_SELECTION_OPTIONS',
  SINGLE_SELECTION_MODE = 'SINGLE_SELECTION_MODE',
  NETWORK_DISABLED_BLOCKS = 'NETWORK_DISABLED_BLOCKS',
  NETWORK_DEFAULT_BLOCK_VALUES = 'NETWORK_DEFAULT_BLOCK_VALUES',
  ATTACHMENT_UPLOAD_MODE = 'ATTACHMENT_UPLOAD_MODE',
  GVL_CODE = 'GVL_CODE',
  GVL_LIST_MODE = 'GVL_LIST_MODE',
  GVL_SELECTION_MODE = 'GVL_SELECTION_MODE',
  GVL_INLINE_MODE = 'GVL_INLINE_MODE',
  GVL_SHOW_NUMERIC = 'GVL_SHOW_NUMERIC',
  GVL_SHOW_DESCRIPTION = 'GVL_SHOW_DESCRIPTION',
  NUMBER_INPUT_MODE = 'NUMBER_INPUT_MODE',
  PLACEHOLDER = 'PLACEHOLDER',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  NUMBER_INPUT_CURRENCY = 'NUMBER_INPUT_CURRENCY',
  GENERAL_VIEW_MODE = 'GENERAL_VIEW_MODE',
  GENERAL_USE_DEFAULT_VALUE = 'GENERAL_USE_DEFAULT_VALUE',
}
export const ValidatorOnlyOptions: { [key in DynamicFieldConfig]: boolean } = {
  [DynamicFieldConfig.GENERAL_REQUIRED]: true,
  [DynamicFieldConfig.GENERAL_REQUIRED_TRUE]: true,
  [DynamicFieldConfig.GENERAL_EMAIL]: true,
  [DynamicFieldConfig.GENERAL_MIN_LENGTH]: true,
  [DynamicFieldConfig.GENERAL_MAX_LENGTH]: true,
  [DynamicFieldConfig.GENERAL_PATTERN]: true,
  [DynamicFieldConfig.GENERAL_MIN]: false,
  [DynamicFieldConfig.GENERAL_MAX]: false,
  [DynamicFieldConfig.ATTACHMENT_MULTIPLE]: false,
  [DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE]: false,
  [DynamicFieldConfig.ATTACHMENT_ACCEPT]: false,
  [DynamicFieldConfig.ATTACHMENT_FOLDER]: false,
  [DynamicFieldConfig.LIST_OPTIONS]: false,
  [DynamicFieldConfig.NUMBER_MAX_FRACTIONS]: false,
  [DynamicFieldConfig.MIN_DATE]: false,
  [DynamicFieldConfig.MAX_DATE]: false,
  [DynamicFieldConfig.DATE_SELECTION_MODE]: false,
  [DynamicFieldConfig.DATE_VIEW_MODE]: false,
  [DynamicFieldConfig.INPUT_MASK]: false,
  [DynamicFieldConfig.ATTACHMENT_FILE_LIMIT]: false,
  [DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]: false,
  [DynamicFieldConfig.MAINTAIN_ASPECT_RATIO]: false,
  [DynamicFieldConfig.OUTPUT_IMAGE_FORMAT]: false,
  [DynamicFieldConfig.ASPECT_RATIO]: false,
  [DynamicFieldConfig.RELATION_TYPE]: false,
  [DynamicFieldConfig.RELATION_TARGET_TYPE]: false,
  [DynamicFieldConfig.RATING_MAX_NUMBER]: false,
  [DynamicFieldConfig.ORDER]: false,
  [DynamicFieldConfig.MCQ_SELECTION_MODE]: false,
  [DynamicFieldConfig.MCQ_PASSING_SCORE]: false,
  [DynamicFieldConfig.MCQ_REQUIRE_SCORE]: false,
  [DynamicFieldConfig.MCQ_SELECTION_OPTIONS]: false,
  [DynamicFieldConfig.SINGLE_SELECTION_MODE]: false,
  [DynamicFieldConfig.NETWORK_DISABLED_BLOCKS]: false,
  [DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES]: false,
  [DynamicFieldConfig.ATTACHMENT_UPLOAD_MODE]: false,
  [DynamicFieldConfig.GVL_CODE]: false,
  [DynamicFieldConfig.GVL_LIST_MODE]: false,
  [DynamicFieldConfig.GVL_SELECTION_MODE]: false,
  [DynamicFieldConfig.GVL_INLINE_MODE]: false,
  [DynamicFieldConfig.GVL_SHOW_NUMERIC]: false,
  [DynamicFieldConfig.GVL_SHOW_DESCRIPTION]: false,
  [DynamicFieldConfig.NUMBER_INPUT_MODE]: false,
  [DynamicFieldConfig.PLACEHOLDER]: false,
  [DynamicFieldConfig.DEFAULT_VALUE]: false,
  [DynamicFieldConfig.NUMBER_INPUT_CURRENCY]: false,
  [DynamicFieldConfig.GENERAL_VIEW_MODE]: false,
  [DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE]: false,
};
export const DynamicFieldConfigDefaultValue: { [key in DynamicFieldConfig]: any } = {
  [DynamicFieldConfig.GENERAL_MIN]: null,
  [DynamicFieldConfig.GENERAL_MAX]: null,
  [DynamicFieldConfig.GENERAL_REQUIRED]: false,
  [DynamicFieldConfig.GENERAL_REQUIRED_TRUE]: false,
  [DynamicFieldConfig.GENERAL_EMAIL]: false,
  [DynamicFieldConfig.GENERAL_MIN_LENGTH]: null,
  [DynamicFieldConfig.GENERAL_MAX_LENGTH]: null,
  [DynamicFieldConfig.GENERAL_PATTERN]: null,
  [DynamicFieldConfig.ATTACHMENT_MULTIPLE]: false,
  [DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE]: null,
  [DynamicFieldConfig.ATTACHMENT_ACCEPT]: null,
  [DynamicFieldConfig.ATTACHMENT_FOLDER]: 'root',
  [DynamicFieldConfig.ATTACHMENT_FILE_LIMIT]: null,
  [DynamicFieldConfig.ATTACHMENT_UPLOAD_MODE]: 'CODE',
  [DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]: null,
  [DynamicFieldConfig.LIST_OPTIONS]: null,
  [DynamicFieldConfig.NUMBER_MAX_FRACTIONS]: null,
  [DynamicFieldConfig.MIN_DATE]: null,
  [DynamicFieldConfig.MAX_DATE]: null,
  [DynamicFieldConfig.DATE_SELECTION_MODE]: null,
  [DynamicFieldConfig.DATE_VIEW_MODE]: null,
  [DynamicFieldConfig.INPUT_MASK]: null,
  [DynamicFieldConfig.RATING_MAX_NUMBER]: 5,
  [DynamicFieldConfig.RELATION_TYPE]: null,
  [DynamicFieldConfig.RELATION_TARGET_TYPE]: null,
  [DynamicFieldConfig.ORDER]: 0,
  [DynamicFieldConfig.SINGLE_SELECTION_MODE]: 'radio', // radio | dropdown
  [DynamicFieldConfig.MAINTAIN_ASPECT_RATIO]: true,
  [DynamicFieldConfig.ASPECT_RATIO]: 4 / 3,
  [DynamicFieldConfig.OUTPUT_IMAGE_FORMAT]: 'png',
  [DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES]: ['', '', '', '', '', '', '', ''],
  [DynamicFieldConfig.NETWORK_DISABLED_BLOCKS]: [],
  [DynamicFieldConfig.GVL_CODE]: null,
  [DynamicFieldConfig.GVL_LIST_MODE]: false,
  [DynamicFieldConfig.GVL_INLINE_MODE]: false,
  [DynamicFieldConfig.GVL_SELECTION_MODE]: false,
  [DynamicFieldConfig.GVL_SHOW_DESCRIPTION]: true,
  [DynamicFieldConfig.GVL_SHOW_NUMERIC]: true,
  [DynamicFieldConfig.PLACEHOLDER]: null,
  [DynamicFieldConfig.DEFAULT_VALUE]: null,
  [DynamicFieldConfig.GENERAL_USE_DEFAULT_VALUE]: null,
  [DynamicFieldConfig.GENERAL_VIEW_MODE]: null,
  [DynamicFieldConfig.MCQ_SELECTION_MODE]: 'normal', // NORMAL | scored
  [DynamicFieldConfig.MCQ_PASSING_SCORE]: 0,
  [DynamicFieldConfig.MCQ_REQUIRE_SCORE]: false,
  [DynamicFieldConfig.MCQ_SELECTION_OPTIONS]: [], // not gonna use it for now
  [DynamicFieldConfig.NUMBER_INPUT_MODE]: NumberInputModes.decimal, // add usage
  [DynamicFieldConfig.NUMBER_INPUT_CURRENCY]: 'USD', // add usage
};
export const DynamicFieldTypeOptions: { [key in FieldDto.TypeEnum]: (keyof typeof DynamicFieldConfig)[] } = {
  ['INTEGER']: [
    'GENERAL_MIN',
    'GENERAL_MAX',
    'GENERAL_REQUIRED',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  // ['DOUBLE']: [
  //   'GENERAL_MIN',
  //   'GENERAL_MAX',
  //   'GENERAL_REQUIRED',
  //   'NUMBER_MAX_FRACTIONS',
  //   'PLACEHOLDER',
  //   'DEFAULT_VALUE',
  //   'GENERAL_USE_DEFAULT_VALUE',
  //   'GENERAL_VIEW_MODE',
  // ],
  ['BOOLEAN']: ['GENERAL_REQUIRED_TRUE', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  ['STRING']: [
    'GENERAL_REQUIRED',
    'GENERAL_EMAIL',
    'GENERAL_MIN_LENGTH',
    'GENERAL_MAX_LENGTH',
    'GENERAL_PATTERN',
    'INPUT_MASK',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['NUMBER']: [
    'GENERAL_MIN',
    'GENERAL_MAX',
    'GENERAL_REQUIRED',
    'NUMBER_MAX_FRACTIONS',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
    'NUMBER_INPUT_MODE',
    'NUMBER_INPUT_CURRENCY',
  ],
  // ['MCQ']: ['GENERAL_REQUIRED', 'LIST_OPTIONS', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  ['SINGLE_SELECTION']: [
    'GENERAL_REQUIRED',
    'LIST_OPTIONS',
    'SINGLE_SELECTION_MODE',
    // 'MULTI_SELECT_DEFAULT_OPTION',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
    'MCQ_SELECTION_MODE',
    'MCQ_PASSING_SCORE',
    'MCQ_REQUIRE_SCORE',
    'MCQ_SELECTION_OPTIONS',
  ],
  ['ATTACHMENT']: [
    'GENERAL_REQUIRED',
    'ATTACHMENT_MULTIPLE',
    'ATTACHMENT_MAX_FILE_SIZE',
    'ATTACHMENT_ACCEPT',
    'ATTACHMENT_FOLDER',
    'ATTACHMENT_FILE_LIMIT',
    'ATTACHMENT_UPLOAD_MODE',
  ],
  ['IMAGE']: [
    'GENERAL_REQUIRED',
    'ATTACHMENT_MAX_FILE_SIZE',
    'ATTACHMENT_ACCEPT',
    'ATTACHMENT_FOLDER',
    'MAINTAIN_ASPECT_RATIO',
    'ASPECT_RATIO',
    'OUTPUT_IMAGE_FORMAT',
  ],
  ['IP']: [
    'GENERAL_REQUIRED',
    'NETWORK_DEFAULT_BLOCK_VALUES',
    'NETWORK_DISABLED_BLOCKS',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['IP6']: [
    'GENERAL_REQUIRED',
    'NETWORK_DEFAULT_BLOCK_VALUES',
    'NETWORK_DISABLED_BLOCKS',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['MAC']: [
    'GENERAL_REQUIRED',
    'NETWORK_DEFAULT_BLOCK_VALUES',
    'NETWORK_DISABLED_BLOCKS',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['IPV4_MASK']: [
    'GENERAL_REQUIRED',
    'NETWORK_DEFAULT_BLOCK_VALUES',
    'NETWORK_DISABLED_BLOCKS',
    'PLACEHOLDER',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['DATE']: [
    'GENERAL_REQUIRED',
    'MIN_DATE',
    'MAX_DATE',
    'DATE_SELECTION_MODE',
    'DATE_VIEW_MODE',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['DATETIME']: [
    'GENERAL_REQUIRED',
    'MIN_DATE',
    'MAX_DATE',
    'DATE_SELECTION_MODE',
    'DATE_VIEW_MODE',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['MULTISELECT']: ['GENERAL_REQUIRED', 'LIST_OPTIONS', 'GENERAL_USE_DEFAULT_VALUE', 'MULTI_SELECT_DEFAULT_OPTION', 'GENERAL_VIEW_MODE'],
  ['RELATION']: [
    'GENERAL_REQUIRED',
    'RELATION_TYPE',
    'RELATION_TARGET_TYPE',
    'PLACEHOLDER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['RATING']: [
    'GENERAL_REQUIRED',
    'RATING_MAX_NUMBER',
    'DEFAULT_VALUE',
    'GENERAL_USE_DEFAULT_VALUE',
    'GENERAL_VIEW_MODE',
  ],
  ['LONG_TEXT']: ['GENERAL_REQUIRED', 'PLACEHOLDER', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  // ['TEXT_VIEW']: ['GENERAL_REQUIRED', 'PLACEHOLDER', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  ['TIME']: ['GENERAL_REQUIRED', 'PLACEHOLDER', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  ['COLOR']: ['GENERAL_REQUIRED', 'PLACEHOLDER', 'DEFAULT_VALUE', 'GENERAL_USE_DEFAULT_VALUE', 'GENERAL_VIEW_MODE'],
  ['GROUP_VALUE_LIST']: [
    'GENERAL_REQUIRED',
    'GVL_CODE',
    'GVL_LIST_MODE',
    'GENERAL_MIN_LENGTH',
    'GENERAL_MAX_LENGTH',
    'GVL_SELECTION_MODE',
    'GVL_INLINE_MODE',
    'GVL_SHOW_DESCRIPTION',
    'GVL_SHOW_NUMERIC',
  ],
};
export const AcceptFileTypes = [
  //@TODO: should be tree nodes T^T
  {
    label: 'Images',
    value: 'image/*',
  },
];
export enum DateSelectionModes {
  single = 'single',
  multiple = 'multiple',
  range = 'range',
}
export enum DateViewModes {
  date = 'date',
  month = 'month',
  year = 'year',
}
export enum SingleSelectionModes {
  radio = 'radio',
  dropdown = 'dropdown',
}
export enum OutputImageFormatModes {
  png = 'png',
  jpg = 'jpg',
  'bmp' = 'bmp',
  'webp' = 'webp',
  'ico' = 'ico',
}
export const AttachmentUploadModes = [
  {
    label: 'Current Item Folder',
    value: 'CODE',
  },
  {
    label: 'Custom Folder',
    value: 'CUSTOM_FOLDER',
  },
];
export enum McqSelectionModes {
  normal = 'normal',
  scored = 'scored',
}
