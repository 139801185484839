import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { GroupRepresentation, RequestHandlerOptions, User } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupDataService extends BaseRequestControllerService<GroupRepresentation> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.user, '/groups', ['id', 'name']);
  }

  getSelector(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<GroupRepresentation[]>(this.url + '/selector', options) as Observable<
      GroupRepresentation[]
    >;
  }
  getMembers(groupId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<User[]>(this.url + '/' + groupId + '/members', options) as Observable<User[]>;
  }
  joinGroup(body: { ids: string[] }, groupId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<Boolean>(`${this.url}/${groupId}/join`, body, options) as Observable<Boolean>;
  }
  leaveGroup(body: { ids: string[] }, groupId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<Boolean>(`${this.url}/${groupId}/leave`, body, options) as Observable<Boolean>;
  }
  getGroupsMembers(groupIds: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<{ [x: string]: string[] }>(
      this.url + '/groups-members',
      groupIds,
      options
    ) as Observable<{ [x: string]: string[] }>;
  }
  navigateToListPage() {
    this.router.navigateByUrl(`user-management/group/list`);
  }
}
