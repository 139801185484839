import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiResponseListModuleName, AppPermissions, isNullObj } from '@shared/classes';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { KeycloakService } from 'keycloak-angular';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionParserService {
  protected isInitialized = false;
  constructor(
    private permissionsService: NgxPermissionsService,
    protected readonly keycloak: KeycloakService,
    private userService: UserDataService,
    private store: Store
  ) { }
  readonly permissionsMap = {
    //@TODONewModule: when you add new module make sure to define these
    //Question Modules
    [AppPermissions.CreateQuestionnaire]: ['question-questionnaire-create', 'question-question-read'],
    [AppPermissions.UpdateQuestionnaire]: [
      'question-questionnaire-read',
      'question-questionnaire-update',
      'question-question-read',
    ],
    [AppPermissions.ReadQuestionnaire]: ['question-questionnaire-read'],
    [AppPermissions.DeleteQuestionnaire]: ['question-questionnaire-admin'],

    [AppPermissions.CreateQuestion]: ['question-question-create'],
    [AppPermissions.UpdateQuestion]: ['question-question-read', 'question-question-update'],
    [AppPermissions.ReadQuestion]: ['question-question-read'],
    [AppPermissions.DeleteQuestion]: ['question-question-admin'],

    [AppPermissions.CreateQuestionnaireValue]: ['question-questionnaire-value-create', 'question-questionnaire-read'],
    [AppPermissions.UpdateQuestionnaireValue]: [
      'question-questionnaire-value-read',
      'question-questionnaire-value-update',
      'question-questionnaire-read',
    ],
    [AppPermissions.ReadQuestionnaireValue]: ['question-questionnaire-value-read'],
    [AppPermissions.DeleteQuestionnaireValue]: ['question-questionnaire-value-admin'],

    [AppPermissions.CreateQuestionnaireType]: ['question-questionnaire-type-create'],
    [AppPermissions.UpdateQuestionnaireType]: [
      'question-questionnaire-type-read',
      'question-questionnaire-type-update',
    ],
    [AppPermissions.ReadQuestionnaireType]: ['question-questionnaire-type-read'],
    [AppPermissions.DeleteQuestionnaireType]: ['question-questionnaire-type-admin'],

    [AppPermissions.CreateMyQuestionnaireValue]: ['authenticated', 'question-questionnaire-read'],
    [AppPermissions.UpdateMyQuestionnaireValue]: ['authenticated', 'authenticated', 'questionnaire-read'],
    [AppPermissions.ReadMyQuestionnaireValue]: ['authenticated'],
    [AppPermissions.DeleteMyQuestionnaireValue]: ['authenticated'],

    //Entity Modules

    [AppPermissions.CreateEntityType]: ['entity-entity-type-create'],
    [AppPermissions.UpdateEntityType]: ['entity-entity-type-read', 'entity-entity-type-update'],
    [AppPermissions.ReadEntityType]: ['entity-entity-type-read'],
    [AppPermissions.DeleteEntityType]: ['entity-entity-type-admin'],

    [AppPermissions.CreateAsset]: [
      'entity-asset-create',
      'entity-organization-read',
      'entity-entity-type-read',
      'entity-responsibility-read',
    ],
    [AppPermissions.UpdateAsset]: ['entity-asset-read', 'entity-asset-update'],
    [AppPermissions.ReadAsset]: ['entity-asset-read'],
    [AppPermissions.DeleteAsset]: ['entity-asset-admin'],

    [AppPermissions.CreateEvent]: [
      'entity-event-create',
      'entity-organization-read',
      'entity-entity-type-read',
      'entity-responsibility-read',
    ],
    [AppPermissions.UpdateEvent]: ['entity-event-read', 'entity-event-update'],
    [AppPermissions.ReadEvent]: ['entity-event-read'],
    [AppPermissions.DeleteEvent]: ['entity-event-admin'],

    [AppPermissions.CreateGeography]: [
      'entity-geography-create',
      'entity-organization-read',
      'entity-entity-type-read',
      'entity-responsibility-read',
    ],
    [AppPermissions.UpdateGeography]: ['entity-geography-read', 'entity-geography-update'],
    [AppPermissions.ReadGeography]: ['entity-geography-read'],
    [AppPermissions.DeleteGeography]: ['entity-geography-admin'],

    [AppPermissions.CreateProcess]: [
      'entity-process-create',
      'entity-organization-read',
      'entity-entity-type-read',
      'entity-responsibility-read',
    ],
    [AppPermissions.UpdateProcess]: ['entity-process-read', 'entity-process-update'],
    [AppPermissions.ReadProcess]: ['entity-process-read'],
    [AppPermissions.DeleteProcess]: ['entity-process-admin'],

    [AppPermissions.CreateResponsibility]: [
      'entity-responsibility-create',
      'entity-organization-read',
      'entity-entity-type-read',
    ],
    [AppPermissions.UpdateResponsibility]: ['entity-responsibility-read', 'entity-responsibility-update'],
    [AppPermissions.ReadResponsibility]: ['entity-responsibility-read'],
    [AppPermissions.DeleteResponsibility]: ['entity-responsibility-admin'],

    [AppPermissions.CreateOrganization]: ['entity-organization-create', 'entity-entity-type-read'],
    [AppPermissions.UpdateOrganization]: ['entity-organization-read', 'entity-organization-update'],
    [AppPermissions.ReadOrganization]: ['entity-organization-read'],
    [AppPermissions.DeleteOrganization]: ['entity-organization-admin'],

    [AppPermissions.CreateObjective]: [
      'entity-objective-create',
      'entity-organization-read',
      'entity-entity-type-read',
      'entity-responsibility-read',
    ],
    [AppPermissions.UpdateObjective]: ['entity-objective-read', 'entity-objective-update'],
    [AppPermissions.ReadObjective]: ['entity-objective-read'],
    [AppPermissions.DeleteObjective]: ['entity-objective-admin'],

    [AppPermissions.CreateAnyEntity]: [
      'entity-asset-create',
      'entity-event-create',
      'entity-geography-create',
      'entity-process-create',
      'entity-responsibility-create',
      'entity-objective-create',
    ],
    [AppPermissions.UpdateAnyEntity]: [
      'entity-asset-read',
      'entity-event-read',
      'entity-geography-read',
      'entity-process-read',
      'entity-responsibility-read',
      'entity-objective-read',
      'entity-asset-update',
      'entity-event-update',
      'entity-geography-update',
      'entity-process-update',
      'entity-responsibility-update',
      'entity-objective-update',
    ],
    [AppPermissions.ReadAnyEntity]: [
      'entity-asset-read',
      'entity-event-read',
      'entity-geography-read',
      'entity-process-read',
      'entity-responsibility-read',
      'entity-objective-read',
    ],
    [AppPermissions.DeleteAnyEntity]: [
      'entity-asset-admin',
      'entity-event-admin',
      'entity-geography-admin',
      'entity-process-admin',
      'entity-responsibility-admin',
      'entity-objective-admin',
    ],

    //@TODO: check permissions risk module
    [AppPermissions.CreateRiskItem]: ['risk-risk-item-create'],
    [AppPermissions.UpdateRiskItem]: ['risk-risk-item-read', 'risk-risk-item-update'],
    [AppPermissions.ReadRiskItem]: ['risk-risk-item-read'],
    [AppPermissions.DeleteRiskItem]: ['risk-risk-item-admin'],

    [AppPermissions.CreateDraftRisk]: ['risk-draft-risk-create'],
    [AppPermissions.UpdateDraftRisk]: ['risk-draft-risk-read', 'risk-draft-risk-update'],
    [AppPermissions.ReadDraftRisk]: ['risk-draft-risk-read'],
    [AppPermissions.DeleteDraftRisk]: ['risk-draft-risk-admin'],

    [AppPermissions.CreateRiskRegister]: ['risk-risk-item-create'],
    [AppPermissions.UpdateRiskRegister]: ['risk-risk-item-read', 'risk-risk-item-update'],
    [AppPermissions.ReadRiskRegister]: ['risk-risk-item-read'],
    [AppPermissions.DeleteRiskRegister]: ['risk-risk-item-admin'],

    [AppPermissions.CreateRiskAssessmentRequest]: ['risk-risk-assessment-request-create'],
    [AppPermissions.UpdateRiskAssessmentRequest]: [
      'risk-risk-assessment-request-read',
      'risk-risk-assessment-request-update',
    ],
    [AppPermissions.ReadRiskAssessmentRequest]: ['risk-risk-assessment-request-read'],
    [AppPermissions.DeleteRiskAssessmentRequest]: ['risk-risk-assessment-request-admin'],

    [AppPermissions.CreateMyRiskAssessmentRequest]: ['authenticated'],
    [AppPermissions.UpdateMyRiskAssessmentRequest]: ['authenticated'],
    [AppPermissions.ReadMyRiskAssessmentRequest]: ['authenticated'],
    [AppPermissions.DeleteMyRiskAssessmentRequest]: ['authenticated'],

    [AppPermissions.CreateRiskStatement]: ['risk-risk-statement-create'],
    [AppPermissions.UpdateRiskStatement]: ['risk-risk-statement-read', 'risk-risk-statement-update'],
    [AppPermissions.ReadRiskStatement]: ['risk-risk-statement-read'],
    [AppPermissions.DeleteRiskStatement]: ['risk-risk-statement-admin'],

    [AppPermissions.CreateRiskStatementCategory]: ['risk-risk-statement-category-create'],
    [AppPermissions.UpdateRiskStatementCategory]: [
      'risk-risk-statement-category-read',
      'risk-risk-statement-category-update',
    ],
    [AppPermissions.ReadRiskStatementCategory]: ['risk-risk-statement-category-read'],
    [AppPermissions.DeleteRiskStatementCategory]: ['risk-risk-statement-category-admin'],

    [AppPermissions.CreateRiskProject]: [
      'risk-risk-project-create',
      'risk-risk-statement-read',
      'question-questionnaire-read',
    ],
    [AppPermissions.UpdateRiskProject]: ['risk-risk-project-read', 'risk-risk-project-update'],
    [AppPermissions.ReadRiskProject]: ['risk-risk-project-read'],
    [AppPermissions.DeleteRiskProject]: ['risk-risk-project-admin'],
    [AppPermissions.AssingRiskProjectRiskItems]: [
      'risk-risk-project-read',
      'entity-asset-read',
      'entity-event-read',
      'entity-geography-read',
      'entity-process-read',
      'entity-responsibility-read',
      'entity-objective-read',
      'risk-risk-item-update',
      'question-questionnaire-value-update',
    ],

    [AppPermissions.CreateRiskMethodology]: ['risk-risk-methodology-create'],
    [AppPermissions.UpdateRiskMethodology]: ['risk-risk-methodology-read', 'risk-risk-methodology-update'],
    [AppPermissions.ReadRiskMethodology]: ['risk-risk-methodology-read'],
    [AppPermissions.DeleteRiskMethodology]: ['risk-risk-methodology-admin'],

    [AppPermissions.CreateRiskMethodologyImpactFactor]: ['risk-risk-methodology-impact-factor-create'],
    [AppPermissions.UpdateRiskMethodologyImpactFactor]: ['risk-risk-methodology-impact-factor-read', 'risk-risk-methodology-impact-factor-update'],
    [AppPermissions.ReadRiskMethodologyImpactFactor]: ['risk-risk-methodology-impact-factor-read'],
    [AppPermissions.DeleteRiskMethodologyImpactFactor]: ['risk-risk-methodology-impact-factor-admin'],

    [AppPermissions.CreateRiskMethodologyImpactFactorOption]: ['risk-risk-methodology-impact-factor-option-create'],
    [AppPermissions.UpdateRiskMethodologyImpactFactorOption]: ['risk-risk-methodology-impact-factor-option-read', 'risk-risk-methodology-impact-factor-option-update'],
    [AppPermissions.ReadRiskMethodologyImpactFactorOption]: ['risk-risk-methodology-impact-factor-option-read'],
    [AppPermissions.DeleteRiskMethodologyImpactFactorOption]: ['risk-risk-methodology-impact-factor-option-admin'],

    [AppPermissions.CreateRiskMethodologyImpactValue]: ['risk-risk-methodology-impact-value-create'],
    [AppPermissions.UpdateRiskMethodologyImpactValue]: ['risk-risk-methodology-impact-value-read', 'risk-risk-methodology-impact-value-update'],
    [AppPermissions.ReadRiskMethodologyImpactValue]: ['risk-risk-methodology-impact-value-read'],
    [AppPermissions.DeleteRiskMethodologyImpactValue]: ['risk-risk-methodology-impact-value-admin'],

    [AppPermissions.CreateRiskMethodologyLikelihoodValue]: ['risk-risk-methodology-likelihood-value-create'],
    [AppPermissions.UpdateRiskMethodologyLikelihoodValue]: ['risk-risk-methodology-likelihood-value-read', 'risk-risk-methodology-likelihood-value-update'],
    [AppPermissions.ReadRiskMethodologyLikelihoodValue]: ['risk-risk-methodology-likelihood-value-read'],
    [AppPermissions.DeleteRiskMethodologyLikelihoodValue]: ['risk-risk-methodology-likelihood-value-admin'],

    [AppPermissions.CreateRiskMethodologyThresholdValue]: ['risk-risk-methodology-threshold-value-create'],
    [AppPermissions.UpdateRiskMethodologyThresholdValue]: ['risk-risk-methodology-threshold-value-read', 'risk-risk-methodology-threshold-value-update'],
    [AppPermissions.ReadRiskMethodologyThresholdValue]: ['risk-risk-methodology-threshold-value-read'],
    [AppPermissions.DeleteRiskMethodologyThresholdValue]: ['risk-risk-methodology-threshold-value-admin'],

    [AppPermissions.CreateAttestation]: ['framework-attestation-create'],
    [AppPermissions.UpdateAttestation]: ['framework-attestation-read', 'framework-attestation-update'],
    [AppPermissions.ReadAttestation]: ['framework-attestation-read'],
    [AppPermissions.DeleteAttestation]: ['framework-attestation-admin'],

    [AppPermissions.CreateEvidence]: ['framework-evidence-create'],
    [AppPermissions.UpdateEvidence]: ['framework-evidence-read', 'framework-evidence-update'],
    [AppPermissions.ReadEvidence]: ['framework-evidence-read'],
    [AppPermissions.DeleteEvidence]: ['framework-evidence-admin'],

    [AppPermissions.CreateControl]: [
      'framework-control-create',
      'framework-policy-read',
      'framework-policy-body-section-read',
    ],
    [AppPermissions.UpdateControl]: ['framework-control-read', 'framework-control-update'],
    [AppPermissions.ReadControl]: ['framework-control-read'],
    [AppPermissions.DeleteControl]: ['framework-control-admin'],

    [AppPermissions.CreateReportControlGroupItem]: ['framework-report-control-group-item-create'],
    [AppPermissions.UpdateReportControlGroupItem]: ['framework-report-control-group-item-read', 'framework-report-control-group-item-update'],
    [AppPermissions.ReadReportControlGroupItem]: ['framework-report-control-group-item-read'],
    [AppPermissions.DeleteReportControlGroupItem]: ['framework-report-control-group-item-admin'],

    [AppPermissions.CreateReportControlItem]: ['framework-report-control-item-create'],
    [AppPermissions.UpdateReportControlItem]: ['framework-report-control-item-read', 'framework-report-control-item-update'],
    [AppPermissions.ReadReportControlItem]: ['framework-report-control-item-read'],
    [AppPermissions.DeleteReportControlItem]: ['framework-report-control-item-admin'],

    [AppPermissions.CreateReportControlObjectiveItem]: ['framework-report-control-objective-item-create'],
    [AppPermissions.UpdateReportControlObjectiveItem]: ['framework-report-control-objective-item-read', 'framework-report-control-objective-item-update'],
    [AppPermissions.ReadReportControlObjectiveItem]: ['framework-report-control-objective-item-read'],
    [AppPermissions.DeleteReportControlObjectiveItem]: ['framework-report-control-objective-item-admin'],

    [AppPermissions.CreateControlObjective]: ['framework-control-objective-create'],
    [AppPermissions.UpdateControlObjective]: ['framework-control-objective-read', 'framework-control-objective-update'],
    [AppPermissions.ReadControlObjective]: ['framework-control-objective-read'],
    [AppPermissions.DeleteControlObjective]: ['framework-control-objective-admin'],

    [AppPermissions.CreateControlCategory]: ['framework-control-category-create'],
    [AppPermissions.UpdateControlCategory]: ['framework-control-category-read', 'framework-control-category-update'],
    [AppPermissions.ReadControlCategory]: ['framework-control-category-read'],
    [AppPermissions.DeleteControlCategory]: ['framework-control-category-admin'],

    [AppPermissions.CreateControlGroup]: [
      'framework-control-group-create',
      'framework-policy-read',
      'framework-policy-body-section-read',
    ],
    [AppPermissions.UpdateControlGroup]: ['framework-control-group-read', 'framework-control-group-update'],
    [AppPermissions.ReadControlGroup]: ['framework-control-group-read'],
    [AppPermissions.DeleteControlGroup]: ['framework-control-group-admin'],

    [AppPermissions.CreateControlProcedure]: ['framework-control-procedure-create'],
    [AppPermissions.UpdateControlProcedure]: ['framework-control-procedure-read', 'framework-control-procedure-update'],
    [AppPermissions.ReadControlProcedure]: ['framework-control-procedure-read'],
    [AppPermissions.DeleteControlProcedure]: ['framework-control-procedure-admin'],

    [AppPermissions.CreatePolicy]: ['framework-policy-create'],
    [AppPermissions.UpdatePolicy]: ['framework-policy-read', 'framework-policy-update'],
    [AppPermissions.ReadPolicy]: ['framework-policy-read'],
    [AppPermissions.DeletePolicy]: ['framework-policy-admin'],

    [AppPermissions.CreatePublishedPolicy]: ['framework-published-policy-create'],
    [AppPermissions.UpdatePublishedPolicy]: ['framework-published-policy-read', 'framework-published-policy-update'],
    [AppPermissions.ReadPublishedPolicy]: ['framework-published-policy-read'],
    [AppPermissions.DeletePublishedPolicy]: ['framework-published-policy-admin'],

    [AppPermissions.CreatePolicyBody]: ['framework-policy-body-create'],
    [AppPermissions.UpdatePolicyBody]: [
      'framework-policy-body-read',
      'framework-policy-body-update',
      'framework-policy-read',
    ],
    [AppPermissions.ReadPolicyBody]: ['framework-policy-body-read', 'framework-policy-read'], //@TODO: to remove policy read permission we must discuss what should be shown of data in the form, approvers n reviewers charts ...etc.
    [AppPermissions.DeletePolicyBody]: ['framework-policy-body-admin'],

    [AppPermissions.CreatePolicyBodyTemplate]: ['framework-policy-body-template-create'],
    [AppPermissions.UpdatePolicyBodyTemplate]: [
      'framework-policy-body-template-read',
      'framework-policy-body-template-update',
    ],
    [AppPermissions.ReadPolicyBodyTemplate]: ['framework-policy-body-template-read'], //@TODO: to remove policy read permission we must discuss what should be shown of data in the form, approvers n reviewers charts ...etc.
    [AppPermissions.DeletePolicyBodyTemplate]: ['framework-policy-body-template-admin'],

    [AppPermissions.CreatePolicyBodySection]: ['framework-policy-body-section-create', 'framework-policy-body-update'],
    [AppPermissions.UpdatePolicyBodySection]: [
      'framework-policy-body-section-read',
      'framework-policy-body-section-update',
    ],
    [AppPermissions.ReadPolicyBodySection]: ['framework-policy-body-section-read'],
    [AppPermissions.DeletePolicyBodySection]: ['framework-policy-body-section-admin'],

    [AppPermissions.CreatePolicyBodySectionTemplate]: ['framework-policy-body-section-template-create'],
    [AppPermissions.UpdatePolicyBodySectionTemplate]: [
      'framework-policy-body-section-template-read',
      'framework-policy-body-section-template-update',
    ],
    [AppPermissions.ReadPolicyBodySectionTemplate]: ['framework-policy-body-section-template-read'],
    [AppPermissions.DeletePolicyBodySectionTemplate]: ['framework-policy-body-section-template-admin'],

    [AppPermissions.CreateStandard]: ['framework-standard-create'],
    [AppPermissions.UpdateStandard]: ['framework-standard-read', 'framework-standard-update'],
    [AppPermissions.ReadStandard]: ['framework-standard-read'],
    [AppPermissions.DeleteStandard]: ['framework-standard-admin'],

    // [AppPermissions.CreateStandardSection]: ['framework-standard-section-create'],
    // [AppPermissions.UpdateStandardSection]: ['framework-standard-section-read', 'framework-standard-section-update'],
    // [AppPermissions.ReadStandardSection]: ['framework-standard-read'],
    // [AppPermissions.DeleteStandardSection]: ['framework-standard-section-admin'],

    [AppPermissions.CreatePublishedStandard]: ['framework-published-standard-create'],
    [AppPermissions.UpdatePublishedStandard]: [
      'framework-published-standard-read',
      'framework-published-standard-update',
    ],
    [AppPermissions.ReadPublishedStandard]: ['framework-published-standard-read'],
    [AppPermissions.DeletePublishedStandard]: ['framework-published-standard-admin'],

    [AppPermissions.CreateGuideline]: ['framework-guideline-create'],
    [AppPermissions.UpdateGuideline]: ['framework-guideline-read', 'framework-guideline-update'],
    [AppPermissions.ReadGuideline]: ['framework-guideline-read'],
    [AppPermissions.DeleteGuideline]: ['framework-guideline-admin'],

    [AppPermissions.CreatePublishedGuideline]: ['framework-published-guideline-create'],
    [AppPermissions.UpdatePublishedGuideline]: [
      'framework-published-guideline-read',
      'framework-published-guideline-update',
    ],
    [AppPermissions.ReadPublishedGuideline]: ['framework-published-guideline-read'],
    [AppPermissions.DeletePublishedGuideline]: ['framework-published-guideline-admin'],

    [AppPermissions.CreateAuthorityDocument]: ['framework-authority-document-create'],
    [AppPermissions.UpdateAuthorityDocument]: [
      'framework-authority-document-read',
      'framework-authority-document-update',
    ],
    [AppPermissions.ReadAuthorityDocument]: ['framework-authority-document-read'],
    [AppPermissions.DeleteAuthorityDocument]: ['framework-authority-document-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateCitation]: [
      'framework-citation-create',
      'framework-citation-type-read',
      'framework-authority-document-read',
    ],
    [AppPermissions.UpdateCitation]: ['framework-citation-read', 'framework-citation-update'],
    [AppPermissions.ReadCitation]: ['framework-citation-read'],
    [AppPermissions.DeleteCitation]: ['framework-citation-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateCitationType]: ['framework-citation-type-create'],
    [AppPermissions.UpdateCitationType]: ['framework-citation-type-read', 'framework-citation-type-update'],
    [AppPermissions.ReadCitationType]: ['framework-citation-type-read'],
    [AppPermissions.DeleteCitationType]: ['framework-citation-type-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateExemption]: [
      'framework-exemption-create',
      'framework-authority-document-read',
      'framework-citation-read',
    ],
    [AppPermissions.UpdateExemption]: [
      'framework-exemption-read',
      'framework-exemption-update',
      'framework-authority-document-read',
      'framework-citation-read',
    ],
    [AppPermissions.ReadExemption]: ['framework-exemption-read'],
    [AppPermissions.DeleteExemption]: ['framework-exemption-admin'],

    [AppPermissions.CreateTask]: ['user-operations-task-create'],
    [AppPermissions.UpdateTask]: ['user-operations-task-read', 'user-operations-task-update'],
    [AppPermissions.ReadTask]: ['user-operations-task-read'],
    [AppPermissions.DeleteTask]: ['user-operations-task-admin'],

    [AppPermissions.CreateMyTask]: ['user-operations-my-task-create'],
    [AppPermissions.UpdateMyTask]: ['user-operations-my-task-read', 'user-operations-my-task-update'],
    [AppPermissions.ReadMyTask]: ['user-operations-my-task-read'],
    [AppPermissions.DeleteMyTask]: ['user-operations-my-task-admin'],

    [AppPermissions.CreateIssue]: ['issues-issue-create', 'issues-issue-type-read'],
    [AppPermissions.UpdateIssue]: ['issues-issue-read', 'issues-issue-update'],
    [AppPermissions.ReadIssue]: ['issues-issue-read'],
    [AppPermissions.DeleteIssue]: ['issues-issue-admin'],

    [AppPermissions.CreateIssueType]: ['issues-issue-type-create'],
    [AppPermissions.UpdateIssueType]: ['issues-issue-type-read', 'issues-issue-type-update'],
    [AppPermissions.ReadIssueType]: ['issues-issue-type-read'],
    [AppPermissions.DeleteIssueType]: ['issues-issue-type-admin'],

    [AppPermissions.CreateRemediation]: ['issues-remediation-create', 'issues-issue-read'],
    [AppPermissions.UpdateRemediation]: ['issues-remediation-read', 'issues-remediation-update'],
    [AppPermissions.ReadRemediation]: ['issues-remediation-read'],
    [AppPermissions.DeleteRemediation]: ['issues-remediation-admin'],

    [AppPermissions.CreateRemediationAction]: ['issues-remediation-action-create'],
    [AppPermissions.UpdateRemediationAction]: ['issues-remediation-action-read', 'issues-remediation-action-update'],
    [AppPermissions.ReadRemediationAction]: ['issues-remediation-action-read'],
    [AppPermissions.DeleteRemediationAction]: ['issues-remediation-action-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateRole]: ['user-role-create'],
    [AppPermissions.UpdateRole]: ['user-role-read', 'user-role-update'],
    [AppPermissions.ReadRole]: ['user-role-read'],
    [AppPermissions.DeleteRole]: ['user-role-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateGroup]: ['user-group-create'],
    [AppPermissions.UpdateGroup]: ['user-group-read', 'user-group-update'],
    [AppPermissions.ReadGroup]: ['user-group-read'],
    [AppPermissions.DeleteGroup]: ['user-group-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateUser]: ['user-user-create'],
    [AppPermissions.UpdateUser]: ['user-user-read', 'user-user-update'],
    [AppPermissions.ReadUser]: ['user-user-read'],
    [AppPermissions.DeleteUser]: ['user-user-admin'],

    //@TODO: changed permissions once ready
    // [AppPermissions.CreateFinding]: ['finding-create'],
    // [AppPermissions.UpdateFinding]: ['finding-read', 'finding-update'],
    //   [AppPermissions.ReadFinding]: ['finding-read'],
    // [AppPermissions.DeleteFinding]: ['finding-admin'],

    [AppPermissions.CreateAcknowledgment]: ['acknowledgment-acknowledgment-create'],
    [AppPermissions.UpdateAcknowledgment]: [
      'acknowledgment-acknowledgment-read',
      'acknowledgment-acknowledgment-update',
      'acknowledgment-acknowledgment-request-read',
    ],
    [AppPermissions.ReadAcknowledgment]: [
      'acknowledgment-acknowledgment-read',
      'acknowledgment-acknowledgment-request-read',
    ],
    [AppPermissions.DeleteAcknowledgment]: ['acknowledgment-acknowledgment-admin'],

    [AppPermissions.CreateMyAcknowledgment]: [
      'acknowledgment-my-acknowledgment-create',
      'acknowledgment-acknowledgment-request-create',
    ],
    [AppPermissions.UpdateMyAcknowledgment]: [
      'acknowledgment-my-acknowledgment-read',
      'acknowledgment-my-acknowledgment-update',
    ],
    [AppPermissions.ReadMyAcknowledgment]: ['acknowledgment-my-acknowledgment-read'],
    [AppPermissions.DeleteMyAcknowledgment]: ['acknowledgment-my-acknowledgment-admin'],

    [AppPermissions.CreateAcknowledgmentRequest]: ['acknowledgment-acknowledgment-request-create'],
    [AppPermissions.UpdateAcknowledgmentRequest]: [
      'acknowledgment-acknowledgment-request-read',
      'acknowledgment-acknowledgment-request-update',
    ],
    [AppPermissions.ReadAcknowledgmentRequest]: ['acknowledgment-acknowledgment-request-read'],
    [AppPermissions.DeleteAcknowledgmentRequest]: ['acknowledgment-acknowledgment-request-admin'],

    [AppPermissions.CreateNotification]: ['user-operations-notification-create'],
    [AppPermissions.UpdateNotification]: ['user-operations-notification-read', 'user-operations-notification-update'],
    [AppPermissions.ReadNotification]: ['user-operations-notification-read'],
    [AppPermissions.DeleteNotification]: ['user-operations-notification-admin'],

    [AppPermissions.CreateMyNotification]: ['user-operations-my-notification-create'],
    [AppPermissions.UpdateMyNotification]: [
      'user-operations-my-notification-read',
      'user-operations-my-notification-update',
    ],
    [AppPermissions.ReadMyNotification]: ['user-operations-my-notification-read'],
    [AppPermissions.DeleteMyNotification]: ['user-operations-my-notification-admin'],

    //@TODO: changed permissions once ready
    [AppPermissions.CreateFieldTemplate]: ['data-structure-field-template-create'],
    [AppPermissions.UpdateFieldTemplate]: [
      'data-structure-field-template-read',
      'data-structure-field-template-update',
    ],
    [AppPermissions.ReadFieldTemplate]: ['data-structure-field-template-read'],
    [AppPermissions.DeleteFieldTemplate]: ['data-structure-field-template-admin'],

    [AppPermissions.CreateException]: ['framework-exception-create'],
    [AppPermissions.UpdateException]: ['framework-exception-read', 'framework-exception-update'],
    [AppPermissions.ReadException]: ['framework-exception-read'],
    [AppPermissions.DeleteException]: ['framework-exception-admin'],

    [AppPermissions.CreateStaticValue]: ['branding-static-value-create'],
    [AppPermissions.UpdateStaticValue]: ['branding-static-value-read', 'branding-static-value-update'],
    [AppPermissions.ReadStaticValue]: ['branding-static-value-read'],
    [AppPermissions.DeleteStaticValue]: ['branding-static-value-admin'],

    [AppPermissions.CreateRelationType]: ['relation-relation-type-create'],
    [AppPermissions.UpdateRelationType]: ['relation-relation-type-read', 'relation-relation-type-update'],
    [AppPermissions.ReadRelationType]: ['relation-relation-type-read'],
    [AppPermissions.DeleteRelationType]: ['relation-relation-type-admin'],

    [AppPermissions.CreateGlobalValueList]: ['data-structure-global-value-list-create'],
    [AppPermissions.UpdateGlobalValueList]: ['data-structure-global-value-list-update'],
    [AppPermissions.ReadGlobalValueList]: ['data-structure-global-value-list-read'],
    [AppPermissions.DeleteGlobalValueList]: ['data-structure-global-value-list-admin'],

    [AppPermissions.CreateGlobalValueListItem]: ['data-structure-global-value-list-Item-create'],
    [AppPermissions.UpdateGlobalValueListItem]: ['data-structure-global-value-list-Item-update'],
    [AppPermissions.ReadGlobalValueListItem]: ['data-structure-global-value-list-Item-read'],
    [AppPermissions.DeleteGlobalValueListItem]: ['data-structure-global-value-list-Item-admin'],

    [AppPermissions.CreateComplianceReport]: ['framework-compliance-report-create', 'framework-policy-read'],
    [AppPermissions.UpdateComplianceReport]: ['framework-compliance-report-update'],
    [AppPermissions.ReadComplianceReport]: ['framework-compliance-report-read'],
    [AppPermissions.DeleteComplianceReport]: ['framework-compliance-report-admin'],

    [AppPermissions.CreateComplianceReportResult]: ['framework-compliance-report-result-create'],
    [AppPermissions.UpdateComplianceReportResult]: ['framework-compliance-report-result-update'],
    [AppPermissions.ReadComplianceReportResult]: ['framework-compliance-report-result-read'],
    [AppPermissions.DeleteComplianceReportResult]: ['framework-compliance-report-result-admin'],

    [AppPermissions.CreateControlsGapReport]: ['framework-control-gap-report-create'],
    [AppPermissions.UpdateControlsGapReport]: ['framework-control-gap-report-update'],
    [AppPermissions.ReadControlsGapReport]: ['framework-control-gap-report-read'],
    [AppPermissions.DeleteControlsGapReport]: ['framework-control-gap-report-admin'],

    [AppPermissions.CreateControlsGapReportResult]: ['framework-control-gap-report-result-create'],
    [AppPermissions.UpdateControlsGapReportResult]: ['framework-control-gap-report-result-update'],
    [AppPermissions.ReadControlsGapReportResult]: ['framework-control-gap-report-result-read'],
    [AppPermissions.DeleteControlsGapReportResult]: ['framework-control-gap-report-result-admin'],

    // [AppPermissions.CreateCitationComplianceReport]: ['framework-citation-compliance-report-create'],
    // [AppPermissions.UpdateCitationComplianceReport]: ['framework-citation-compliance-report-update'],
    // [AppPermissions.ReadCitationComplianceReport]: ['framework-citation-compliance-report-read'],
    // [AppPermissions.DeleteCitationComplianceReport]: ['framework-citation-compliance-report-admin'],

    // [AppPermissions.CreateCitationComplianceReportResult]: ['framework-citation-compliance-report-result-create'],
    // [AppPermissions.UpdateCitationComplianceReportResult]: ['framework-citation-compliance-report-result-update'],
    // [AppPermissions.ReadCitationComplianceReportResult]: ['framework-citation-compliance-report-result-read'],
    // [AppPermissions.DeleteCitationComplianceReportResult]: ['framework-citation-compliance-report-result-admin'],

    [AppPermissions.CreateEmailTemplate]: ['user-operations-email-template-create'],
    [AppPermissions.UpdateEmailTemplate]: [
      'user-operations-email-template-read',
      'user-operations-email-template-update',
    ],
    [AppPermissions.ReadEmailTemplate]: ['user-operations-email-template-read'],
    [AppPermissions.DeleteEmailTemplate]: ['user-operations-email-template-admin'],

    [AppPermissions.CreateTaskTemplate]: ['user-operations-task-template-create'],
    [AppPermissions.UpdateTaskTemplate]: ['user-operations-task-template-read', 'user-operations-task-template-update'],
    [AppPermissions.ReadTaskTemplate]: ['user-operations-task-template-read'],
    [AppPermissions.DeleteTaskTemplate]: ['user-operations-task-template-admin'],

    [AppPermissions.CreateAuditTrail]: ['user-operations-audit-trail-create'],
    [AppPermissions.UpdateAuditTrail]: ['user-operations-audit-trail-read', 'user-operations-audit-trail-update'],
    [AppPermissions.ReadAuditTrail]: ['user-operations-audit-trail-read'],
    [AppPermissions.DeleteAuditTrail]: ['user-operations-audit-trail-admin'],

    [AppPermissions.CreateSubscription]: ['user-operations-subscription-create'],
    [AppPermissions.UpdateSubscription]: ['user-operations-subscription-read', 'user-operations-subscription-update'],
    [AppPermissions.ReadSubscription]: ['user-operations-subscription-read'],
    [AppPermissions.DeleteSubscription]: ['user-operations-subscription-admin'],

    [AppPermissions.CreateAuditUniverse]: ['audit-audit-universe-create'],
    [AppPermissions.UpdateAuditUniverse]: ['audit-audit-universe-read', 'audit-audit-universe-update'],
    [AppPermissions.ReadAuditUniverse]: ['audit-audit-universe-read'],
    [AppPermissions.DeleteAuditUniverse]: ['audit-audit-universe-admin'],

    [AppPermissions.CreateAuditCase]: ['audit-audit-case-create'],
    [AppPermissions.UpdateAuditCase]: ['audit-audit-case-read', 'audit-audit-case-update'],
    [AppPermissions.ReadAuditCase]: ['audit-audit-case-read'],
    [AppPermissions.DeleteAuditCase]: ['audit-audit-case-admin'],

    [AppPermissions.CreateAuditCaseResult]: ['audit-audit-case-result-create'],
    [AppPermissions.UpdateAuditCaseResult]: ['audit-audit-case-result-read', 'audit-audit-case-result-update'],
    [AppPermissions.ReadAuditCaseResult]: ['audit-audit-case-result-read'],
    [AppPermissions.DeleteAuditCaseResult]: ['audit-audit-case-result-admin'],

    [AppPermissions.CreateAuditProgram]: ['audit-audit-program-create'],
    [AppPermissions.UpdateAuditProgram]: ['audit-audit-program-read', 'audit-audit-program-update'],
    [AppPermissions.ReadAuditProgram]: ['audit-audit-program-read'],
    [AppPermissions.DeleteAuditProgram]: ['audit-audit-program-admin'],

    [AppPermissions.CreateAuditProgramReport]: ['audit-audit-program-report-create'],
    [AppPermissions.UpdateAuditProgramReport]: ['audit-audit-program-report-read', 'audit-audit-program-report-update'],
    [AppPermissions.ReadAuditProgramReport]: ['audit-audit-program-report-read'],
    [AppPermissions.DeleteAuditProgramReport]: ['audit-audit-program-report-admin'],

    [AppPermissions.CreateAuditProgramType]: ['audit-audit-program-type-create'],
    [AppPermissions.UpdateAuditProgramType]: ['audit-audit-program-type-read', 'audit-audit-program-type-update'],
    [AppPermissions.ReadAuditProgramType]: ['audit-audit-program-type-read'],
    [AppPermissions.DeleteAuditProgramType]: ['audit-audit-program-type-admin'],

    [AppPermissions.CreateAuditProject]: ['audit-audit-project-create'],
    [AppPermissions.UpdateAuditProject]: ['audit-audit-project-read', 'audit-audit-project-update'],
    [AppPermissions.ReadAuditProject]: ['audit-audit-project-read'],
    [AppPermissions.DeleteAuditProject]: ['audit-audit-project-admin'],

    [AppPermissions.CreateAuditObjective]: ['audit-audit-objective-create'],
    [AppPermissions.UpdateAuditObjective]: ['audit-audit-objective-read', 'audit-audit-objective-update'],
    [AppPermissions.ReadAuditObjective]: ['audit-audit-objective-read'],
    [AppPermissions.DeleteAuditObjective]: ['audit-audit-objective-admin'],

    [AppPermissions.CreateAuditProjectReport]: ['audit-audit-project-report-create'],
    [AppPermissions.UpdateAuditProjectReport]: ['audit-audit-project-report-read', 'audit-audit-project-report-update'],
    [AppPermissions.ReadAuditProjectReport]: ['audit-audit-project-report-read'],
    [AppPermissions.DeleteAuditProjectReport]: ['audit-audit-project-report-admin'],

    [AppPermissions.CreateAuditCaseTemplate]: ['audit-audit-task-template-create'],
    [AppPermissions.UpdateAuditCaseTemplate]: ['audit-audit-task-template-read', 'audit-audit-project-update'],
    [AppPermissions.ReadAuditCaseTemplate]: ['audit-audit-task-template-read'],
    [AppPermissions.DeleteAuditCaseTemplate]: ['audit-audit-task-template-admin'],

    [AppPermissions.CreateAuditRequest]: ['audit-audit-request-create'],
    [AppPermissions.UpdateAuditRequest]: ['audit-audit-request-read', 'audit-audit-request-update'],
    [AppPermissions.ReadAuditRequest]: ['audit-audit-request-read'],
    [AppPermissions.DeleteAuditRequest]: ['audit-audit-request-admin'],

    [AppPermissions.CreateAuditExpense]: ['audit-audit-expense-create'],
    [AppPermissions.UpdateAuditExpense]: ['audit-audit-expense-read', 'audit-audit-expense-update'],
    [AppPermissions.ReadAuditExpense]: ['audit-audit-expense-read'],
    [AppPermissions.DeleteAuditExpense]: ['audit-audit-expense-admin'],

    [AppPermissions.CreateImportGlobalPackage]: ['hup-import-global-package-create'],
    [AppPermissions.UpdateImportGlobalPackage]: ['hup-import-global-package-read', 'hup-import-global-package-update'],
    [AppPermissions.ReadImportGlobalPackage]: ['hup-import-global-package-read'],
    [AppPermissions.DeleteImportGlobalPackage]: ['hup-import-global-package-admin'],

    [AppPermissions.CreateExportGlobalPackage]: ['hup-export-global-package-create'],
    [AppPermissions.UpdateExportGlobalPackage]: ['hup-export-global-package-read', 'hup-export-global-package-update'],
    [AppPermissions.ReadExportGlobalPackage]: ['hup-export-global-package-read'],
    [AppPermissions.DeleteExportGlobalPackage]: ['hup-export-global-package-admin'],

    [AppPermissions.CreateRiskThreat]: ['risk-risk-threat-create'],
    [AppPermissions.UpdateRiskThreat]: ['risk-risk-threat-read', 'risk-risk-threat-update'],
    [AppPermissions.ReadRiskThreat]: ['risk-risk-threat-read'],
    [AppPermissions.DeleteRiskThreat]: ['risk-risk-threat-admin'],

    [AppPermissions.CreateRiskVulnerability]: ['risk-risk-vulnerability-create'],
    [AppPermissions.UpdateRiskVulnerability]: ['risk-risk-vulnerability-read', 'risk-risk-vulnerability-update'],
    [AppPermissions.ReadRiskVulnerability]: ['risk-risk-vulnerability-read'],
    [AppPermissions.DeleteRiskVulnerability]: ['risk-risk-vulnerability-admin'],

    [AppPermissions.CreateFilter]: ['data-structure-filter-create'],
    [AppPermissions.UpdateFilter]: ['data-structure-filter-read', 'data-structure-filter-update'],
    [AppPermissions.ReadFilter]: ['data-structure-filter-read'],
    [AppPermissions.DeleteFilter]: ['data-structure-filter-admin'],

    [AppPermissions.CreateContentView]: ['data-structure-content-view-create'],
    [AppPermissions.UpdateContentView]: ['data-structure-content-view-read', 'data-structure-content-view-update'],
    [AppPermissions.ReadContentView]: ['data-structure-content-view-read'],
    [AppPermissions.DeleteContentView]: ['data-structure-content-view-admin'],

    [AppPermissions.CreateDashboard]: ['data-structure-dashboard-create'],
    [AppPermissions.UpdateDashboard]: ['data-structure-dashboard-read', 'data-structure-dashboard-update'],
    [AppPermissions.ReadDashboard]: ['data-structure-dashboard-read'],
    [AppPermissions.DeleteDashboard]: ['data-structure-dashboard-admin'],

    [AppPermissions.CreateReport]: ['data-structure-report-create'],
    [AppPermissions.UpdateReport]: ['data-structure-report-read', 'data-structure-report-update'],
    [AppPermissions.ReadReport]: ['data-structure-report-read'],
    [AppPermissions.DeleteReport]: ['data-structure-report-admin'],

    [AppPermissions.CreateReportScheduler]: ['data-structure-report-scheduler-create'],
    [AppPermissions.UpdateReportScheduler]: [
      'data-structure-report-scheduler-read',
      'data-structure-report-scheduler-update',
    ],
    [AppPermissions.ReadReportScheduler]: ['data-structure-report-scheduler-read'],
    [AppPermissions.DeleteReportScheduler]: ['data-structure-report-scheduler-admin'],

    [AppPermissions.CreateReportConfigurationTemplate]: ['data-structure-report-configuration-template-create'],
    [AppPermissions.UpdateReportConfigurationTemplate]: [
      'data-structure-report-configuration-template-read',
      'data-structure-report-configuration-template-update',
    ],
    [AppPermissions.ReadReportConfigurationTemplate]: ['data-structure-report-configuration-template-read'],
    [AppPermissions.DeleteReportConfigurationTemplate]: ['data-structure-report-configuration-template-admin'],

    [AppPermissions.CreateLanguage]: ['data-structure-language-create'],
    [AppPermissions.UpdateLanguage]: ['data-structure-language-read', 'data-structure-language-update'],
    [AppPermissions.ReadLanguage]: ['data-structure-language-read'],
    [AppPermissions.DeleteLanguage]: ['data-structure-language-admin'],

    [AppPermissions.CreateWorkspace]: ['data-structure-workspace-create'],
    [AppPermissions.UpdateWorkspace]: ['data-structure-workspace-read', 'data-structure-workspace-update'],
    [AppPermissions.ReadWorkspace]: ['data-structure-workspace-read'],
    [AppPermissions.DeleteWorkspace]: ['data-structure-workspace-admin'],

    [AppPermissions.CreateTransparencyCase]: ['tm-transparency-case-create'],
    [AppPermissions.UpdateTransparencyCase]: ['tm-transparency-case-read', 'tm-transparency-case-update'],
    [AppPermissions.ReadTransparencyCase]: ['tm-transparency-case-read'],
    [AppPermissions.DeleteTransparencyCase]: ['tm-transparency-case-admin'],

    [AppPermissions.CreateTransparencyCaseCategory]: ['tm-transparency-case-category-create'],
    [AppPermissions.UpdateTransparencyCaseCategory]: [
      'tm-transparency-case-category-read',
      'tm-transparency-case-category-update',
    ],
    [AppPermissions.ReadTransparencyCaseCategory]: ['tm-transparency-case-category-read'],
    [AppPermissions.DeleteTransparencyCaseCategory]: ['tm-transparency-case-category-admin'],

    [AppPermissions.CreateStatisticCollector]: ['statistic-collector-create'],
    [AppPermissions.UpdateStatisticCollector]: ['statistic-collector-read', 'statistic-collector-update'],
    [AppPermissions.ReadStatisticCollector]: ['statistic-collector-read'],
    [AppPermissions.DeleteStatisticCollector]: ['statistic-collector-admin'],

    [AppPermissions.CreateGeneratedStatistic]: ['generated-statistic-create'],
    [AppPermissions.UpdateGeneratedStatistic]: ['generated-statistic-read', 'generated-statistic-update'],
    [AppPermissions.ReadGeneratedStatistic]: ['generated-statistic-read'],
    [AppPermissions.DeleteGeneratedStatistic]: ['generated-statistic-admin'],

    [AppPermissions.CreateGeneratedReport]: ['generated-report-create'],
    [AppPermissions.UpdateGeneratedReport]: ['generated-report-read', 'generated-report-update'],
    [AppPermissions.ReadGeneratedReport]: ['generated-report-read'],
    [AppPermissions.DeleteGeneratedReport]: ['generated-report-admin'],

    [AppPermissions.CreateTag]: ['data-structure-tag-create'],
    [AppPermissions.UpdateTag]: ['data-structure-tag-read', 'data-structure-tag-update'],
    [AppPermissions.ReadTag]: ['data-structure-tag-read'],
    [AppPermissions.DeleteTag]: ['data-structure-tag-admin'],

    [AppPermissions.CreateMetric]: ['performance-metric-create'],
    [AppPermissions.UpdateMetric]: ['performance-metric-read', 'performance-metric-update'],
    [AppPermissions.ReadMetric]: ['performance-metric-read'],
    [AppPermissions.DeleteMetric]: ['performance-metric-admin'],

    [AppPermissions.CreateMetricValue]: ['performance-metric-value-create'],
    [AppPermissions.UpdateMetricValue]: ['performance-metric-value-read', 'performance-metric-value-update'],
    [AppPermissions.ReadMetricValue]: ['performance-metric-value-read'],
    [AppPermissions.DeleteMetricValue]: ['performance-metric-value-admin'],

    [AppPermissions.CreateMetricCategory]: ['performance-metric-category-create'],
    [AppPermissions.UpdateMetricCategory]: ['performance-metric-category-read', 'performance-metric-category-update'],
    [AppPermissions.ReadMetricCategory]: ['performance-metric-category-read'],
    [AppPermissions.DeleteMetricCategory]: ['performance-metric-category-admin'],

    [AppPermissions.CreateCampaign]: ['question-campaign-create'],
    [AppPermissions.UpdateCampaign]: ['question-campaign-read', 'question-campaign-update'],
    [AppPermissions.ReadCampaign]: ['question-campaign-read'],
    [AppPermissions.DeleteCampaign]: ['question-campaign-admin'],

    [AppPermissions.CreateQuestionnaireRequest]: ['question-questionnaire-request-create'],
    [AppPermissions.UpdateQuestionnaireRequest]: [
      'question-questionnaire-request-read',
      'question-questionnaire-request-update',
    ],
    [AppPermissions.ReadQuestionnaireRequest]: ['question-questionnaire-request-read'],
    [AppPermissions.DeleteQuestionnaireRequest]: ['question-questionnaire-request-admin'],

    [AppPermissions.CreateQuestionnaireCampaignRequest]: ['question-questionnaire-request-create'],
    [AppPermissions.UpdateQuestionnaireCampaignRequest]: [
      'question-questionnaire-request-read',
      'question-questionnaire-request-update',
    ],
    [AppPermissions.ReadQuestionnaireCampaignRequest]: ['question-questionnaire-request-read'],
    [AppPermissions.DeleteQuestionnaireCampaignRequest]: ['question-questionnaire-request-admin'],
  };
  initializeUserPermissions(userInfo, licensedModules: ApiResponseListModuleName.DataEnum[]) {
    if (this.isInitialized) return;

    // const tPerms = this.keycloak.getUserRoles();
    const allPerms: string[] = [];
    Object.keys(this.permissionsMap).forEach((key) => {
      allPerms.push(...this.permissionsMap[key]);
    });
    const formattedLicensedModules = licensedModules.map((x) => {
      const key =
        x != 'ALL'
          ? x
            ?.substring(0, x?.length - 4)
            ?.replace(/_/g, '-')
            ?.toLowerCase()
          : 'ALL'; //remove _APP then replace _ with - , then to lower case
      return key;
    });
    let allLicensedPerms = ['authenticated'];
    if (licensedModules.indexOf('ALL') != -1) {
      allLicensedPerms.push(...allPerms);
    } else {
      formattedLicensedModules.forEach((moduleKey) => {
        const arrayToAdd = allPerms.filter((x) => x.startsWith(moduleKey));
        allLicensedPerms.push(...arrayToAdd);
      });
    }
    let tempPerms: string[] = [];
    // if(this.keycloak.getToken()){
    // this.userService.getUserInfo().subscribe((res)=>{

    let a = ['authenticated'];
    if (!isNullObj(userInfo?.resource_access)) {
      Object.keys(userInfo?.resource_access)?.forEach((key) => {
        let item = userInfo?.resource_access[key];
        a = a.concat((item?.roles as string[])?.filter((x) => allLicensedPerms.includes(x)));
      });
    }

    tempPerms = a;
    const perms: string[] = this.getViewPermissions(userInfo?.sf_sys_admin ? allLicensedPerms : tempPerms);
    this.permissionsService.loadPermissions(perms);
    if (perms.length > 0) {
      this.isInitialized = true;
    }
    // })
    // }

    // const perms: string[] = this.getViewPermissions(tempPerms);
    // this.permissionsService.loadPermissions(perms);
  }

  private canAddPermission(requiredPerms: string[], permsList: string[]) {
    const containsAll = requiredPerms.every((element) => {
      return permsList.includes(element);
    });
    return !!containsAll;
  }

  private getViewPermissions(permList: string[]) {
    const basePerms: string[] = [];
    Object.keys(this.permissionsMap).forEach((key) => {
      this.canAddPermission(this.permissionsMap[key], permList) ? basePerms.push(key) : null;
    });
    return basePerms;
  }
}
