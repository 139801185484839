import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseResponse, Control, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlDataService extends BaseRequestControllerWithRuleService<Control, ControlEvents.ControlEventsEnum> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/controls', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'description',
      'attestationRespondent',
      'controlStatus',
      'controlAttestation',
      'controlAttestationTemplate',
      'policy',
      'policySection',
      'controlFrequency',
      'controlId',
      'controlComplianceStatus',
      'controlEntity',
      'questionnaireValueId',
      'controlClassification',
      'controlObjective',
      'controlGroup',
      'standard',
      'standardSection',
      'owner',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/control-management/control/list`);
  }

  sendAttestation(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<any>>(this.url + '/send/' + id, options) as Observable<
      BaseResponse<Control>
    >;
  }

  completeControl(status, body, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(
      this.url + '/control/complete/list' + '/' + status,
      body,
      options
    ) as Observable<BaseResponse<Control>>;
  }
}

export namespace ControlEvents {
  export type ControlEventsEnum =
    | 'ADD_EXCEPTION'
    | 'COMPLETE_CONTROL'
    | 'TO_DRAFT'
    | 'TO_ATTEST'
    | 'TO_MONITOR'
    | 'TO_ATTEST_FROM_MONITOR'
    | 'TO_RETIRED'
    | 'TO_RETIRED_FROM_DRAFT'
    | 'TO_RETIRED_FROM_ATTEST';
  export const ControlEventsEnum = {
    COMPLETE_CONTROL: 'COMPLETE_CONTROL' as ControlEventsEnum,
    ADD_EXCEPTION: 'ADD_EXCEPTION' as ControlEventsEnum,
    TO_DRAFT: 'TO_DRAFT' as ControlEventsEnum,
    TO_ATTEST: 'TO_ATTEST' as ControlEventsEnum,
    TO_MONITOR: 'TO_MONITOR' as ControlEventsEnum,
    TO_ATTEST_FROM_MONITOR: 'TO_ATTEST_FROM_MONITOR' as ControlEventsEnum,
    TO_RETIRED: 'TO_RETIRED' as ControlEventsEnum,
    TO_RETIRED_FROM_DRAFT: 'TO_RETIRED_FROM_DRAFT' as ControlEventsEnum,
    TO_RETIRED_FROM_ATTEST: 'TO_RETIRED_FROM_ATTEST' as ControlEventsEnum,
  };
}
