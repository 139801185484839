import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, ControlGroup, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlGroupDataService extends BaseRequestControllerService<ControlGroup> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/control-groups', [
      'id',
      'uuid',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'description',
      'policy',
      'controlGroupStatus',
      'owner',
      'attestationRespondent',
      'controlFrequency',
      'controlAttestationTemplate',
      'controlEntities',
      'policySection',
      'progress',
      'controlClassification',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/control-management/control-group/list`);
  }

  toggleState<T>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/toggle/' + id, options) as Observable<
      BaseResponse<any>
    >;
  }

  linkControlObjectives(
    code: string,
    body: { link: string[]; unLink: string[]; addAndLink: string[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<BaseResponse<any>>(this.url + '/' + code + '/link', body, options) as Observable<
      BaseResponse<any>
    >;
  }
}
