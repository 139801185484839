<div class="bs-progress" [style]="containerStyle">
  <div
    [pTooltip]="tooltipHint || (data | toFixedDigits) + '%'"
    [tooltipPosition]="tooltipPosition"
    [class]="
      'bs-progress-bar ' +
      (striped ? ' bs-progress-bar-striped ' : '') +
      (animated ? ' bs-progress-bar ' : '') +
      (bgColor ?? '')
    "
    role="progressbar"
    [style]="'width: ' + data + '%; ' + (styleBasedColor ? ' background-color:' + styleBasedColor + '; ' : '')"
  >
    <p class="p-component" [style]="{ color: getReversedColor(bgColor) }">
      @if (onBarValue) {
        <span
          [style]="
            this.appStateService.colorScheme === 'light'
              ? { textShadow: '0 0 5px white', color: 'black' }
              : { textShadow: '0 0 5px black', color: 'white' }
          "
        >
          {{ onBarValue }}
        </span>
      }
    </p>
  </div>
</div>
