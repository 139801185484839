/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PDFRenderConfig } from './pDFRenderConfig';
import { AccessRecord } from './accessRecord';
import { RunAs } from './runAs';
import { ReportDetails } from './reportDetails';


export interface ReportConfigurationTemplateDto { 
    id?: string;
    searchScore?: number;
    uuid?: string;
    description?: string;
    recordStatus?: ReportConfigurationTemplateDto.RecordStatusEnum;
    code?: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    version?: number;
    lockedForEdit?: boolean;
    creatorName?: string;
    lastModifierName?: string;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
    module?: ReportConfigurationTemplateDto.ModuleEnum;
    systemGenerated?: boolean;
    parent?: string;
    parents?: Array<string>;
    translations?: { [key: string]: any; };
    systemLocked?: boolean;
    originalLanguage?: string;
    creationDate?: string;
    softDeleteDate?: string;
    lastModificationDate?: string;
    lockedUntil?: string;
    name?: string;
    reportFormat?: ReportConfigurationTemplateDto.ReportFormatEnum;
    reportDetails?: ReportDetails;
    pdfRenderConfig?: PDFRenderConfig;
    runAs?: RunAs;
    pageLocation?: ReportConfigurationTemplateDto.PageLocationEnum;
    lang?: string;
    theme?: ReportConfigurationTemplateDto.ThemeEnum;
    colorPalette?: Array<string>;
    watermark?: string;
    watermarkOpacity?: number;
}
export namespace ReportConfigurationTemplateDto {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type ReportFormatEnum = 'PDF' | 'MS_WORD' | 'HTML' | 'MS_EXCEL' | 'MS_POWER_POINT';
    export const ReportFormatEnum = {
        Pdf: 'PDF' as ReportFormatEnum,
        MsWord: 'MS_WORD' as ReportFormatEnum,
        Html: 'HTML' as ReportFormatEnum,
        MsExcel: 'MS_EXCEL' as ReportFormatEnum,
        MsPowerPoint: 'MS_POWER_POINT' as ReportFormatEnum
    };
    export type PageLocationEnum = 'NONE' | 'TOP_LEFT' | 'TOP_CENTER' | 'TOP_RIGHT' | 'TOP_START' | 'TOP_END' | 'BOTTOM_LEFT' | 'BOTTOM_CENTER' | 'BOTTOM_RIGHT' | 'BOTTOM_START' | 'BOTTOM_END';
    export const PageLocationEnum = {
        None: 'NONE' as PageLocationEnum,
        TopLeft: 'TOP_LEFT' as PageLocationEnum,
        TopCenter: 'TOP_CENTER' as PageLocationEnum,
        TopRight: 'TOP_RIGHT' as PageLocationEnum,
        TopStart: 'TOP_START' as PageLocationEnum,
        TopEnd: 'TOP_END' as PageLocationEnum,
        BottomLeft: 'BOTTOM_LEFT' as PageLocationEnum,
        BottomCenter: 'BOTTOM_CENTER' as PageLocationEnum,
        BottomRight: 'BOTTOM_RIGHT' as PageLocationEnum,
        BottomStart: 'BOTTOM_START' as PageLocationEnum,
        BottomEnd: 'BOTTOM_END' as PageLocationEnum
    };
    export type ThemeEnum = 'DARK' | 'LIGHT';
    export const ThemeEnum = {
        Dark: 'DARK' as ThemeEnum,
        Light: 'LIGHT' as ThemeEnum
    };
}


