import { Injectable } from '@angular/core';
import { ApiResponseBoolean, BaseResponse, ChangeStatusBody, FlatRule, RequestHandlerOptions } from '@shared/classes';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../base-request-controller.service';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class BaseRequestControllerWithRuleService<BaseType, Events> extends BaseRequestControllerService<BaseType> {
  protected changeStatusUrl: string = '/changeStatus';
  protected bulkChangeStatusUrl: string = '/bulk-change-status';
  protected getRuleHandlerUrl: string = '/get-rule-handlers';

  constructor(
    private requestHandler: DataService,
    baseUrl: string = '',
    subUrl: string = '',
    defaultProjectionFields = ['id', 'code']
  ) {
    super(requestHandler, baseUrl, subUrl, defaultProjectionFields);
  }
  getRuleHandlers(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.requestHandler.getData<FlatRule[]>(
      `${this.url}${this.getRuleHandlerUrl}/${idOrCode}`,
      options
    ) as Observable<FlatRule[]>;
  }
  changeStatus(
    id: string,
    status: Events,
    body: { messages?: any } = {},
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.requestHandler.postData<ApiResponseBoolean>(
      `${this.url}${this.changeStatusUrl}/${id}/${status}`,
      body,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  bulkChangeStatus<T = BaseType>(
    body: { codes: string[]; toStatus: string; changeStatusBody?: ChangeStatusBody },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.requestHandler.postData<BaseResponse<T>>(
      this.url + this.bulkChangeStatusUrl,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
}
