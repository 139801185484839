import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  BasePaginationResponse,
  BaseResponse,
  FilterItem,
  PaginationModel,
  RelationItemDto,
  RelationRequestDto,
  RelationTypeDto,
  RequestHandlerOptions,
  TargetTypeEnum,
} from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../api/base-request-controller.service';
import { DataService } from '../api/data.service';

@Injectable({
  providedIn: 'root',
})
export class RelationsService extends BaseRequestControllerService<any> {
  private relationTypeSearchUrl: string = '/relation-type/search';
  private relationSearchUrl: string = '/search/';
  private relationTypesUrl: string = '/types';
  private relationTypesProjectionFields: string[] = [
    'code',
    'lockedBy',
    'lockedForEdit',
    'lockedUntil',
    'recordStatus',
    'name',
    'icon',
    'color',
    'riskCascadeDirection',
    'complianceMode',
  ];
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.relation, '/relations', ['id', 'code']);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  public getRelationsMap() {
    const newLocal = environment.relation + '/relations/typesMap';
    return this.dataService.getData<BaseResponse<any>>(newLocal, this.apiOptions) as Observable<BaseResponse<any>>;
  }

  public getBySourceCode(sourceCode: string) {
    const newLocal = environment.relation + `/relations/${sourceCode}`;
    return this.dataService.getData<BaseResponse<any>>(newLocal, this.apiOptions) as Observable<BaseResponse<any>>;
  }

  searchDynamic(
    pageOptions: PageableOptions,
    searchBody: {
      projectionFields?: string[];
      query?: any;
      filters?: FilterItem[];
    },
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl
  ) {
    searchBody.projectionFields = !!searchBody.projectionFields ? searchBody.projectionFields : [];
    return this.dataService.postData<BasePaginationResponse<any>>(searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<any>>;
  }

  createRelations(body: RelationRequestDto, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(this.url + `/saveAll`, body, options) as Observable<
      BaseResponse<RelationItemDto>
    >;
  }
  searchRelations(
    relationType: string,
    pageOptions: PageableOptions,
    filters: {
      sourceType?: TargetTypeEnum[];
      targetType?: TargetTypeEnum[];
      sourceCode?: string[];
      targetCode?: string[];
      directions?: RelationItemDto.SourceTypeEnum[];
    },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<PaginationModel<RelationItemDto>>(
      this.url + this.relationSearchUrl + relationType,
      filters,
      { ...options, params: { ...pageOptions, ...options.params } }
    ) as Observable<PaginationModel<RelationItemDto>>;
  }
  deleteRelation<T = RelationItemDto>(
    sourceCode: string,
    id: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.deleteData<BaseResponse<T>>(`${this.url}/${sourceCode}/${id}`, options) as Observable<
      BaseResponse<T>
    >;
  }
  getRelationTypes(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<any>>(`${this.url}${this.relationTypesUrl}`, options);
  }
  searchRelationTypes<T = RelationTypeDto>(
    pageOptions: PageableOptions,
    searchBody: { projectionFields?: string[]; query?: any; filters?: FilterItem[]; textSearch?: any },
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.relationTypeSearchUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : this.relationTypesProjectionFields;
    return this.dataService.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
}
