import { getEnumOptionsString } from '../helpers';
import { EntityTypeFieldDto } from '../model';
import { ModuleKeywords } from './ModuleKeywords';
export interface ImporterColumnDefinition {
  id: any;
  type?: 'text' | 'text_content' | 'date' | 'dropdown';
  field: ImporterField;
  fieldGroupKey?: string;
  customValueObject?: { valueKey: string; customObject: any };
  boundedSheetColumns?: ImporterSheetColumn[];
  isArray?: boolean;
  isCommaDelimiter?: boolean;
  delimiterType?: string;
}
export interface ImporterField {
  name: string;
  key: string;
  validators: ImporterValidator[];
  required: boolean;
  description?: string;
  hideInImporter?: boolean;
  calculationFunction?: (params: { body: any; currentData?: any; fieldKey?: string }) => string | any;
  calculationFunctionParams?: string[];
}
export interface ImporterValidator {
  key: 'dropdown' | 'text_content';
  value: any;
}
export interface ImporterSheetColumn {
  id: any;
  colName: string;
  colIndex: number;
  boundDefinition?: ImporterColumnDefinition;
  data?: any;
  validAmount?: number;
}
export const ImporterValidators = {
  required: {
    luckySheetValidator: {},
    name: 'Required',
    description: 'The field should be filled with data across all rows',
    icon: 'fa fa-asterisk',
  },
  dropdown: {
    luckySheetValidator: {},
    name: 'Dropdown List',
    description: 'The field value should be within a specific list',
    icon: 'pi pi-angle-down',
  },
};
export const ImporterColumnDefinitions: { [x: string]: ImporterColumnDefinition[] } = {
  // [ModuleKeywords.EntityLevels]: [],
  // [ModuleKeywords.EntityGroup]: [
  //     {
  //         id: 1,
  //         type: 'text',
  //         field: {
  //             name: 'Name',
  //             key: 'name',
  //             required: true,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 2,
  //         type: 'dropdown',
  //         field: {
  //             name: 'Category',
  //             key: 'category',
  //             required: true,
  //             validators: [
  //                 {
  //                     key: 'dropdown',
  //                     value: {
  //                         "type": "dropdown",
  //                         "type2": null,
  //                         "value1": getEnumOptionsString(EntityTypeFieldDto.CategoryEnum),
  //                         "value2": "",
  //                         "checked": false,
  //                         "remote": false,
  //                         "prohibitInput": false,
  //                         "hintShow": false,
  //                         "hintText": ""
  //                     }
  //                 },
  //             ]
  //         }
  //     },
  // ],
  [ModuleKeywords.EntityType]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    {
      id: 2,
      type: 'dropdown',
      field: {
        name: 'Category',
        key: 'category',
        required: true,
        validators: [
          {
            key: 'dropdown',
            value: {
              type: 'dropdown',
              type2: null,
              value1: getEnumOptionsString(EntityTypeFieldDto.CategoryEnum),
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: false,
              hintText: '',
            },
          },
        ],
      },
    },
    // {
    //     id: 3,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Tree View Mode',
    //         key: 'treeViewMode',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": getEnumOptionsString(EntityTypeFieldDto.TreeViewModeEnum),
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 4,
      type: 'text',
      field: {
        name: 'Icon',
        key: 'icon',
        required: false,
        validators: null,
      },
    },
  ],
  [ModuleKeywords.Asset]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Owner',
        key: 'owner',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
        calculationFunction: (params) => {
          return { name: params.body?.owner, mode: 'ONE', principleType: 'RESPONSIBILITY' };
        },
      },
    },
    {
      id: 4,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'AST-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with AST-',
            },
          },
        ],
      },
    },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  [ModuleKeywords.Event]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Owner',
        key: 'owner',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
        calculationFunction: (params) => {
          return { name: params?.body?.owner, mode: 'ONE', principleType: 'RESPONSIBILITY' };
        },
      },
    },
    {
      id: 4,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'EVE-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with EVE-',
            },
          },
        ],
      },
    },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  [ModuleKeywords.Geography]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Owner',
        key: 'owner',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
        calculationFunction: (params) => {
          return { name: params?.body?.owner, mode: 'ONE', principleType: 'RESPONSIBILITY' };
        },
      },
    },
    {
      id: 4,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'GEO-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with GEO-',
            },
          },
        ],
      },
    },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  [ModuleKeywords.Objective]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Owner',
        key: 'owner',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
        calculationFunction: (params) => {
          return { name: params?.body?.owner, mode: 'ONE', principleType: 'RESPONSIBILITY' };
        },
      },
    },
    {
      id: 4,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'OBJ-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with OBJ-',
            },
          },
        ],
      },
    },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  [ModuleKeywords.Process]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Owner',
        key: 'owner',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
        calculationFunction: (params) => {
          return { name: params?.body?.owner, mode: 'ONE', principleType: 'RESPONSIBILITY' };
        },
      },
    },
    {
      id: 4,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'PRO-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with PRO-',
            },
          },
        ],
      },
    },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  [ModuleKeywords.Responsibility]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: true,
        validators: null,
      },
    },
    // {
    //     id: 2,
    //     type: 'dropdown',
    //     field: {
    //         name: 'Type',
    //         key: 'type',
    //         required: true,
    //         validators: [
    //             {
    //                 key: 'dropdown',
    //                 value: {
    //                     "type": "dropdown",
    //                     "type2": null,
    //                     "value1": "Develop,Fix,Done",
    //                     "value2": "",
    //                     "checked": false,
    //                     "remote": false,
    //                     "prohibitInput": false,
    //                     "hintShow": false,
    //                     "hintText": ""
    //                 }
    //             },
    //         ]
    //     }
    // },
    {
      id: 3,
      type: 'text_content',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'RES-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with RES-',
            },
          },
        ],
      },
    },
    // {
    //     id: 5,
    //     type: 'text',
    //     field: {
    //         name: 'People',
    //         key: 'people',
    //         required: false,
    //         validators: null,
    //     },
    //     isArray: true,
    //     isCommaDelimiter: true
    // },
    {
      id: 6,
      type: 'text_content',
      field: {
        name: 'Organization',
        key: 'organization',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'ORG-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'must start with ORG-',
            },
          },
        ],
      },
    },
  ],
  // [ModuleKeywords.Entity]: [
  //     {
  //         id: 1,
  //         type: 'text',
  //         field: {
  //             name: 'Name',
  //             key: 'name',
  //             required: true,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 2,
  //         type: 'dropdown',
  //         field: {
  //             name: 'Type',
  //             key: 'type',
  //             required: true,
  //             validators: [
  //                 {
  //                     key: 'dropdown',
  //                     value: {
  //                         "type": "dropdown",
  //                         "type2": null,
  //                         "value1": "Develop,Fix,Done",
  //                         "value2": "",
  //                         "checked": false,
  //                         "remote": false,
  //                         "prohibitInput": false,
  //                         "hintShow": false,
  //                         "hintText": ""
  //                     }
  //                 },
  //             ]
  //         }
  //     },
  //     {
  //         id: 3,
  //         type: 'dropdown',
  //         field: {
  //             name: 'Owner',
  //             key: 'owner',
  //             required: true,
  //             validators: [
  //                 {
  //                     key: 'dropdown',
  //                     value: {
  //                         "type": "dropdown",
  //                         "type2": null,
  //                         "value1": "Develop,Fix,Done",
  //                         "value2": "",
  //                         "checked": false,
  //                         "remote": false,
  //                         "prohibitInput": false,
  //                         "hintShow": false,
  //                         "hintText": ""
  //                     }
  //                 },
  //             ]
  //         }
  //     },
  //     {
  //         id: 4,
  //         type: 'dropdown',
  //         field: {
  //             name: 'Parent',
  //             key: 'parent',
  //             required: false,
  //             validators: [
  //                 {
  //                     key: 'dropdown',
  //                     value: {
  //                         "type": "dropdown",
  //                         "type2": null,
  //                         "value1": "Develop,Fix,Done",
  //                         "value2": "",
  //                         "checked": false,
  //                         "remote": false,
  //                         "prohibitInput": false,
  //                         "hintShow": false,
  //                         "hintText": ""
  //                     }
  //                 },
  //             ]
  //         },
  //     },
  // ],
  [ModuleKeywords.Question]: [],
  [ModuleKeywords.Questionnaire]: [],
  [ModuleKeywords.QuestionnaireValue]: [],
  [ModuleKeywords.MyQuestionnaireValue]: [],
  [ModuleKeywords.RiskItem]: [],
  [ModuleKeywords.RiskProject]: [],
  [ModuleKeywords.RiskStatement]: [],
  [ModuleKeywords.RiskStatementCategory]: [],
  // [ModuleKeywords.ImpactFactor]: [],
  [ModuleKeywords.RiskMethodology]: [],
  [ModuleKeywords.Control]: [],
  [ModuleKeywords.ControlGroup]: [],
  [ModuleKeywords.Policy]: [],
  // [ModuleKeywords.AuthorityDocument]: [
  //     {
  //         id: 1,
  //         type: 'text',
  //         field: {
  //             name: 'Name',
  //             key: 'name',
  //             required: true,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 2,
  //         type: 'text',
  //         field: {
  //             name: 'Alias Name',
  //             key: 'aliasName',
  //             required: false,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 3,
  //         type: 'text',
  //         field: {
  //             name: 'Version Number',
  //             key: 'versionNumber',
  //             required: true,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 4,
  //         type: 'date',
  //         field: {
  //             name: 'Valid From',
  //             key: 'validFrom',
  //             required: false,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 5,
  //         type: 'date',
  //         field: {
  //             name: 'Valid To',
  //             key: 'validTo',
  //             required: false,
  //             validators: null
  //         }
  //     },
  //     {
  //         id: 6,
  //         type: 'text',
  //         field: {
  //             name: 'Description',
  //             key: 'description',
  //             required: false,
  //             validators: null
  //         }
  //     },
  //     // {
  //     //     id: 2,
  //     //     type: 'dropdown',
  //     //     field: {
  //     //         name: 'Type',
  //     //         key: 'type',
  //     //         required: true,
  //     //         validators: [
  //     //             {
  //     //                 "type": "dropdown",
  //     //                 "type2": null,
  //     //                 "value1": "Develop,Fix,Done",
  //     //                 "value2": "",
  //     //                 "checked": false,
  //     //                 "remote": false,
  //     //                 "prohibitInput": false,
  //     //                 "hintShow": false,
  //     //                 "hintText": ""
  //     //             },
  //     //         ]
  //     //     }
  //     // },
  //     // {
  //     //     id: 2,
  //     //     type: 'text',
  //     //     field: {
  //     //         name: 'Code',
  //     //         key: 'code',
  //     //         required: true,
  //     //         validators: null
  //     //     }
  //     // }
  // ],
  [ModuleKeywords.CitationType]: [],
  [ModuleKeywords.Citation]: [
    {
      id: 1,
      type: 'text',
      field: {
        name: 'Title',
        key: 'title',
        required: true,
        validators: null,
      },
    },
    {
      id: 2,
      type: 'text',
      field: {
        name: 'Framework Reference',
        key: 'frameworkRef',
        required: true,
        validators: null,
      },
    },
    {
      id: 3,
      type: 'text',
      field: {
        name: 'Parent',
        key: 'parent',
        required: false,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'CIT-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'Must start with CIT-',
            },
          },
        ],
      },
    },
    {
      id: 4,
      type: 'text',
      field: {
        name: 'Citation Type',
        key: 'citationType',
        required: true,
        validators: [
          {
            key: 'text_content',
            value: {
              type: 'text_content',
              type2: 'include',
              value1: 'CTY-',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: true,
              hintText: 'Must start with CTY-',
            },
          },
        ],
      },
    },
    {
      id: 5,
      type: 'text',
      field: {
        name: 'Description',
        key: 'description',
        required: false,
        validators: null,
      },
    },
    {
      id: 6,
      type: 'text',
      field: {
        name: 'Name',
        key: 'name',
        required: false,
        validators: null,
        calculationFunction: (params) => {
          return params?.body?.frameworkRef + ' : ' + params?.body?.title;
        },
        hideInImporter: true,
      },
    },
    {
      id: 7,
      type: 'dropdown',
      field: {
        name: 'Mandatory',
        key: 'mandatory',
        required: true,
        validators: [
          {
            key: 'dropdown',
            value: {
              type: 'dropdown',
              type2: null,
              value1: 'TRUE,FALSE',
              value2: '',
              checked: false,
              remote: false,
              prohibitInput: false,
              hintShow: false,
              hintText: '',
            },
          },
        ],
        calculationFunction: (params) => {
          return (params?.body?.mandatory as string)?.toLowerCase() == 'true' ? true : false;
        },
      },
    },
  ],
  [ModuleKeywords.Task]: [],
  [ModuleKeywords.User]: [],
  [ModuleKeywords.Role]: [],
  [ModuleKeywords.Group]: [],
  [ModuleKeywords.Finding]: [],
  [ModuleKeywords.Acknowledgment]: [],
};
