<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="listBoxMode && multi ? (viewMode == 'view' ? 'edit' : viewMode) : viewMode"
  [data]="data || control?.value?.label || control?.value?.name || control?.value?.value || control?.value"
  [dataType]="dataType"
  [options]="showBadgeInView ? { badgeView: badgeView } : undefined"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
  [multiViewFields]="multi && !listBoxMode"
  [multiViewFieldStyleClass]="''"
  [multiViewFieldParentStyleClass]="'flex-row'"
>
  <div class="field" [class.mb-1]="control?.dirty && control?.hasError('required')">
    <span
      class="w-full flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
      [class.p-float-label]="!listBoxMode ? floatLabel : false"
    >
      <ng-container *ngIf="!listBoxMode; else multi ? pickListTemplate : listBoxTemplate">
        <ng-container *ngIf="multi; else elseTemplate">
          <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
          <p-multiSelect
            [inputId]="name"
            [showToggleAll]="true"
            [showClear]="showClear && !readOnly"
            [options]="items"
            [formControl]="fControl"
            [optionLabel]="optionLabel"
            [filter]="showFilter"
            [optionValue]="optionValue"
            [placeholder]="placeholder"
            class="w-full min-w-0"
            [maxSelectedLabels]="3"
            [lazy]="lazy"
            (onLazyLoad)="onLazyLoadCommand($event)"
            (onFilter)="onFilterCommand ? onFilterCommand($event) : null"
            [appendTo]="appendTo"
            [dataKey]="dataKey"
            [dropdownIcon]="loading ? 'pi pi-spin pi-spinner' : readOnly ? 'pi' : 'pi pi-chevron-down'"
            [group]="group"
            [resetFilterOnHide]="true"
            [readonly]="readOnly"
            (onChange)="onChangeObject($event)"
          >
            @if (selectedItemTemplate) {
              <ng-template let-value pTemplate="selectedItems">
                <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: value }"></ng-container
              ></ng-template>
            } @else if (badgeView) {
              <ng-template pTemplate="selectedItems" let-items let-removeChip="removeChip">
                <div class="flex flex-row gap-2 flex-wrap" style="min-height: 18.4px">
                  @for (option of items?.slice(0, 2); track option) {
                    <app-badge-item
                      [status]="option?.value"
                      [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                      [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                      [text]="
                        translationKeyPrefix +
                          '.' +
                          (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                          | translate
                      "
                    ></app-badge-item>
                  }
                  @if (items?.length > 2) {
                    {{ items?.length - 2 }} more...
                  }
                </div>
              </ng-template>
            }
            @if (customFilter) {
              <ng-template pTemplate="filter" let-options="options">
                <div class="flex p-3 w-full">
                  <div class="p-input-icon-right flex-1" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="loading; else elseTemplate">
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <i class="pi pi-search"></i>
                    </ng-template>

                    <input
                      appMaxCharacterValidator
                      type="text"
                      pInputText
                      [placeholder]="placeholder"
                      [(ngModel)]="filterValue"
                      name="dd-filter"
                      id="dd-filter"
                      class="w-full"
                      (ngModelChange)="this.filterValueUpdate.next($event)"
                      pAutoFocus
                      [autofocus]="true"
                    />
                  </div>
                </div>
              </ng-template>
            }
            <!-- <ng-template let-option pTemplate="selectedItem">
                    <ng-container *ngIf="badgeView; else normalLabel">
                        <app-badge-item [status]="optionLabel ? option[optionLabel] : option"></app-badge-item>
                    </ng-container>
                    <ng-template #normalLabel>
                        {{optionLabel ? option[optionLabel] : option}}
                    </ng-template>
                </ng-template> -->
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center gap-1">
                <i *ngIf="group?.icon" [class]="group?.icon"></i>
                <span>{{ group[optionLabel] }}</span>
              </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <ng-container *ngIf="itemTemplate; else noItemTemplate">
                <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"></ng-container>
              </ng-container>
              <ng-template #noItemTemplate>
                <div class="flex">
                  <ng-content select="[item-pre-template]"></ng-content>
                  <ng-container *ngIf="showStatusBadge && option?.[showStatusBadgeTextField]">
                    <!-- && option.code !== (optionLabel ? option[optionLabel] : option) -->
                    <app-badge-item
                      class="mr-2"
                      [styleClass]="'text-xs'"
                      [status]="option?.[showStatusBadgeStatusField]"
                      [text]="
                        translationKeyPrefix
                          ? (translationKeyPrefix + '.' + option?.[showStatusBadgeTextField]
                            | translate: option?.[showStatusBadgeTextField])
                          : option?.[showStatusBadgeTextField]
                      "
                    ></app-badge-item>
                  </ng-container>
                  <ng-container *ngIf="badgeView; else elseTemplate">
                    <app-badge-item
                      [status]="
                        optionValue
                          ? option[optionValue]
                          : dataKey
                            ? option[dataKey]
                            : optionLabel
                              ? option[optionLabel]
                              : option?.label || option[optionValue] || option[dataKey]
                      "
                      [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                      [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                      [text]="
                        translationKeyPrefix +
                          '.' +
                          (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                          | translate
                      "
                    ></app-badge-item>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <ng-container *ngIf="viewType == dataTypes.Icon; else elseTemplate1">
                      <span
                        ><i [class]="option[optionValue]"></i> {{ optionLabel ? option[optionLabel] : option }}</span
                      >
                    </ng-container>
                    <ng-template #elseTemplate1>
                      <span style="overflow: hidden; text-overflow: ellipsis; max-width: 36vw">{{
                        optionLabel ? option[optionLabel] : option
                      }}</span>
                    </ng-template>
                  </ng-template>
                  <ng-content select="[item-post-template]"></ng-content>
                </div>
              </ng-template>
            </ng-template>

            <ng-template pTemplate="loader" let-options="options">
              <div class="flex align-items-center py-2 px-3" style="height: 38px">
                <p-skeleton [width]="options?.even ? '60%' : '50%'" height="1rem"></p-skeleton>
              </div>
            </ng-template>
          </p-multiSelect>
          <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
        </ng-container>
        <ng-template #elseTemplate>
          <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
          <p-dropdown
            [inputId]="name"
            [showClear]="showClear && !readOnly"
            [autoDisplayFirst]="false"
            [options]="items"
            [formControl]="fControl"
            [optionLabel]="optionLabel"
            [optionValue]="optionValue"
            [optionDisabled]="optionDisabled"
            [placeholder]="label || placeholder"
            [filter]="showFilter"
            [lazy]="lazy"
            (onLazyLoad)="onLazyLoadCommand($event)"
            (onFilter)="onFilterCommand ? onFilterCommand($event) : null"
            [appendTo]="appendTo"
            [dataKey]="dataKey"
            [dropdownIcon]="loading ? 'pi pi-spin pi-spinner' : readOnly ? 'pi' : 'pi pi-chevron-down'"
            [virtualScroll]="virtualScroll"
            [virtualScrollItemSize]="virtualScrollItemSize"
            class="w-full min-w-0"
            [styleClass]="'w-full'"
            [group]="group"
            [editable]="editable"
            [resetFilterOnHide]="true"
            [readonly]="readOnly"
            (onChange)="onChangeObject($event)"
          >
            @if (selectedItemTemplate) {
              <ng-template let-value pTemplate="selectedItem">
                <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: value }"></ng-container
              ></ng-template>
            }
            <ng-container *ngIf="viewType == dataTypes.Icon">
              <ng-template let-value pTemplate="selectedItem">
                <div *ngIf="fControl?.value">
                  <span><i [class]="value.value"></i> {{ value.label }}</span>
                </div>
              </ng-template>
            </ng-container>
            @if (showScore) {
              <ng-template let-option pTemplate="selectedItem">
                <!-- <ng-container *ngIf="badgeView; else normalLabel"> -->
                <div class="max-w-0 w-full flex flex-row gap-2">
                  <app-badge-item
                    [status]="passingScore > option?.value ? 'false' : 'true'"
                    [text]="
                      (passingScore > option?.value ? 'general.messages.fail' : 'general.messages.pass') | translate
                    "
                  ></app-badge-item>
                  <span>
                    {{ option?.label }}
                  </span>
                </div>
              </ng-template>
            }
            <ng-container *ngIf="badgeView">
              <ng-template let-option pTemplate="selectedItem">
                <ng-container *ngIf="badgeView; else normalLabel">
                  <app-badge-item
                    [status]="option?.value"
                    [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                    [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                    [text]="
                      translationKeyPrefix +
                        '.' +
                        (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                        | translate
                    "
                  ></app-badge-item>
                </ng-container>
                <ng-template #normalLabel>
                  {{ option?.label }}
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="customFilter">
              <ng-template pTemplate="filter" let-options="options">
                <div class="flex p-3 w-full">
                  <div class="p-input-icon-right flex-1" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="loading; else elseTemplate">
                      <i class="pi pi-spin pi-spinner"></i>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <i class="pi pi-search"></i>
                    </ng-template>

                    <input
                      appMaxCharacterValidator
                      type="text"
                      pInputText
                      placeholder="Search"
                      [(ngModel)]="filterValue"
                      name="dd-filter"
                      id="dd-filter"
                      class="w-full"
                      (ngModelChange)="this.filterValueUpdate.next($event)"
                      pAutoFocus
                      [autofocus]="true"
                    />
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center gap-1">
                <i *ngIf="group?.icon" [class]="group?.icon"></i>
                <span>{{ group[optionLabel] }}</span>
              </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <ng-container *ngIf="itemTemplate; else noItemTemplate">
                <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"></ng-container>
              </ng-container>
              <ng-template #noItemTemplate>
                <div class="flex">
                  <ng-content select="[item-pre-template]"></ng-content>
                  <ng-container *ngIf="showStatusBadge && option?.[showStatusBadgeTextField]">
                    <!-- && option.code !== (optionLabel ? option[optionLabel] : option) -->
                    <app-badge-item
                      class="mr-2"
                      [styleClass]="'text-xs'"
                      [status]="option?.[showStatusBadgeStatusField]"
                      [text]="
                        translationKeyPrefix + '.' + option?.[showStatusBadgeTextField]
                          | translate: option?.[showStatusBadgeTextField]
                      "
                    ></app-badge-item>
                  </ng-container>
                  <ng-container *ngIf="badgeView; else elseTemplate">
                    <app-badge-item
                      [status]="
                        optionValue
                          ? option[optionValue]
                          : dataKey
                            ? option[dataKey]
                            : optionLabel
                              ? option[optionLabel]
                              : option?.label || option[optionValue] || option[dataKey]
                      "
                      [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                      [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                      [text]="
                        translationKeyPrefix +
                          '.' +
                          (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                          | translate
                      "
                    ></app-badge-item>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <ng-container *ngIf="viewType == dataTypes.Icon; else elseTemplate1">
                      <span
                        ><i [class]="option[optionValue]"></i> {{ optionLabel ? option[optionLabel] : option }}</span
                      >
                    </ng-container>
                    <ng-template #elseTemplate1>
                      @if (showScore) {
                        <app-badge-item
                          [status]="passingScore > option?.value ? 'false' : 'true'"
                          [text]="
                            (passingScore > option?.value ? 'general.messages.fail' : 'general.messages.pass')
                              | translate
                          "
                          class="mr-2"
                        ></app-badge-item>
                      }
                      <span style="overflow: hidden; text-overflow: ellipsis; max-width: 36vw">{{
                        optionLabel ? option[optionLabel] : option
                      }}</span>
                    </ng-template>
                  </ng-template>
                  <ng-content select="[item-post-template]"></ng-content>
                </div>
              </ng-template>
            </ng-template>

            <ng-template pTemplate="loader" let-options="options">
              <div class="flex align-items-center py-2 px-3" style="height: 38px">
                <p-skeleton [width]="options?.even ? '60%' : '50%'" height="1rem"></p-skeleton>
              </div>
            </ng-template>
          </p-dropdown>
          <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
        </ng-template>
      </ng-container>
      <ng-template #listBoxTemplate>
        <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
        <!-- <p *ngIf="label && !instanceInFormRepeater" class="mb-2" >{{label}}<span *ngIf="isControlRequired" class="text-red-500"> *</span></p> -->
        <p-listbox
          [options]="items"
          [formControl]="fControl"
          [optionLabel]="optionLabel"
          [optionValue]="optionValue"
          [filter]="showFilter"
          (onLazyLoad)="onLazyLoadCommand($event)"
          (onFilter)="onFilterCommand ? onFilterCommand($event) : null"
          [dataKey]="dataKey"
          [multiple]="multi"
          class="w-full"
          [styleClass]="'w-full custom-listbox'"
          [group]="group"
          [metaKeySelection]="false"
          (onChange)="onChangeObject($event)"
        >
          <ng-container *ngIf="viewType == dataTypes.Icon">
            <ng-template let-value pTemplate="selectedItem">
              <div *ngIf="fControl?.value">
                <span><i [class]="value.value"></i> {{ value.label }}</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="badgeView">
            <ng-template let-option pTemplate="selectedItem">
              <ng-container *ngIf="badgeView; else normalLabel">
                <app-badge-item
                  [status]="option?.value"
                  [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                  [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                  [text]="
                    translationKeyPrefix +
                      '.' +
                      (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                      | translate
                  "
                ></app-badge-item>
              </ng-container>
              <ng-template #normalLabel>
                {{ option?.label }}
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="customFilter">
            <ng-template pTemplate="filter" let-options="options">
              <div class="flex flex-row w-full">
                <div class="p-input-icon-right flex-1" (click)="$event.stopPropagation()">
                  <ng-container *ngIf="loading; else elseTemplate">
                    <i class="pi pi-spin pi-spinner"></i>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <i class="pi pi-search"></i>
                  </ng-template>

                  <input
                    appMaxCharacterValidator
                    type="text"
                    pInputText
                    placeholder="Search"
                    [(ngModel)]="filterValue"
                    name="dd-filter"
                    id="dd-filter"
                    class="w-full"
                    [class.border-noround]="useTypesSelector"
                    [class.border-round-left]="useTypesSelector"
                    (ngModelChange)="this.filterValueUpdate.next($event)"
                    pAutoFocus
                    [autofocus]="true"
                  />
                </div>
                <div *ngIf="useTypesSelector && listOfTypes?.length > 1" class="flex">
                  <app-button
                    pTooltip="Types"
                    tooltipPosition="top"
                    (onClickAction)="typesOp.toggle($event)"
                    [action]="{
                      id: 2,
                      label: '',
                      icon: 'pi pi-table',
                      iconPos: 'left',
                      buttonStyle: 'raised',
                      color: 'primary',
                      buttonClass: 'border-noround border-round-right',
                    }"
                  ></app-button>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center gap-1">
              <i *ngIf="group?.icon" [class]="group?.icon"></i>
              <span>{{ group[optionLabel] }}</span>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <ng-container *ngIf="itemTemplate; else noItemTemplate">
              <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"></ng-container>
            </ng-container>
            <ng-template #noItemTemplate>
              <div class="flex line-clamp-1-ellipsis">
                <ng-content select="[item-pre-template]"></ng-content>
                <ng-container *ngIf="showStatusBadge && option?.[showStatusBadgeTextField]">
                  <!-- && option.code !== (optionLabel ? option[optionLabel] : option) -->
                  <app-badge-item
                    class="mr-2"
                    [styleClass]="'text-xs'"
                    [status]="option?.[showStatusBadgeStatusField]"
                    [text]="
                      translationKeyPrefix + '.' + option?.[showStatusBadgeTextField]
                        | translate: option?.[showStatusBadgeTextField]
                    "
                  ></app-badge-item>
                </ng-container>
                <ng-container *ngIf="badgeView; else elseTemplate">
                  <app-badge-item
                    [status]="
                      optionValue
                        ? option[optionValue]
                        : dataKey
                          ? option[dataKey]
                          : optionLabel
                            ? option[optionLabel]
                            : option?.label || option[optionValue] || option[dataKey]
                    "
                    [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                    [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                    [text]="
                      translationKeyPrefix +
                        '.' +
                        (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                        | translate
                    "
                  ></app-badge-item>
                </ng-container>
                <ng-template #elseTemplate>
                  <ng-container *ngIf="viewType == dataTypes.Icon; else elseTemplate1">
                    <span><i [class]="option[optionValue]"></i> {{ optionLabel ? option[optionLabel] : option }}</span>
                  </ng-container>
                  <ng-template #elseTemplate1>
                    <span class="line-clamp-1-ellipsis">{{ optionLabel ? option[optionLabel] : option }}</span>
                  </ng-template>
                </ng-template>
                <ng-content select="[item-post-template]"></ng-content>
              </div>
            </ng-template>
          </ng-template>

          <!-- <ng-template pTemplate="loader" let-options="options">
                    <div class="flex align-items-center py-2 px-3" style="height: 38px">
                        <p-skeleton [width]="options?.even ? '60%' : '50%'" height="1rem"></p-skeleton>
                    </div>
                </ng-template> -->
        </p-listbox>
      </ng-template>
      <ng-template #pickListTemplate>
        <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>

        <p-pickList
          #pickList
          [source]="sourceElements"
          [target]="targetElements"
          sourceHeader="Available Items"
          targetHeader="Selected Items"
          [dragdrop]="true"
          [responsive]="true"
          [sourceStyle]="{ height: '30rem' }"
          [targetStyle]="{ height: '30rem' }"
          [filterBy]="optionLabel || 'label'"
          sourceFilterPlaceholder="Search for more items"
          targetFilterPlaceholder="Search items"
          [showSourceControls]="true"
          [showSourceFilter]="true"
          [showTargetControls]="true"
          [showTargetFilter]="viewMode == 'view'"
          (onMoveAllToSource)="onPickListChange()"
          (onMoveAllToTarget)="onPickListChange()"
          (onMoveToSource)="onPickListChange()"
          (onMoveToTarget)="onPickListChange()"
          (onTargetReorder)="onPickListChange()"
          class="w-full app-picklist"
          [class.pick-list-view-mode]="viewMode == 'view' || fControl?.disabled"
          [disabled]="viewMode == 'view' || fControl?.disabled"
          (keydown)="onPickListKeyDown($event)"
          [metaKeySelection]="true"
        >
          <ng-template pTemplate="sourceFilter" let-options="options">
            <div class="flex flex-row w-full">
              <div class="flex flex-1">
                <div class="p-picklist-filter w-full">
                  <input
                    appMaxCharacterValidator
                    [(ngModel)]="filterValue"
                    [ngModelOptions]="{ standalone: true }"
                    type="text"
                    (ngModelChange)="this.filterValueUpdate.next($event)"
                    role="textbox"
                    class="p-picklist-filter-input p-inputtext p-component"
                    [class.border-noround]="useTypesSelector"
                    [class.border-round-left]="useTypesSelector"
                    placeholder="Search for more records"
                  />
                  <ng-container *ngIf="filterValue; else elseTemplate">
                    <span
                      [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-times')"
                      (click)="options.reset(); filterValue = ''; this.filterValueUpdate.next('')"
                    ></span>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <span
                      [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"
                    ></span>
                  </ng-template>
                </div>
              </div>
              <div *ngIf="useTypesSelector && listOfTypes?.length > 1" class="flex">
                <app-button
                  pTooltip="Types"
                  tooltipPosition="top"
                  (onClickAction)="typesOp.toggle($event)"
                  [action]="{
                    id: 2,
                    label: '',
                    icon: 'pi pi-table',
                    iconPos: 'left',
                    buttonStyle: 'raised',
                    color: 'primary',
                    buttonClass: 'border-noround border-round-right',
                  }"
                ></app-button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="targetFilter" let-options="options">
            <div class="flex flex-row w-full">
              <div class="flex flex-1">
                <div class="p-picklist-filter w-full">
                  <input
                    appMaxCharacterValidator
                    [(ngModel)]="filterValue"
                    type="text"
                    (keyup)="options.filter($any($event.target).value)"
                    role="textbox"
                    class="p-picklist-filter-input p-inputtext p-component border-noround border-round-left"
                    placeholder="Search by name"
                  />
                  <ng-container *ngIf="filterValue; else elseTemplate">
                    <span class="p-picklist-filter-icon pi pi-times" (click)="options.reset(); filterValue = ''"></span>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <span
                      [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"
                    ></span>
                  </ng-template>
                </div>
              </div>
              <!-- <div class="flex">
                          <p-button icon="pi pi-filter" (click)="onCreateReq()" label=""
                              [styleClass]="'p-button border-noround ' + (showCategoriesSelector ? '' : ' border-round-right')"></p-button>
                      </div> -->
              <!-- <div *ngIf="showCategoriesSelector" class="flex">
                          <app-button
                          pTooltip="Categories"
                          tooltipPosition="top"
                          (onClickAction)="op.toggle($event)"
                          [action]="{
                              id: 2,
                              label: '',
                              icon: 'pi pi-table',
                              iconPos: 'left',
                              buttonStyle: 'raised',
                              color:'primary',
                              buttonClass:'border-noround border-round-right'
                          }"
                      ></app-button>
                      </div> -->
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <ng-container *ngIf="preItemTemplate">
                <ng-container *ngTemplateOutlet="preItemTemplate; context: { $implicit: option }"></ng-container>
              </ng-container>
              <ng-container *ngIf="itemTemplate; else noItemTemplate">
                <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option }"></ng-container>
              </ng-container>
              <ng-template #noItemTemplate>
                <!-- <ng-container *ngIf="showStatusBadge && option?.code" >
                            <app-badge-item class="mr-2" [styleClass]="'text-xs'" [status]="option?.recordStatus || 'cyan'" [text]="option.code"></app-badge-item>
                        </ng-container> -->
                <ng-container *ngIf="badgeView; else elseTemplate">
                  <app-badge-item
                    [status]="
                      optionValue
                        ? option[optionValue]
                        : dataKey
                          ? option[dataKey]
                          : optionLabel
                            ? option[optionLabel]
                            : option?.label || option[optionValue] || option[dataKey]
                    "
                    [customColor]="useCustomBadgeMode ? option[customBadgeColorField] : null"
                    [customIcon]="useCustomBadgeMode ? option[customBadgeIconField] : null"
                    [text]="
                      translationKeyPrefix +
                        '.' +
                        (optionLabel ? option[optionLabel] : option?.label || option[optionValue] || option[dataKey])
                        | translate
                    "
                  ></app-badge-item>
                </ng-container>
                <ng-template #elseTemplate>
                  <ng-container *ngIf="viewType == dataTypes.Icon; else elseTemplate1">
                    <span><i [class]="option[optionValue]"></i> {{ optionLabel ? option[optionLabel] : option }}</span>
                  </ng-container>
                  <ng-template #elseTemplate1>
                    <app-code-navigation
                      style="width: calc(100% - 24px)"
                      [sendRequest]="
                        true || (showStatusBadge && (optionLabel ? (option[optionLabel] ? false : true) : false))
                      "
                      [staticData]="option?.code && option?.label && option?.recordStatus ? option : null"
                      [badgeMode]="true"
                      [data]="option?.code || option?.[optionLabel] || null"
                    ></app-code-navigation>
                    <!-- <span style="overflow: hidden; text-overflow: ellipsis; max-width:25vw;">{{optionLabel ? option[optionLabel] : option}}</span> -->
                  </ng-template>
                </ng-template>
              </ng-template>
              <ng-container *ngIf="postItemTemplate">
                <ng-container *ngTemplateOutlet="postItemTemplate; context: { $implicit: option }"></ng-container>
              </ng-container>
              @if (option?._pickListTargetItem && !(viewMode == 'view' || fControl?.disabled)) {
                <span
                  class="pi pi-times cursor-pointer right-10"
                  (click)="removeItem(option, $event)"
                  style="position: absolute; top: 50%; margin-top: -0.5rem; z-index: 10"
                ></span>
              }
            </div>
          </ng-template>
        </p-pickList>
      </ng-template>
    </span>
    <ng-container *ngIf="control?.dirty">
      <small class="p-error p-invalid" *ngIf="control?.hasError('required')">{{ label }} {{ 'is Required' }}</small>
      <small class="p-error p-invalid" *ngIf="customError">{{ customErrorLabel }}</small>
    </ng-container>
    <small class="text-500 block" *ngIf="helpText">{{ helpText }}</small>
  </div>
</app-input-view-switch>
<!-- {{targetElements | json}} -->
<p-overlayPanel #typesOp [showCloseIcon]="true">
  <ng-template pTemplate>
    <p-listbox
      [options]="listOfTypes"
      [checkbox]="true"
      [filter]="true"
      [multiple]="true"
      [formControl]="typesControl"
      optionLabel="label"
      [optionValue]="'value'"
      [style]="{ width: '15rem' }"
    >
      <ng-template let-item pTemplate="item">
        <app-badge-item
          [status]="item.value"
          [text]="translationKeyPrefix + '.' + item.label | translate: item.label"
        ></app-badge-item>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
<ng-template #labelTemplate>
  <label
    [for]="name || label"
    class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
    *ngIf="label && !instanceInFormRepeater"
    >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
    @if (resetCheckTemplate) {
      <br />
      <ng-container *ngTemplateOutlet="resetCheckTemplate"></ng-container>
    } @else if (resetFieldCheckVisable) {
      <br />
      <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
    }
    @if (showScore && passingScore > fControl?.value) {
      <app-badge-item
        [status]="passingScore > fControl?.value ? 'false' : 'true'"
        [text]="(passingScore > fControl?.value ? 'general.messages.fail' : 'general.messages.pass') | translate"
        class="mr-2 mt-1"
      ></app-badge-item>
    }
  </label>
</ng-template>
