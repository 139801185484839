import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { camelCase, kebabCase, lowerCase, startCase } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { DataTypeEnum, IColumn, IViewMode, PermissionActions, TypesWithName } from '../view';
import { isValidCode } from './code-based-functions';

export class GeneralFunctions { }
export function isNullObj(obj) {
  return obj == null || obj == undefined || obj == '' || obj.length == 0;
}
export function isStrictlyUndefined(obj: any): boolean {
  return obj === undefined && typeof obj === 'undefined';
}
export function isStrictlyNotNull(obj: any): boolean {
  return obj === null || obj === undefined;
}
export function getDataKeyValueFormat(formCurrentData) {
  let createItems: { key: string; value: any }[] = [];
  if (!isNullObj(formCurrentData)) {
    Object.keys(formCurrentData).forEach((key) => {
      if (!isNullObj(formCurrentData[key])) createItems.push({ key: key, value: formCurrentData[key] });
    });
  }
  return { createItems: createItems };
}
export function getChangedDataKeyValueFormat(formCurrentData, data, valueChangeChecker = checkValueChanged) {
  let valueChanged = false;
  let changedFields: { key: string; value: any }[] = [];
  Object.keys(formCurrentData).forEach((key) => {
    if (
      valueChangeChecker(formCurrentData, data, key)
      // !this.data ||
      // &&
      // formCurrentData[key] != null &&
      // formCurrentData[key] != ""
    ) {
      valueChanged = true;
      changedFields.push({ key: key, value: formCurrentData[key] !== undefined ? formCurrentData[key] : null });
    }
  });

  return { hasChanges: valueChanged, updateItems: changedFields };
}
function checkValueChanged(formCurrentData, data, key) {
  return data && formCurrentData?.[key] != data?.[key];
}
export function getFormData(object) {
  return Object.keys(object).reduce((formData, key) => {
    if (typeof object[key] === 'boolean') formData.append(key, object[key] ? '1' : '0');
    else if (Array.isArray(object[key])) {
      object[key].forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else if (object[key] != null) formData.append(key, object[key]);
    return formData;
  }, new FormData());
}
export function buildFormData(formData, data, parentKey?) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data;
    if (typeof value === 'boolean') formData.append(parentKey, value ? '1' : '0');
    else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${parentKey}[${index}]`, item);
      });
    } else if (value != null) formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}
export function getIdArray(dataArray: { id?: any }[]) {
  if (dataArray) return dataArray?.map((x) => x.id);
  else return [];
}

export function parseToFixedNumberFn(value: any, fixedFloatingPoints: number = 3): string {
  if (typeof value === 'number') {
    return value.toFixed(fixedFloatingPoints);
  }
  if (typeof value === 'string') return parseFloat(value).toFixed(fixedFloatingPoints);
  else return '';
}

export function replaceCamelCaseRoute(str: string) {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter;
    })
    .join('');
}
export function getViewMode(url: string) {
  return url.includes('/view')
    ? IViewMode.view
    : url.includes('/create')
      ? IViewMode.create
      : url.includes('/list')
        ? IViewMode.list
        : url.includes('/tabs')
          ? IViewMode.tabs
          : IViewMode.edit;
}
export function hexToRgba(hex: string, alpha: number | string) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
export function cleanObject(obj: any): any {
  if (obj === null || obj === undefined) {
    return null;
  }

  if (Array.isArray(obj)) {
    return obj.filter((value) => value !== null && value !== undefined).map((value) => cleanObject(value));
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  return Object.entries(obj)
    .filter(([key, value]) => value !== null && value !== undefined)
    .reduce((acc, [key, value]) => {
      acc[key] = cleanObject(value);
      return acc;
    }, {});
}
export function filterOutNonExistingParents(codes: { code: string; parent: string }[]) {
  // Step 1: Extract all unique parentCodes
  let uniqueParentCodes = new Set(codes.map((item) => item.parent));

  // Step 2: Filter out null and undefined
  uniqueParentCodes.delete(null);
  uniqueParentCodes.delete(undefined);

  // Step 3: Filter out parentCodes that don't exist in the array
  let filteredParentCodes = Array.from(uniqueParentCodes).filter(
    (parentCode) => !codes.some((item) => item.code === parentCode)
  );

  // Step 4: Replace non-existent parentCodes with null in the original array
  codes = codes.map((item) => ({
    ...item,
    parent: filteredParentCodes.includes(item.parent) ? null : item.parent,
  }));

  return codes;
}
export function generateCodeWithTimeStamp(baseCode: string) {
  return `${baseCode}-${new Date().getTime()}`;
}

export function cleanNotAlphaNumericUnderScore(inputString) {
  if (!inputString) return '';

  // Remove all non-alphanumeric characters (except spaces)
  const cleanedString = inputString.replace(/[^a-zA-Z0-9\s]/g, '');

  // Replace spaces with underscores
  const finalString = cleanedString.replace(/\s+/g, '_');

  return finalString.toLowerCase();
}

export function generateAlphaNumericUnderScoreCodeWithTimeStamp(baseName: string) {
  baseName = cleanNotAlphaNumericUnderScore(baseName);
  return `${baseName}-${new Date().getTime()}`;
}

export function shortenString(val: string, length: any = 15): string {
  return val?.length > length + 3 ? `${val?.substring(0, length)}...` : val;
}
export function cloneFormGroup(formGroup: FormGroup): FormGroup {
  // Clone the form group
  const clonedForm = new FormGroup(formGroup.controls);

  // Get a deep copy of the form group's value
  const formValue = formGroup.getRawValue();

  // Set the cloned form group's value to the deep copy of the original form group's value
  clonedForm.setValue(formValue);
  return clonedForm;
}
export function markFormGroupTouched(formGroup: FormGroup) {
  // Recursively mark all form controls as touched
  Object?.values(formGroup?.controls)?.forEach((control) => {
    control?.markAsTouched();
    control?.markAsDirty();

    if (control instanceof FormGroup) {
      markFormGroupTouched(control);
    }
  });
}
export function markFormGroupUntouched(formGroup: FormGroup) {
  // Recursively remove mark all form controls as touched
  Object?.values(formGroup?.controls)?.forEach((control) => {
    control?.markAsUntouched();
    control?.markAsPristine();

    if (control instanceof FormGroup) {
      markFormGroupUntouched(control);
    }
  });
}
export function humanizeFileSize(bytes: number): string {
  if (bytes === 0 || isNullObj(bytes)) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}
/**
 * change string into snake_case string
 *
 * @param {string} str string to convert
 */
export function toSnakeCase(str: string) {
  return str?.length > 0
    ? str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('_')
    : str;
}
/**
 * change string into kebab-case string
 *
 * @param {string} str string to convert
 */
export function toKebabCase(str: string) {
  return kebabCase(str);
  // return str?.length > 0 ? str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-') : str;
}
/**
 * change string into kebab-case route string
 *
 * @param {string} str string to convert
 */
export function toRouteCase(str: string) {
  return str
    .split('/')
    .map((item) => kebabCase(item))
    .join('/');
  // return str?.length > 0 ? str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-') : str;
}

/**
 * change string into camelCase locale string
 *
 * @param {string} str string to convert
 */
export function routeToLocaleCase(str: string) {
  if (isNullObj(str)) return '';
  return str
    .split('/')
    .map((item) => camelCase(item))
    .join('.');
  // return str?.length > 0 ? str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-') : str;
}

/**
 * change string into camelCase string
 *
 * @param {string} str string to convert
 */
export function toCamelCase(str: string) {
  return str?.length > 0 ? str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) : str;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * change string into PascalCase string fails if string is already Pascal
 *
 * @param {string} str string to convert
 */
export function toPascalCase(str: string) {
  return str.length > 1 ? str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substring(1).toLowerCase()) : str;
}

export function humanizeString(value: string): string {
  let retValue = '';
  if (value === '' || !value) {
    return retValue;
  }
  retValue = value.replace(/([^A-Z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][^A-Z])/g, '$1 $2');
  retValue = value[0].toUpperCase() + value.slice(1);
  return retValue;
}
export function humanizeCasedString(value: string): string {
  let retValue = '';
  if (value === '' || !value) {
    return retValue;
  }
  if (value?.length === 3 && value?.toUpperCase() == value) return value;
  if (isValidCode(value)) return value;
  retValue = startCase(lowerCase(value));
  // Split the input string into words
  const words = retValue?.split(/\s+/);
  // Filter out consecutive duplicate words
  const filteredWords = words?.filter((word, index) => {
    return index === 0 || word !== words?.[index - 1];
  });
  // Join the filtered words back into a string
  retValue = filteredWords?.join(' ');
  return retValue;
  // // Convert snake_case and kebab-case to Pascal case
  // if (value.includes('_')) {
  //   retValue = value.replace(/_/g, ' ');
  // } else if (value.includes('-')) {
  //   retValue = value.replace(/-/g, ' ');
  // } else {
  //   retValue = value;
  // }

  // // Insert spaces before capital letters (except the first one)
  // retValue = retValue.replace(/([^A-Z\s])([A-Z])/g, '$1 $2');

  // // Capitalize the first letter and convert the rest to lowercase
  // // retValue = retValue.charAt(0).toUpperCase() + retValue.slice(1).toLowerCase();

  // // Handle camelCase by replacing capital letters with spaces
  // retValue = retValue.replace(/([a-z])([A-Z])/g, '$1 $2');

  // return retValue;
}
export function makePlural(word: string): string {
  const exceptions: { [key: string]: string } = {
    // Add exceptions for irregular plurals
    // For example: 'child' -> 'children'
    child: 'children',
    // Add more exceptions as needed
  };

  // Check for irregular plurals
  if (exceptions[word]) {
    return exceptions[word];
  }

  // Check for common cases ending in 'y'
  if (word.endsWith('y')) {
    // Replace 'y' with 'ies'
    return word.slice(0, -1) + 'ies';
  }
  // Check for common cases ending in 's'
  if (word.endsWith('s')) {
    // Add 'es'
    return word + 'es';
  }

  // Add more rules as needed for other common cases

  // Default case: Add 's' to the end
  return word + 's';
}
export function isDocumentCode(inputString: string, regex = /(DOC-\d+)(.*)/) {
  if (isNullObj(inputString) || typeof inputString !== 'string') return false;
  const match = inputString.match(regex);
  if (match) return true;
  else return false;
}
export function extractItemCode(inputString: string, regex = /(RES-\d+)(.*)/) {
  if (isNullObj(inputString) || typeof inputString !== 'string') return { code: null, label: null };
  // Regular expression to match the pattern "RES-[NUMBERS]"
  // const regex = /(RES-\d+)(.*)/;

  // Find the first match in the input string
  const match = inputString.match(regex);

  // Extract the code and label if a match is found
  if (match) {
    return { code: match[1], label: match?.[2]?.substring(1) };
  } else {
    return { code: inputString, label: inputString };
  }
}
export function removeStartingCode(inputString: string, regex = /(RES-\d+)(.*)/) {
  if (isNullObj(inputString)) return '';
  // Regular expression to match the pattern "RES-[NUMBERS]"
  // const regex = /(RES-\d+)(.*)/;

  // Find the first match in the input string
  const match = inputString.match(regex);

  // Extract the code and label if a match is found
  if (match) {
    return match?.[2]?.substring(1);
  } else {
    return inputString;
  }
}
export function getProp(obj: any, prop: string) {
  if (typeof obj !== 'object') return '';
  if (typeof prop !== 'string') return '';
  if (isNullObj(obj)) return '';
  // Replace [] notation with dot notation
  prop = prop.replace(/\[["'`](.*)["'`]\]/g, '.$1');

  return prop.split('.').reduce(function (prev, curr) {
    return prev ? prev[curr] : '';
  }, obj || self);
}

export function getElementValue(key: string, data: any) {
  let value = data[key];
  value = getProp(data, key);
  return value;
}

export function parseColData(data: any, col: IColumn) {
  let res = null;
  if (data == '' || data == null || data == undefined) return '';

  switch (col.dataType) {
    case DataTypeEnum.Date:
      res = formatDate(data, 'yyyy-MM-dd HH:mm', 'en');
      break;
    case DataTypeEnum.DateLong:
      res = formatDate(data, 'yyyy-MM-dd HH:mm', 'en');
      break;
    case DataTypeEnum.DateShort:
      res = formatDate(data, 'yyyy-MM-dd', 'en');
      break;
    case DataTypeEnum.Time:
      res = formatDate(data, 'HH:mm', 'en');
      break;
    case DataTypeEnum.LongText:
      res = data.replace(/<[^>]+>/g, '');
      break;
    case DataTypeEnum.DynamicComponent:
      res = prettyPrintMap(data?.value ?? data);
      break;
    case DataTypeEnum.ResponsibilityListView:
      res = parseResponsibilityListView(data);
      break;
    case DataTypeEnum.ProgressBar:
      res = parseProgressBar(data);
      break;
    case DataTypeEnum.ProgressBarStacked:
      res = parseProgressBar(data);
      break;
    default:
      res = data;
      break;
  }

  if (Array.isArray(res) && res.length > 0) {
    if (typeof res[0] === 'string' || res[0] instanceof String) {
      res = res.join(', ');
    } else if (res?.[0]?.code !== undefined) {
      res = res.map((x) => x?.code).join(', ');
    } else if (res?.[0]?.user !== undefined) {
      res = res.map((x) => x?.user).join(', ');
    } else {
      res = res.map((x) => JSON.stringify(x)).join(', ');
    }
  }

  return res;
}

export function parseResponsibilityListView(data) {
  try {
    if (typeof data === 'string' || data instanceof String) {
      return data;
    } else if (Array.isArray(data)) {
      return data.map((x) => x.name).join(',');
    } else {
      return data.name;
    }
  } catch (e) {
    return data;
  }
}

export function parseProgressBar(data) {
  try {
    if (Array.isArray(data)) {
      return data.map((x) => x.tooltipLabel).join(',');
    } else {
      return data.tooltipLabel;
    }
  } catch (e) {
    return data;
  }
}

export function prettyPrintMap(map: Record<string, number | null> | {}): string {
  let replaceNullWith = 'Not Responding';
  const nonZeroEntries: string[] = [];
  for (const key in map) {
    if (map[key] !== null && map[key] !== 0 && key.toLowerCase() == 'nulls') {
      nonZeroEntries.push(`${replaceNullWith}: ${map[key]}`);
    } else if (map[key] !== null && map[key] !== 0) {
      nonZeroEntries.push(`${key.toLowerCase()}: ${map[key]}`);
    }
  }

  return nonZeroEntries.length > 0 ? nonZeroEntries.join(', ') : '0';
}

export function isRecord(a: Record<string, number | null> | string[]): a is Record<string, number | null> {
  // Check if a is an object and has a property called "key"
  return typeof a === 'object' && 'key' in a;
}

export function getOptionsFromStringArray(options: string[]) {
  if (isNullObj(options)) return [];
  return options.map((option) => {
    return { label: humanizeCasedString(option), value: option };
  });
}
export function getEnumOptions(enumClass: any) {
  const enumKeys = Object.keys(enumClass);
  let options: { label: any; value: any }[] = [];
  enumKeys.forEach((key) => {
    options.push({ label: enumClass[key], value: enumClass[key] });
  });
  return options;
  /*
    // ✅ For Numeric Enums
    enum Sizes2 {
      Small,
      Medium,
      Large,
    }

    const keys2 = Object.keys(Sizes2).filter((v) => isNaN(Number(v)));


    keys2.forEach((key, index) => {
      // 👇️ Small, Medium, Large

    });

    const values2 = Object.values(Sizes2).filter((v) => !isNaN(Number(v)));


    values2.forEach((value) => {
      // 👇️ 0, 1, 2

    });

    // ✅ For STRING Enums
    enum Sizes {
      Small = 'S',
      Medium = 'M',
      Large = 'L',
    }

    const keys1 = Object.keys(Sizes);


    keys1.forEach((key, index) => {
      // 👇️ Small, Medium, Large

    });

    const values1 = Object.values(Sizes);

    values1.forEach((value, index) => {
      // 👇️ S, M, L

    });
    */
}
export function getBlocks(type) {
  let extraBlocks = [];
  if (type == 'IP6') extraBlocks = [5, 6, 7, 8];
  else if (type == 'IPV4_MASK') extraBlocks = [5];
  else if (type == 'MAC') extraBlocks = [5, 6];

  return [1, 2, 3, 4, ...extraBlocks].map((x) => {
    return { label: 'block ' + (x), value: x };
  });
}
export function getEnumOptionsString(enumClass: any) {
  const enumKeys = Object.keys(enumClass);
  let options: { label: any; value: any }[] = [];
  enumKeys.forEach((key) => {
    options.push({ label: key, value: enumClass[key] });
  });
  return options.map((x) => x.value).join(',');
}
export function lightenColor(hex: string, percent: number): string {
  if (isNullObj(hex)) return null;
  // Ensure that hex is in the correct format
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    // Convert to decimal and change luminosity
    const num = parseInt(hex, 16);
    const r = (num >> 16) + Math.round(((255 - (num >> 16)) * percent) / 100);
    const g = ((num >> 8) & 0x00ff) + Math.round(((255 - ((num >> 8) & 0x00ff)) * percent) / 100);
    const b = (num & 0x0000ff) + Math.round(((255 - (num & 0x0000ff)) * percent) / 100);

    const newColor = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

    return newColor;
  } else {
    const stringPercent = mapNumberToStringValue(100 - percent);
    const newColor = '--' + hex + '-' + stringPercent;
    const documentStyle = getComputedStyle(document.documentElement);
    const resultColor = documentStyle.getPropertyValue(newColor);
    return resultColor;
  }
}
function mapNumberToStringValue(number: number): string {
  // if (number < 0 || number > 100) {
  //   throw new Error('Input number must be between 0 and 100.');
  // }

  if (number >= 0 && number < 10) {
    return '50';
  } else if (number >= 10 && number < 20) {
    return '100';
  } else if (number >= 20 && number < 30) {
    return '200';
  } else if (number >= 30 && number < 40) {
    return '300';
  } else if (number >= 40 && number < 50) {
    return '400';
  } else if (number >= 50 && number < 60) {
    return '500';
  } else if (number >= 60 && number < 70) {
    return '600';
  } else if (number >= 70 && number < 80) {
    return '700';
  } else if (number >= 80 && number < 90) {
    return '800';
  } else {
    // Covers the range 90 to 100
    return '900';
  }
}
export function getAllowedTargetTypes(permissionService: NgxPermissionsService) {
  const perms = permissionService.getPermissions();
  const allowedTypeList = [];
  getEnumOptions(TypesWithName).forEach((element) => {
    if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
      allowedTypeList.push(element);
    }
  });
  return [...allowedTypeList];
}
export function flattenKeys(keys: string[]): string[] {
  if (isNullObj(keys)) return null;
  const flattenedKeys: string[] = [];

  keys.forEach((key) => {
    const subitems = key.split('.'); // Split each key by dots
    flattenedKeys.push(...subitems); // Add subitems to the flattenedKeys array
  });

  return flattenedKeys;
}
export function translate(key: string | null | undefined, languageFile: any, fallbackValue = null) {
  if (!key || typeof key !== 'string') return fallbackValue ?? '';

  const tKey = key;
  const keys = tKey?.split('.'); // At this point, tKey is guaranteed to be a string
  const defaultRet =
    fallbackValue !== null && fallbackValue !== undefined ? fallbackValue : `${keys[keys.length - 1]}` || key;

  return getProp(languageFile, tKey) || humanizeCasedString(defaultRet);
}
export function translateFromLocaleStorage(key: string, fallbackValue = null) {
  let langFile = localStorage.getItem('langFile') ? JSON.parse(localStorage.getItem('langFile')) : null;
  if (langFile) {
    return translate(key, langFile, fallbackValue);
  } else {
    return humanizeCasedString(fallbackValue) || '';
  }
}
export function deepAssigne(obj: any, key: string, value: any) {
  let current = obj;
  const path = key.split('.');
  for (let i = 0; i < path.length - 1; i++) {
    if (!current[path[i]]) {
      current[path[i]] = {};
    }
    current = current[path[i]];
  }
  current[path[path.length - 1]] = value;
}
