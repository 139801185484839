import { Type } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { FieldDto } from '../model';
import { BaseInputOptions, DynamicComponentBase, DynamicFieldConfig, IDynamicComponent, IViewMode } from '../view';

export function getFieldOptions(fieldOptions: BaseInputOptions, field: FieldDto) {
  let options: BaseInputOptions = new BaseInputOptions();
  switch (field.type) {
    case FieldDto.TypeEnum.String:
      options.textInput = fieldOptions.textInput;
      options.textEditorInput = fieldOptions.textEditorInput;
      break;
    case FieldDto.TypeEnum.Integer:
      options.numberInput = fieldOptions.numberInput;
      options.numberInput.maxFractionDigits = 0;
      break;
    case FieldDto.TypeEnum.Number:
      options.numberInput = fieldOptions.numberInput;
      options.numberInput.maxFractionDigits =
        options.numberInput.maxFractionDigits == 0 ? 5 : options.numberInput.maxFractionDigits;
      break;
    case FieldDto.TypeEnum.Boolean:
      options.checkBox = fieldOptions.checkBox;
      break;
    default:
      options = fieldOptions;
      break;
  }
}
export function getFieldValidators(options) {
  let validators: ValidatorFn[] = [];
  if (!options) return validators;
  if (options?.[DynamicFieldConfig.GENERAL_MIN])
    validators.push(Validators.min(options?.[DynamicFieldConfig.GENERAL_MIN]));
  if (options?.[DynamicFieldConfig.GENERAL_MAX])
    validators.push(Validators.max(options?.[DynamicFieldConfig.GENERAL_MAX]));
  if (options?.[DynamicFieldConfig.GENERAL_REQUIRED]) validators.push(Validators.required);
  if (options?.[DynamicFieldConfig.GENERAL_REQUIRED_TRUE]) validators.push(Validators.requiredTrue);
  if (options?.[DynamicFieldConfig.GENERAL_EMAIL]) validators.push(Validators.email);
  if (options?.[DynamicFieldConfig.GENERAL_MIN_LENGTH])
    validators.push(Validators.minLength(options?.[DynamicFieldConfig.GENERAL_MIN_LENGTH]));
  if (options?.[DynamicFieldConfig.GENERAL_MAX_LENGTH])
    validators.push(Validators.maxLength(options?.[DynamicFieldConfig.GENERAL_MAX_LENGTH]));
  if (options?.[DynamicFieldConfig.GENERAL_PATTERN])
    validators.push(Validators.pattern(options?.[DynamicFieldConfig.GENERAL_PATTERN]));
  // if (options?.[DynamicFieldConfig.MCQ_REQUIRE_SCORE] && options?.[DynamicFieldConfig.MCQ_PASSING_SCORE])
  //   validators.push(Validators.min(options?.[DynamicFieldConfig.MCQ_PASSING_SCORE]));
  return validators;
}
export function arrayToTree(
  array,
  defaultExpanded = false,
  defaultSelectable = true,
  extraDefaultData = null,
  labelKey = 'label',
  key = 'code',
  parentKey = 'parent'
) {
  const map = new Map<string, TreeNode>(); // Map to store references to nodes using their code as key
  const tree: TreeNode[] = [];

  array.forEach((item) => {
    map.set(item?.[key], {
      // ...item,
      label: item?.[labelKey] || item?.label || item?.name || item?.[key],
      data: { ...item, ...extraDefaultData },
      //   expandedIcon: "pi pi-folder-open",
      //   collapsedIcon: "pi pi-folder",
      //   icon: 'pi pi-file',
      children: [],
      expanded: defaultExpanded,
      selectable: defaultSelectable,
      // leaf: false,
    }); // Create a node with an empty children array
  });

  array.forEach((item) => {
    const node = map.get(item?.[key]);
    if (item?.[parentKey]) {
      // If there is a parentCode, add the current node as a child to its parent
      const parentNode = map.get(item?.[parentKey]);
      if (parentNode) {
        parentNode.children.push(node);
      } else {
        // Handle the case where the parent node is not found (optional)
        console.error(`Parent node not found for item with code: ${item?.[parentKey]}`);
      }
    } else {
      // If there is no parentCode, it means it's a root node, add it to the tree
      tree.push(node);
    }
  });

  return { tree, linkedMap: map };
}

export const updateFormControlTree = (abstractControl: AbstractControl): void => {
  const forEachChildControl = (
    control: AbstractControl,
    callbackFunction: (abstractControl: AbstractControl) => void
  ): void => {
    const childControls = (control as AbstractControl & { controls?: AbstractControl[] }).controls;

    if (!childControls) {
      return;
    }

    if (typeof childControls === 'object') {
      const extractedChildControls: AbstractControl[] = Object.values(childControls);

      extractedChildControls.forEach((childControl) => {
        callbackFunction(childControl);
      });
    }
  };

  forEachChildControl(abstractControl, (control: AbstractControl) => updateFormControlTree(control));
  abstractControl.markAsDirty();
  abstractControl.markAsTouched();
  abstractControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
};

export function addDays(days) {
  let date = new Date(); // Get the current date
  date.setDate(date.getDate() + days); // Add the specified number of days
  date.setHours(0, 0, 0, 0);

  return date.toISOString(); // Return the date in the desired format
}

export function daysUntil(dateString) {
  // Parse the given date
  const futureDate = new Date(dateString);
  const today = new Date();

  // Ensure the given date is valid and in the future
  if (isNaN(futureDate.getTime()) || futureDate <= today) {
    throw new Error('Please provide a valid future date.');
  }

  // Calculate the difference in milliseconds
  const msPerDay = 24 * 60 * 60 * 1000;
  const diffInMs = futureDate.getTime() - today.getTime();

  // Convert to days and return the result
  return Math.ceil(diffInMs / msPerDay);
}
/**
 * use to get dynamic component object from a FieldDto
 *
 * field: type, name, label, key, options: {[key in DynamicFieldConfig]: any }
 */
export function getDynamicFieldObjectOptions(
  field: FieldDto,
  dynamicFieldMap: { [key in FieldDto.TypeEnum]: Type<DynamicComponentBase> },
  dynamicFieldValues: { [x: string]: any },
  formControl: AbstractControl<any, any>,
  i: number,
  viewMode: IViewMode,
  showScore: boolean = false
) {
  // type:FieldDto.TypeEnum,name:string,label:string,key:string,options:{[key in DynamicFieldConfig]: any }
  // const field = {type,name,label,key,options}
  return {
    componentType: dynamicFieldMap[field.type],
    options: {
      label: field.label || field.name,
      name: field.key,
      placeholder: field?.options?.[DynamicFieldConfig.PLACEHOLDER] ?? 'Fill ' + field.label,
      control: formControl,
      inputOptions: {
        numberInput: {
          maxFractionDigits:
            field.type == 'INTEGER' ? 0 : (field?.options?.[DynamicFieldConfig.NUMBER_MAX_FRACTIONS] ?? 5),
          showButtons: true,
          min: field?.options?.[DynamicFieldConfig.GENERAL_MIN] ?? undefined,
          max: field?.options?.[DynamicFieldConfig.GENERAL_MAX] ?? undefined,
        },
        attachmentInput: {
          bucketId: field?.options?.[DynamicFieldConfig.ATTACHMENT_FOLDER] ?? 'root',
          mode: 'basic',
          multiple: field?.options?.[DynamicFieldConfig.ATTACHMENT_MULTIPLE] ?? false,
          accept: field?.options?.[DynamicFieldConfig.ATTACHMENT_ACCEPT] ?? null,
          maxFileSize: field?.options?.[DynamicFieldConfig.ATTACHMENT_MAX_FILE_SIZE] ?? null,
          fileLimit: field?.options?.[DynamicFieldConfig.ATTACHMENT_FILE_LIMIT] ?? null,
          uploadLocation: field?.options?.[DynamicFieldConfig.ATTACHMENT_UPLOAD_MODE] ?? null,
          //@IMPORTANT
          //@TODO: add support to use any link
          // externalCode: field?.options?.[DynamicFieldConfig.ATTACHMENT_EXTERNAL_CODE] ?? null,
          //@IMPORTANT
          //@TODO: add to backend enums
          // acceptCustomLinks: field?.options?.[DynamicFieldConfig.ATTACHMENT_ACCEPT_CUSTOM_LINKS] ?? null,
        },
        dropDownInput: {
          items:
            field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored'
              ? field?.options?.[DynamicFieldConfig.MCQ_SELECTION_OPTIONS]
              : field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                  return { label: x, value: x };
                })
                : [],
          multi: field.type == FieldDto.TypeEnum.Multiselect ? true : false,
          optionLabel: field.type == FieldDto.TypeEnum.Relation ? null : 'label',
          optionValue: field.type == FieldDto.TypeEnum.Relation ? null : 'value',
          defaultOption: field?.options?.[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]
            ? field?.options?.[DynamicFieldConfig.MULTI_SELECT_DEFAULT_OPTION]
            : null,
          scored: field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored',
          showScore: field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored' && showScore,
          passingScore: field?.options?.[DynamicFieldConfig.MCQ_PASSING_SCORE],
        },
        dateInput: {
          minDate: field?.options?.[DynamicFieldConfig.MIN_DATE]
            ? new Date(field?.options?.[DynamicFieldConfig.MIN_DATE])
            : null,
          maxDate: field?.options?.[DynamicFieldConfig.MAX_DATE]
            ? new Date(field?.options?.[DynamicFieldConfig.MAX_DATE])
            : null,
          showTime: field.type == FieldDto.TypeEnum.Datetime ? true : false,
          selectionMode: field?.options?.[DynamicFieldConfig.DATE_SELECTION_MODE] ?? 'single',
          mode: field?.options?.[DynamicFieldConfig.DATE_VIEW_MODE] ?? 'date',
        },
        ratingInput: {
          maxNumber: field?.options?.[DynamicFieldConfig.RATING_MAX_NUMBER] ?? 5,
        },
        radioInput: {
          groupItems:
            field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored'
              ? field?.options?.[DynamicFieldConfig.MCQ_SELECTION_OPTIONS]
              : field?.options?.[DynamicFieldConfig.LIST_OPTIONS]
                ? (field?.options?.[DynamicFieldConfig.LIST_OPTIONS] as string[]).map((x) => {
                  return { label: x, value: x };
                })
                : [],
          selectionMode: field?.options?.[DynamicFieldConfig.SINGLE_SELECTION_MODE] ?? 'radio',
          //this is the actual mcq input
          scored: field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored',
          showScore: field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored' && showScore,
          passingScore: field?.options?.[DynamicFieldConfig.MCQ_PASSING_SCORE],
        },
        codeSelectorInput: {
          targetTypes: field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]
            ? [field?.options?.[DynamicFieldConfig.RELATION_TARGET_TYPE]]
            : null,
          // relationType: (field?.options?.[DynamicFieldConfig.RELATION_TYPE] ? [field?.options?.[DynamicFieldConfig.RELATION_TYPE]] : null)
        },
        imageInput: {
          maintainAspectRatio: field?.options?.[DynamicFieldConfig.MAINTAIN_ASPECT_RATIO] ?? null,
          aspectRatio: field?.options?.[DynamicFieldConfig.ASPECT_RATIO] ?? null,
          format: field?.options?.[DynamicFieldConfig.OUTPUT_IMAGE_FORMAT] ?? null,
        },
        extra: field,
        networkInput: {
          defaultBlockValues: field?.options?.[DynamicFieldConfig.NETWORK_DEFAULT_BLOCK_VALUES] ?? null,
          disabledBlocks: field?.options?.[DynamicFieldConfig.NETWORK_DISABLED_BLOCKS] ?? null,
        },
        gvlInput: {
          gvlCode: field?.options?.[DynamicFieldConfig.GVL_CODE] ?? null,
          gvlListMode: field?.options?.[DynamicFieldConfig.GVL_LIST_MODE] ?? false,
          gvlSelectionMode: field?.options?.[DynamicFieldConfig.GVL_SELECTION_MODE] ?? false,
          gvlInlineMode: field?.options?.[DynamicFieldConfig.GVL_INLINE_MODE] ?? false,
          gvlMinSelection: field?.options?.[DynamicFieldConfig.GENERAL_MIN_LENGTH] ?? null,
          gvlMaxSelection: field?.options?.[DynamicFieldConfig.GENERAL_MAX_LENGTH] ?? null,
          gvlShowDescription: field?.options?.[DynamicFieldConfig.GVL_SHOW_DESCRIPTION] ?? null,
          gvlShowNumeric: field?.options?.[DynamicFieldConfig.GVL_SHOW_NUMERIC] ?? null,
        },
      },
      inputMask: field?.options?.[DynamicFieldConfig.INPUT_MASK] ?? null,
      viewMode: field?.options?.[DynamicFieldConfig.GENERAL_VIEW_MODE] ?? viewMode,
      id: field?.options?.[DynamicFieldConfig.ORDER] ?? i,
      data: dynamicFieldValues?.[field.key]?.value ?? null,
    },
  } as IDynamicComponent;
}
