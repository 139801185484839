/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PDFRenderConfig { 
    pdfSize?: PDFRenderConfig.PdfSizeEnum;
    header?: string;
    footer?: string;
    coverPage?: string;
    includeHeader?: boolean;
    includeFooter?: boolean;
    includeCoverPage?: boolean;
    includeTableOfContents?: boolean;
    includeReportDetails?: boolean;
    exportRaw?: boolean;
    headerHeight?: number;
    footerHeight?: number;
}
export namespace PDFRenderConfig {
    export type PdfSizeEnum = 'A0' | 'A1' | 'A2' | 'A3' | 'A4' | 'A5' | 'LEGAL' | 'LETTER' | 'TABLOID';
    export const PdfSizeEnum = {
        A0: 'A0' as PdfSizeEnum,
        A1: 'A1' as PdfSizeEnum,
        A2: 'A2' as PdfSizeEnum,
        A3: 'A3' as PdfSizeEnum,
        A4: 'A4' as PdfSizeEnum,
        A5: 'A5' as PdfSizeEnum,
        Legal: 'LEGAL' as PdfSizeEnum,
        Letter: 'LETTER' as PdfSizeEnum,
        Tabloid: 'TABLOID' as PdfSizeEnum
    };
}


