import { AppPermissions, ModuleIcons, ModuleKeywords, ProductionType } from '@shared/classes';
//@TODONewModule: when you add new module make sure to define these
export const MenuItemList: MenuItem[] = [
  {
    label: 'modules.dataManagement.dashboard.dashboards',
    icon: 'sf sf-main',
    routerLink: ['/dashboard/tabs'],
    items: [
      // { label: 'Main', icon: 'pi pi-circle', routerLink: ['/dashboards/main'] },
      // { label: 'Entities', icon: ModuleIcons.Entity, routerLink: ['/dashboards/entities'], permission: [AppPermissions.ReadResponsibility] },
    ],
  },
  {
    label: 'modules.entity.entities',
    icon: ModuleIcons.Entity,
    routerLink: ['/entity'],
    permission: [
      AppPermissions.ReadResponsibility,
      AppPermissions.ReadObjective,
      AppPermissions.ReadAsset,
      AppPermissions.ReadEvent,
      AppPermissions.ReadGeography,
      AppPermissions.ReadProcess,
      AppPermissions.ReadEntityType,
      AppPermissions.ReadOrganization,
    ],
    items: [
      // { label: 'Entities Dashboard', icon: 'pi pi-fw pi-table', routerLink: ['/entity/dashboard'], permission: [AppPermissions.ReadEntity] },
      // { label: 'Entity List', icon: 'pi pi-fw pi-ticket', routerLink: ['/entity/list'], permission: [AppPermissions.ReadEntity] },
      // { label: 'Import Data', icon: 'pi pi-fw pi-ticket', routerLink: ['/entity/import-data'], permission: [AppPermissions.ReadAnyEntity] },
      {
        label: 'modules.entity.organization.organizations',
        icon: ModuleIcons?.[ModuleKeywords.Organization],
        routerLink: ['/entity/organization/list'],
        permission: [AppPermissions.ReadOrganization],
        moduleKeyword: ModuleKeywords.Organization,
      },
      {
        label: 'modules.entity.responsibility.responsibilities',
        icon: ModuleIcons?.[ModuleKeywords.Responsibility],
        routerLink: ['/entity/responsibility/list'],
        permission: [AppPermissions.ReadResponsibility],
        moduleKeyword: ModuleKeywords.Responsibility,
      },
      {
        label: 'modules.entity.objective.objectives',
        icon: ModuleIcons?.[ModuleKeywords.Objective],
        routerLink: ['/entity/objective/list'],
        permission: [AppPermissions.ReadObjective],
        moduleKeyword: ModuleKeywords.Objective,
      },
      {
        label: 'modules.entity.asset.assets',
        icon: ModuleIcons?.[ModuleKeywords.Asset],
        routerLink: ['/entity/asset/list'],
        permission: [AppPermissions.ReadAsset],
        moduleKeyword: ModuleKeywords.Asset,
      },
      {
        label: 'modules.entity.event.events',
        icon: ModuleIcons?.[ModuleKeywords.Event],
        routerLink: ['/entity/event/list'],
        permission: [AppPermissions.ReadEvent],
        moduleKeyword: ModuleKeywords.Event,
      },
      {
        label: 'modules.entity.geography.geographies',
        icon: ModuleIcons?.[ModuleKeywords.Geography],
        routerLink: ['/entity/geography/list'],
        permission: [AppPermissions.ReadGeography],
        moduleKeyword: ModuleKeywords.Geography,
      },
      {
        label: 'modules.entity.process.processes',
        icon: ModuleIcons?.[ModuleKeywords.Process],
        routerLink: ['/entity/process/list'],
        permission: [AppPermissions.ReadProcess],
        moduleKeyword: ModuleKeywords.Process,
      },
      {
        label: `modules.entity.entityType.entityTypes`,
        icon: ModuleIcons?.[ModuleKeywords.EntityType],
        routerLink: ['/entity/entity-type/list'],
        permission: [AppPermissions.ReadEntityType],
        moduleKeyword: ModuleKeywords.EntityType,
      },
      // {
      //     label: 'Entity Configuration', icon: 'sf sf-entity-configuration', routerLink: ['/entity'], permission: [AppPermissions.ReadEntityType, AppPermissions.ReadEntityGroup],
      //     items: [
      //         { label: 'Entity Types', icon: ModuleIcons?.[ModuleKeywords.EntityType], routerLink: ['/entity/entity-type/list'], permission: [AppPermissions.ReadEntityType], moduleKeyword: ModuleKeywords.EntityType },
      //         // { label: 'Entity Levels', icon: 'pi pi-fw pi-sort-amount-up-alt', routerLink: ['/entity/entity-levels/list'],permission:[AppPermissions.ReadEntityLevels] },
      //         // { label: 'Entity Groups', icon: ModuleIcons?.[ModuleKeywords.EntityGroup], routerLink: ['/entity/entity-group/list'], permission: [AppPermissions.ReadEntityGroup], moduleKeyword: ModuleKeywords.EntityGroup },
      //     ]
      // },
    ],
  },
  {
    label: 'modules.framework.framework',
    icon: ModuleIcons.FRAMEWORK,
    routerLink: ['/framework'],
    permission: [
      AppPermissions.ReadStandard,
      AppPermissions.ReadPolicy,
      AppPermissions.ReadPolicyBody,
      AppPermissions.ReadPolicyBodySection,
      AppPermissions.ReadGuideline,
      AppPermissions.ReadPublishedGuideline,
      AppPermissions.ReadPublishedPolicy,
      AppPermissions.ReadPublishedStandard,
      AppPermissions.ReadControl,
      AppPermissions.ReadControlCategory,
      AppPermissions.ReadControlGroup,
      AppPermissions.ReadControlObjective,
      AppPermissions.ReadControlProcedure,
      AppPermissions.ReadAttestation,
      AppPermissions.ReadException,
      AppPermissions.ReadAuthorityDocument,
      AppPermissions.ReadCitation,
      AppPermissions.ReadCitationType,
      AppPermissions.ReadExemption,
      AppPermissions.ReadComplianceReport,
      AppPermissions.ReadComplianceReportResult,
    ],
    items: [
      {
        label: 'modules.framework.evidence.evidences',
        icon: ModuleIcons?.[ModuleKeywords.Evidence],
        routerLink: ['/framework/evidence/list'],
        permission: [AppPermissions.ReadEvidence],
        moduleKeyword: ModuleKeywords.Evidence,
      },
      {
        label: 'modules.framework.standard.standards',
        icon: ModuleIcons?.[ModuleKeywords.Standard],
        routerLink: ['/framework/standard/list'],
        permission: [AppPermissions.ReadStandard],
        moduleKeyword: ModuleKeywords.Standard,
      },
      {
        label: 'modules.framework.policy.policyManagement',
        icon: ModuleIcons?.[ModuleKeywords.Policy],
        routerLink: ['/framework/policy'],
        permission: [AppPermissions.ReadPolicy, AppPermissions.ReadPolicyBody, AppPermissions.ReadPolicyBodySection],
        class: '',
        items: [
          {
            label: 'modules.framework.policy.policies',
            icon: ModuleIcons?.[ModuleKeywords.Policy],
            routerLink: ['/framework/policy/list'],
            permission: [AppPermissions.ReadPolicy],
            moduleKeyword: ModuleKeywords.Policy,
          },
          {
            label: 'modules.framework.policy.policyBody.policyBodies',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBody],
            routerLink: ['/framework/policy/policy-body/list'],
            permission: [AppPermissions.ReadPolicyBody],
            moduleKeyword: ModuleKeywords.PolicyBody,
          },
          {
            label: 'modules.framework.policy.policyBodyTemplate.policyBodyTemplates',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodyTemplate],
            routerLink: ['/framework/policy/policy-body-template/list'],
            permission: [AppPermissions.ReadPolicyBodyTemplate],
            moduleKeyword: ModuleKeywords.PolicyBodyTemplate,
          },
          {
            label: 'modules.framework.policy.policyBodySection.policyBodySections',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
            routerLink: ['/framework/policy/policy-body-section/list'],
            permission: [AppPermissions.ReadPolicyBodySection],
            moduleKeyword: ModuleKeywords.PolicyBodySection,
          },
          {
            label: 'modules.framework.policy.policyBodySectionTemplate.policyBodySectionTemplates',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodySectionTemplate],
            routerLink: ['/framework/policy/policy-body-section-template/list'],
            permission: [AppPermissions.ReadPolicyBodySectionTemplate],
            moduleKeyword: ModuleKeywords.PolicyBodySectionTemplate,
          },
          {
            label: 'modules.framework.policy.policyBodySection.unlinkedSections',
            icon: ModuleIcons?.[ModuleKeywords.PolicyBodySection],
            routerLink: ['/framework/policy/policy-body-section/unlinked'],
            permission: [AppPermissions.ReadPolicyBodySection],
            moduleKeyword: ModuleKeywords.PolicyBodySection,
          },
        ],
      },
      {
        label: 'modules.framework.guideline.guidelines', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.Guideline],
        routerLink: ['/framework/guideline/list'],
        permission: [AppPermissions.ReadGuideline],
        moduleKeyword: ModuleKeywords.Guideline,
      },
      {
        label: 'modules.framework.published.published',
        icon: ModuleIcons?.[ModuleKeywords.Policy],
        routerLink: ['/framework/published'],
        permission: [
          AppPermissions.ReadPublishedGuideline,
          AppPermissions.ReadPublishedPolicy,
          AppPermissions.ReadPublishedStandard,
        ],
        class: '',
        items: [
          {
            label: 'modules.framework.published.publishedGuideline.publishedGuidelines',
            icon: ModuleIcons?.[ModuleKeywords.PublishedGuideline],
            routerLink: ['/framework/published/published-guideline/list'],
            permission: [AppPermissions.ReadPublishedGuideline],
            moduleKeyword: ModuleKeywords.PublishedGuideline,
          },
          {
            label: 'modules.framework.published.publishedPolicy.publishedPolicies',
            icon: ModuleIcons?.[ModuleKeywords.PublishedPolicy],
            routerLink: ['/framework/published/published-policy/list'],
            permission: [AppPermissions.ReadPublishedPolicy],
            moduleKeyword: ModuleKeywords.PublishedPolicy,
          },
          {
            label: 'modules.framework.published.publishedStandard.publishedStandards',
            icon: ModuleIcons?.[ModuleKeywords.Standard],
            routerLink: ['/framework/published/published-standard/list'],
            permission: [AppPermissions.ReadPublishedStandard],
            moduleKeyword: ModuleKeywords.PublishedStandard,
          },
        ],
      },
      {
        label: 'modules.framework.control.controlsManagement',
        icon: ModuleIcons?.[ModuleKeywords.Citation],
        routerLink: ['/framework/control-management'],
        permission: [
          AppPermissions.ReadControl,
          AppPermissions.ReadControlCategory,
          AppPermissions.ReadControlGroup,
          AppPermissions.ReadControlObjective,
          AppPermissions.ReadControlProcedure,
          AppPermissions.ReadAttestation,
        ],
        class: '',
        items: [
          {
            label: 'modules.framework.control.controls',
            icon: ModuleIcons?.[ModuleKeywords.Control],
            routerLink: ['/framework/control-management/control/list'],
            permission: [AppPermissions.ReadControl],
            moduleKeyword: ModuleKeywords.Control,
          },
          {
            label: 'modules.framework.controlCategory.controlCategories',
            icon: ModuleIcons?.[ModuleKeywords.ControlCategory],
            routerLink: ['/framework/control-management/control-category/list'],
            permission: [AppPermissions.ReadControlCategory],
            moduleKeyword: ModuleKeywords.ControlCategory,
          },
          {
            label: 'modules.framework.controlGroup.controlGroups',
            icon: ModuleIcons?.[ModuleKeywords.ControlGroup],
            routerLink: ['/framework/control-management/control-group/list'],
            permission: [AppPermissions.ReadControlGroup],
            moduleKeyword: ModuleKeywords.ControlGroup,
          },
          {
            label: 'modules.framework.controlObjective.controlObjectives', //@TODO: check breadcrumbs for adding s
            icon: ModuleIcons?.[ModuleKeywords.ControlObjective],
            routerLink: ['/framework/control-management/control-objective/list'],
            permission: [AppPermissions.ReadControlObjective],
            moduleKeyword: ModuleKeywords.ControlObjective,
          },
          {
            label: 'modules.framework.controlProcedure.controlProcedures', //@TODO: check breadcrumbs for adding s
            icon: ModuleIcons?.[ModuleKeywords.ControlProcedure],
            routerLink: ['/framework/control-management/control-procedure/list'],
            permission: [AppPermissions.ReadControlProcedure],
            moduleKeyword: ModuleKeywords.ControlProcedure,
          },
          {
            label: 'modules.framework.attestation.attestations',
            icon: ModuleIcons?.[ModuleKeywords.Attestation],
            routerLink: ['/framework/control-management/attestation/list'],
            permission: [AppPermissions.ReadAttestation],
            moduleKeyword: ModuleKeywords.Attestation,
          },
        ],
      },
      {
        label: 'modules.framework.exception.exceptions',
        icon: ModuleIcons?.[ModuleKeywords.Exception],
        routerLink: ['/framework/exception/list'],
        permission: [AppPermissions.ReadException],
        moduleKeyword: ModuleKeywords.Exception,
      },
      // {
      //   label: 'Check Item',
      //   icon: 'pi pi-fw pi-check',
      //   routerLink: ['/framework/check-item'],
      //   permission: [AppPermissions.ReadAuthorityDocument],
      // },
      {
        label: 'modules.framework.authority.authority',
        icon: ModuleIcons?.[ModuleKeywords.Citation],
        routerLink: ['/framework/authority'],
        permission: [
          AppPermissions.ReadAuthorityDocument,
          AppPermissions.ReadCitation,
          AppPermissions.ReadCitationType,
          AppPermissions.ReadExemption,
        ],
        class: '',
        items: [
          {
            label: 'modules.framework.authority.authorityDocument.authorityDocuments',
            icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument],
            routerLink: ['/framework/authority/authority-document/list'],
            permission: [AppPermissions.ReadAuthorityDocument],
            moduleKeyword: ModuleKeywords.AuthorityDocument,
          },
          {
            label: 'modules.framework.authority.citation.citations',
            icon: ModuleIcons?.[ModuleKeywords.Citation],
            routerLink: ['/framework/authority/citation/list'],
            permission: [AppPermissions.ReadCitation],
            moduleKeyword: ModuleKeywords.Citation,
          },
          {
            label: 'modules.framework.authority.citation.unlinkedCitations',
            icon: ModuleIcons?.[ModuleKeywords.Citation],
            routerLink: ['/framework/authority/citation/unlinked'],
            permission: [AppPermissions.ReadCitation],
            moduleKeyword: ModuleKeywords.Citation,
          },
          {
            label: 'modules.framework.authority.citationType.citationTypes',
            icon: ModuleIcons?.[ModuleKeywords.CitationType],
            routerLink: ['/framework/authority/citation-type/list'],
            permission: [AppPermissions.ReadCitationType],
            moduleKeyword: ModuleKeywords.CitationType,
          },
          {
            label: 'modules.framework.authority.exemption.exemptions',
            icon: ModuleIcons?.[ModuleKeywords.Exemption],
            routerLink: ['/framework/authority/exemption/list'],
            permission: [AppPermissions.ReadExemption],
            moduleKeyword: ModuleKeywords.Exemption,
          },
        ],
      },
      {
        label: 'modules.framework.complianceReport.complianceReports',
        icon: ModuleIcons?.[ModuleKeywords.ComplianceReport],
        routerLink: ['/framework/compliance-report/list'],
        permission: [AppPermissions.ReadComplianceReport],
        moduleKeyword: ModuleKeywords.ComplianceReport,
      },
      {
        label: 'modules.framework.complianceReportResult.complianceReportResults',
        icon: ModuleIcons?.[ModuleKeywords.ComplianceReportResult],
        routerLink: ['/framework/compliance-report-result/list'],
        permission: [AppPermissions.ReadComplianceReportResult],
        moduleKeyword: ModuleKeywords.ComplianceReportResult,
      },
      {
        label: 'modules.framework.controlsGapReport.controlsGapReports',
        icon: ModuleIcons?.[ModuleKeywords.ControlsGapReport],
        routerLink: ['/framework/controls-gap-report/list'],
        permission: [AppPermissions.ReadControlsGapReport],
        moduleKeyword: ModuleKeywords.ControlsGapReport,
      },
      {
        label: 'modules.framework.controlsGapReportResult.controlsGapReportResults',
        icon: ModuleIcons?.[ModuleKeywords.ControlsGapReportResult],
        routerLink: ['/framework/controls-gap-report-result/list'],
        permission: [AppPermissions.ReadControlsGapReportResult],
        moduleKeyword: ModuleKeywords.ControlsGapReportResult,
      },
    ],
  },
  {
    label: 'modules.acknowledgmentManagement.acknowledgment.acknowledgment',
    icon: ModuleIcons?.[ModuleKeywords.Acknowledgment],
    routerLink: ['/acknowledgment-management'],
    permission: [
      AppPermissions.ReadAcknowledgment,
      AppPermissions.ReadAcknowledgmentRequest,
      AppPermissions.ReadMyAcknowledgment,
    ],
    items: [
      {
        label: 'modules.acknowledgmentManagement.acknowledgment.acknowledgments',
        icon: ModuleIcons?.[ModuleKeywords.Acknowledgment],
        routerLink: ['/acknowledgment-management/acknowledgment/list'],
        permission: [AppPermissions.ReadAcknowledgment],
        moduleKeyword: ModuleKeywords.Acknowledgment,
      },
      // { label: 'My Acknowledgments', icon: 'pi pi-fw pi-book', routerLink: ['/acknowledgment/my-list'], permission: [AppPermissions.ReadAcknowledgment] },
      {
        label: 'modules.acknowledgmentManagement.acknowledgmentRequest.acknowledgmentRequests',
        icon: ModuleIcons?.[ModuleKeywords.AcknowledgmentRequest],
        routerLink: ['/acknowledgment-management/acknowledgment-request/list'],
        permission: [AppPermissions.ReadAcknowledgmentRequest],
        moduleKeyword: ModuleKeywords.AcknowledgmentRequest,
      },
      {
        label: 'modules.acknowledgmentManagement.acknowledgment.myAcknowledgments',
        icon: ModuleIcons?.[ModuleKeywords.MyAcknowledgment],
        routerLink: ['/acknowledgment-management/my-acknowledgment/list'],
        permission: [AppPermissions.ReadMyAcknowledgment],
        moduleKeyword: ModuleKeywords.MyAcknowledgment,
      },
    ],
  },
  {
    label: 'modules.activityFeed.activityFeed',
    icon: 'pi pi-fw pi-calendar',
    routerLink: ['/activity-feed'],
    permission: [
      AppPermissions.ReadNotification,
      AppPermissions.ReadMyNotification,
      AppPermissions.ReadMyTask,
      AppPermissions.ReadTask,
      AppPermissions.ReadEmailTemplate,
      AppPermissions.ReadTaskTemplate,
    ],
    items: [
      {
        label: 'modules.activityFeed.myNotification.myNotification',
        icon: ModuleIcons?.[ModuleKeywords.MyNotification],
        routerLink: ['/activity-feed/notification/my-notification/list'],
        permission: [AppPermissions.ReadMyNotification],
        moduleKeyword: ModuleKeywords.MyNotification,
      },
      {
        label: 'modules.activityFeed.notification.notifications',
        icon: ModuleIcons.AllNotification,
        routerLink: ['/activity-feed/notification/list'],
        permission: [AppPermissions.ReadNotification],
        moduleKeyword: ModuleKeywords.Notification,
      },
      {
        label: 'modules.activityFeed.myTask.myTasks',
        icon: ModuleIcons.MyTasks,
        routerLink: ['/activity-feed/task/my-task/list'],
        permission: [AppPermissions.ReadMyTask],
      },
      {
        label: 'modules.activityFeed.task.tasks',
        icon: ModuleIcons.AllTasks,
        routerLink: ['/activity-feed/task/list'],
        permission: [AppPermissions.ReadTask],
      },
      {
        label: 'modules.activityFeed.emailTemplate.emailTemplates',
        icon: ModuleIcons?.[ModuleKeywords.EmailTemplate],
        routerLink: ['/activity-feed/email-template/list'],
        permission: [AppPermissions.ReadEmailTemplate],
        moduleKeyword: ModuleKeywords.EmailTemplate,
      },
      {
        label: 'modules.activityFeed.taskTemplate.taskTemplates',
        icon: ModuleIcons?.[ModuleKeywords.TaskTemplate],
        routerLink: ['/activity-feed/task-template/list'],
        permission: [AppPermissions.ReadTaskTemplate],
        moduleKeyword: ModuleKeywords.TaskTemplate,
      },
    ],
  },
  {
    label: 'modules.audit.audit',
    icon: 'sf sf-audit',
    routerLink: ['/audit'],
    permission: [
      AppPermissions.ReadAuditUniverse,
      AppPermissions.ReadAuditProgram,
      AppPermissions.ReadAuditProgramType,
      AppPermissions.ReadAuditProgramReport,
      AppPermissions.ReadAuditCaseTemplate,
      AppPermissions.ReadAuditProject,
      AppPermissions.ReadAuditCase,
      AppPermissions.ReadAuditCaseResult,
      AppPermissions.ReadAuditExpense,
      AppPermissions.ReadAuditRequest,
      AppPermissions.ReadAuditProjectReport,
    ],
    items: [
      {
        label: 'modules.audit.auditUniverse.auditUniverses', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditUniverse],
        routerLink: ['/audit/audit-universe/list'],
        permission: [AppPermissions.ReadAuditUniverse],
        moduleKeyword: ModuleKeywords.AuditUniverse,
      },
      {
        label: 'modules.audit.auditObjective.auditObjectives',
        icon: ModuleIcons?.[ModuleKeywords.AuditObjective],
        routerLink: ['/audit/audit-objective/list'],
        permission: [AppPermissions.ReadAuditObjective],
        moduleKeyword: ModuleKeywords.AuditObjective,
      },
      {
        label: 'modules.audit.auditProgram.auditPrograms', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgram],
        routerLink: ['/audit/audit-program/list'],
        permission: [AppPermissions.ReadAuditProgram],
        moduleKeyword: ModuleKeywords.AuditProgram,
      },
      {
        label: 'modules.audit.auditProgramType.auditProgramTypes', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgramType],
        routerLink: ['/audit/audit-program-type/list'],
        permission: [AppPermissions.ReadAuditProgramType],
        moduleKeyword: ModuleKeywords.AuditProgramType,
      },
      {
        label: 'modules.audit.auditProgramReport.auditProgramReports', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProgramReport],
        routerLink: ['/audit/audit-program-report/list'],
        permission: [AppPermissions.ReadAuditProgramReport, AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.AuditProgramReport,
      },
      {
        label: 'modules.audit.auditCaseTemplate.auditCaseTemplates', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCaseTemplate],
        routerLink: ['/audit/audit-case-template/list'],
        permission: [AppPermissions.ReadAuditCaseTemplate],
        moduleKeyword: ModuleKeywords.AuditCaseTemplate,
      },
      {
        label: 'modules.audit.auditProject.auditProjects', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProject],
        routerLink: ['/audit/audit-project/list'],
        permission: [AppPermissions.ReadAuditProject],
        moduleKeyword: ModuleKeywords.AuditProject,
      },
      {
        label: 'modules.audit.auditCase.auditCases', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCase],
        routerLink: ['/audit/audit-case/list'],
        permission: [AppPermissions.ReadAuditCase],
        moduleKeyword: ModuleKeywords.AuditCase,
      },
      {
        label: 'modules.audit.auditCaseResult.auditCasesResults', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditCaseResult],
        routerLink: ['/audit/audit-case-result/list'],
        permission: [AppPermissions.ReadAuditCaseResult],
        moduleKeyword: ModuleKeywords.AuditCaseResult,
      },
      {
        label: 'modules.audit.auditExpense.auditExpenses', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditExpense],
        routerLink: ['/audit/audit-expense/list'],
        permission: [AppPermissions.ReadAuditExpense],
        moduleKeyword: ModuleKeywords.AuditExpense,
      },
      // { label: 'My Auditee Cases', icon: 'pi pi-pen-to-square', routerLink: ['/audit/audit-case-result/my-audits-auditee'], permission: [AppPermissions.ReadAuditCaseResult], moduleKeyword: ModuleKeywords.AuditCaseResult },
      // { label: 'My Auditor Cases', icon: 'pi pi-user-edit', routerLink: ['/audit/audit-case-result/my-audits-auditor'], permission: [AppPermissions.ReadAuditCaseResult], moduleKeyword: ModuleKeywords.AuditCaseResult },
      {
        label: 'modules.audit.auditRequest.auditRequests',
        icon: ModuleIcons?.[ModuleKeywords.AuditRequest],
        routerLink: ['/audit/audit-request/list'],
        permission: [AppPermissions.ReadAuditRequest],
        moduleKeyword: ModuleKeywords.AuditRequest,
      },
      {
        label: 'modules.audit.auditProjectReport.auditProjectReports', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.AuditProjectReport],
        routerLink: ['/audit/audit-project-report/list'],
        permission: [AppPermissions.ReadAuditProjectReport, AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.AuditProjectReport,
      },
      {
        label: 'modules.audit.myAuditWorkspace',
        icon: 'pi pi-pen-to-square',
        routerLink: ['/audit/my-audit-workspace'],
        permission: [AppPermissions.UpdateAuditRequest, AppPermissions.UpdateAuditCaseResult],
        moduleKeyword: ModuleKeywords.AuditRequest,
      },
    ],
  },
  // {
  //     label: 'Tasks', icon: 'sf sf-tasks', routerLink: ['/activity-feed/task'], permission: [AppPermissions.ReadTask, AppPermissions.ReadMyTask],
  //     items: [
  //         // { label: 'Notifications', icon: ModuleIcons.Notification, routerLink: ['/activity-feed/notifications'],permission:[AppPermissions.ReadNotification]},
  //         { label: 'My Tasks', icon: ModuleIcons.MyTasks, routerLink: ['/activity-feed/task/my-task/list'],permission:[AppPermissions.ReadTask], moduleKeyword: ModuleKeywords.MyTask},
  //         { label: 'All Tasks', icon: ModuleIcons.AllTasks, routerLink: ['/activity-feed/task/list'],permission:[AppPermissions.ReadTask], moduleKeyword: ModuleKeywords.Task},
  //     ]
  // },

  {
    label: 'modules.risk.risk',
    icon: 'sf sf-risk-mangement',
    routerLink: ['/risk'],
    permission: [
      AppPermissions.ReadRiskMethodology,
      AppPermissions.ReadRiskItem,
      AppPermissions.ReadRiskRegister,
      AppPermissions.ReadRiskProject,
      AppPermissions.ReadRiskAssessmentRequest,
      AppPermissions.ReadRiskStatement,
      AppPermissions.ReadRiskStatementCategory,
      AppPermissions.ReadRiskThreat,
      AppPermissions.ReadRiskVulnerability,
    ],
    items: [
      {
        label: 'modules.risk.riskMethodology.riskMethodologies',
        icon: ModuleIcons?.[ModuleKeywords.RiskMethodology],
        routerLink: ['/risk/risk-methodology/list'],
        permission: [AppPermissions.ReadRiskMethodology],
        moduleKeyword: ModuleKeywords.RiskMethodology,
      },
      {
        label: 'modules.risk.riskMethodology.activeRiskMethodology',
        icon: ModuleIcons?.[ModuleKeywords.RiskMethodology],
        routerLink: ['/risk/risk-methodology/active'],
        permission: [AppPermissions.ReadRiskMethodology],
        moduleKeyword: ModuleKeywords.RiskMethodology,
      },
      {
        label: 'modules.risk.risks', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons.RiskList,
        routerLink: ['/risk/risk-item/list'],
        permission: [AppPermissions.ReadRiskItem],
        moduleKeyword: ModuleKeywords.RiskItem,
      },
      {
        label: 'modules.risk.riskItem.riskRegisters', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskItem],
        routerLink: ['/risk/risk-item/register'],
        permission: [AppPermissions.ReadRiskRegister],
        moduleKeyword: ModuleKeywords.RiskRegister,
      },
      {
        label: 'modules.risk.riskProject.riskProjects',
        icon: ModuleIcons?.[ModuleKeywords.RiskProject],
        routerLink: ['/risk/risk-project/list'],
        permission: [AppPermissions.ReadRiskProject],
        moduleKeyword: ModuleKeywords.RiskProject,
      },
      {
        label: 'modules.risk.riskAssessmentRequest.riskAssessmentRequests',
        icon: ModuleIcons?.[ModuleKeywords.RiskAssessmentRequest],
        routerLink: ['/risk/risk-assessment-request/list'],
        permission: [AppPermissions.ReadRiskAssessmentRequest],
        moduleKeyword: ModuleKeywords.RiskAssessmentRequest,
      },
      // { label: 'My Risk Assessment Request', icon: ModuleIcons?.[ModuleKeywords.MyRiskAssessmentRequest], routerLink: ['/risk/my-risk-assessment-request/list'], permission: [AppPermissions.ReadMyRiskAssessmentRequest], moduleKeyword: ModuleKeywords.MyRiskAssessmentRequest },
      {
        label: 'modules.risk.riskStatement.riskStatements', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskStatement],
        routerLink: ['/risk/risk-statement/list'],
        permission: [AppPermissions.ReadRiskStatement],
        moduleKeyword: ModuleKeywords.RiskStatement,
      },
      {
        label: 'modules.risk.riskStatementCategory.riskStatementCategories', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskStatementCategory],
        routerLink: ['/risk/risk-statement-category/list'],
        permission: [AppPermissions.ReadRiskStatementCategory],
        moduleKeyword: ModuleKeywords.RiskStatementCategory,
      },
      {
        label: 'modules.risk.riskThreat.riskThreats', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskThreat],
        routerLink: ['/risk/risk-threat/list'],
        permission: [AppPermissions.ReadRiskThreat],
        moduleKeyword: ModuleKeywords.RiskThreat,
      },
      {
        label: 'modules.risk.riskVulnerability.riskVulnerabilities', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.RiskVulnerability],
        routerLink: ['/risk/risk-vulnerability/list'],
        permission: [AppPermissions.ReadRiskVulnerability],
        moduleKeyword: ModuleKeywords.RiskVulnerability,
      },
    ],
  },
  // {
  //     label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
  //     items: [
  //         { label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
  //         { label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login'] },
  //         { label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error'] },
  //         { label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound'] },
  //         { label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access'] },
  //         { label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty'] },
  //         { label: 'Form Example', icon: 'pi pi-fw pi-id-card', routerLink: ['/pages/form'] },
  //         { label: 'Dynamic Form Example', icon: 'pi pi-fw pi-id-card', routerLink: ['/pages/dynamic-form'] },
  //         { label: 'Table Example', icon: 'pi pi-fw pi-list', routerLink: ['/pages/table'] }

  //     ]
  // },

  // {
  //     label: 'Authority', icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument], routerLink: ['/authority'], permission: [AppPermissions.ReadAuthorityDocument, AppPermissions.ReadCitationType, AppPermissions.ReadExemption, AppPermissions.ReadCitation, AppPermissions.ReadCitationComplianceReport, AppPermissions.ReadCitationComplianceReportResult],
  //     items: [
  //         { label: 'Authority Documents', icon: ModuleIcons?.[ModuleKeywords.AuthorityDocument], routerLink: ['/authority/authority-document/list'], permission: [AppPermissions.ReadAuthorityDocument], moduleKeyword: ModuleKeywords.AuthorityDocument },
  //         { label: 'Citations', icon: ModuleIcons?.[ModuleKeywords.Citation], routerLink: ['/authority/citation/list'], permission: [AppPermissions.ReadCitation], moduleKeyword: ModuleKeywords.Citation },
  //         { label: 'Citation Types', icon: ModuleIcons?.[ModuleKeywords.CitationType], routerLink: ['/authority/citation-type/list'], permission: [AppPermissions.ReadCitationType], moduleKeyword: ModuleKeywords.CitationType },
  //         { label: 'Exemptions', icon: ModuleIcons?.[ModuleKeywords.Exemption], routerLink: ['/authority/exemption/list'], permission: [AppPermissions.ReadExemption], moduleKeyword: ModuleKeywords.Exemption },
  //         { label: 'Compliance Reports', icon: ModuleIcons?.[ModuleKeywords.ComplianceReport], routerLink: ['/authority/citation-compliance-report/list'], permission: [AppPermissions.ReadCitationComplianceReport], moduleKeyword: ModuleKeywords.CitationComplianceReport },
  //         { label: 'Compliance Report Results', icon: ModuleIcons?.[ModuleKeywords.ComplianceReportResult], routerLink: ['/authority/citation-compliance-report-result/list'], permission: [AppPermissions.ReadCitationComplianceReportResult], moduleKeyword: ModuleKeywords.CitationComplianceReportResult },
  //         // { label: 'Check Item', icon: 'pi pi-fw pi-check', routerLink: ['/authority/check-item'], permission: [AppPermissions.ReadAuthorityDocument] },
  //     ]
  // },
  {
    label: 'modules.issueManagement.issue.issue',
    icon: ModuleIcons?.[ModuleKeywords.Issue],
    routerLink: ['/issue-management'],
    permission: [
      AppPermissions.ReadIssue,
      AppPermissions.ReadIssueType,
      AppPermissions.ReadRemediation,
      AppPermissions.ReadRemediationAction,
    ],
    items: [
      {
        label: 'modules.issueManagement.issue.issues',
        icon: ModuleIcons?.[ModuleKeywords.Issue],
        routerLink: ['/issue-management/issue/list'],
        permission: [AppPermissions.ReadIssue],
        moduleKeyword: ModuleKeywords.Issue,
      },
      {
        label: 'modules.issueManagement.issueType.issueTypes',
        icon: ModuleIcons?.[ModuleKeywords.IssueType],
        routerLink: ['/issue-management/issue-type/list'],
        permission: [AppPermissions.ReadIssueType],
        moduleKeyword: ModuleKeywords.IssueType,
      },
      {
        label: 'modules.issueManagement.remediation.remediations',
        icon: ModuleIcons?.[ModuleKeywords.Remediation],
        routerLink: ['/issue-management/remediation/list'],
        permission: [AppPermissions.ReadRemediation],
        moduleKeyword: ModuleKeywords.Remediation,
      },
      {
        label: 'modules.issueManagement.remediationAction.remediationActions',
        icon: ModuleIcons?.[ModuleKeywords.RemediationAction],
        routerLink: ['/issue-management/remediation-action/list'],
        permission: [AppPermissions.ReadRemediationAction],
        moduleKeyword: ModuleKeywords.RemediationAction,
      },
    ],
  },
  {
    label: 'modules.dataManagement.dataManagement',
    icon: ModuleIcons.DataStructure,
    routerLink: ['/data-management'],
    permission: [
      AppPermissions.ReadFieldTemplate,
      AppPermissions.ReadGlobalValueList,
      AppPermissions.ReadFilter,
      AppPermissions.ReadContentView,
      AppPermissions.ReadDashboard,
      AppPermissions.ReadReport,
      AppPermissions.ReadReportConfigurationTemplate,
      AppPermissions.ReadReportScheduler,
      AppPermissions.ReadGeneratedReport,
      AppPermissions.ReadStatisticCollector,
      AppPermissions.ReadGeneratedStatistic,
      AppPermissions.ReadTag,
      AppPermissions.ReadLanguage,
      AppPermissions.ReadWorkspace,
    ],
    items: [
      {
        label: 'modules.dataManagement.fieldTemplate.fieldTemplates',
        icon: ModuleIcons?.[ModuleKeywords.FieldTemplate],
        routerLink: ['/data-management/field-template/list'],
        permission: [AppPermissions.ReadFieldTemplate],
        moduleKeyword: ModuleKeywords.FieldTemplate,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.globalValueList.globalValueLists',
        icon: ModuleIcons?.[ModuleKeywords.GlobalValueList],
        routerLink: ['/data-management/global-value-list/list'],
        permission: [AppPermissions.ReadGlobalValueList],
        moduleKeyword: ModuleKeywords.GlobalValueList,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.filter.filters',
        icon: ModuleIcons?.[ModuleKeywords.Filter],
        routerLink: ['/data-management/filter/list'],
        permission: [AppPermissions.ReadFilter],
        moduleKeyword: ModuleKeywords.Filter,
      },
      {
        label: 'modules.dataManagement.contentView.contentViews',
        icon: ModuleIcons?.[ModuleKeywords.ContentView],
        routerLink: ['/data-management/content-view/list'],
        permission: [AppPermissions.ReadContentView],
        moduleKeyword: ModuleKeywords.ContentView,
      },
      {
        label: 'modules.dataManagement.dashboard.dashboardsManagement',
        icon: ModuleIcons?.[ModuleKeywords.Dashboard],
        routerLink: ['/data-management/dashboard/list'],
        permission: [AppPermissions.ReadDashboard],
        moduleKeyword: ModuleKeywords.Dashboard,
      },
      // {
      //   label: 'Dashboards v2',
      //   icon: ModuleIcons?.[ModuleKeywords.Dashboard],
      //   routerLink: ['/data-management/dashboard/dashboards/tabs'],
      //   permission: [AppPermissions.ReadDashboard],
      //   moduleKeyword: ModuleKeywords.Dashboard,
      // },
      // {
      //   label: 'Dashboard Cards',
      //   icon: ModuleIcons?.[ModuleKeywords.Dashboard],
      //   routerLink: ['/data-management/dashboard/dashboards/cards'],
      //   permission: [AppPermissions.ReadDashboard],
      //   moduleKeyword: ModuleKeywords.Dashboard,
      // },
      {
        label: 'modules.dataManagement.report.reports',
        icon: ModuleIcons?.[ModuleKeywords.Report],
        routerLink: ['/data-management/report/list'],
        permission: [AppPermissions.ReadReport],
        moduleKeyword: ModuleKeywords.Report,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.reportConfigurationTemplate.reportConfigurationTemplates',
        icon: ModuleIcons?.[ModuleKeywords.ReportConfigurationTemplate],
        routerLink: ['/data-management/report-configuration-template/list'],
        permission: [AppPermissions.ReadReportConfigurationTemplate],
        moduleKeyword: ModuleKeywords.ReportConfigurationTemplate,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.reportScheduler.reportSchedulers',
        icon: ModuleIcons?.[ModuleKeywords.ReportScheduler],
        routerLink: ['/data-management/report-scheduler/list'],
        permission: [AppPermissions.ReadReportScheduler],
        moduleKeyword: ModuleKeywords.ReportScheduler,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.generatedReport.generatedReports',
        icon: ModuleIcons?.[ModuleKeywords.GeneratedReport],
        routerLink: ['/data-management/generated-report/list'],
        permission: [AppPermissions.ReadGeneratedReport],
        moduleKeyword: ModuleKeywords.GeneratedReport,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.statisticCollector.statisticCollectors',
        icon: ModuleIcons?.[ModuleKeywords.StatisticCollector],
        routerLink: ['/data-management/statistic-collector/list'],
        permission: [AppPermissions.ReadStatisticCollector],
        moduleKeyword: ModuleKeywords.StatisticCollector,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.generatedStatistic.generatedStatistics',
        icon: ModuleIcons?.[ModuleKeywords.GeneratedStatistic],
        routerLink: ['/data-management/generated-statistic/list'],
        permission: [AppPermissions.ReadGeneratedStatistic],
        moduleKeyword: ModuleKeywords.GeneratedStatistic,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.tag.tags',
        icon: ModuleIcons?.[ModuleKeywords.Tag],
        routerLink: ['/data-management/tag/list'],
        permission: [AppPermissions.ReadTag],
        moduleKeyword: ModuleKeywords.Tag,
      },
      {
        label: 'modules.dataManagement.language.languages',
        icon: ModuleIcons?.[ModuleKeywords.Language],
        routerLink: ['/data-management/language/list'],
        permission: [AppPermissions.ReadLanguage],
        moduleKeyword: ModuleKeywords.Language,
        production: 'test',
      },
      {
        label: 'modules.dataManagement.workspace.workspaces',
        icon: ModuleIcons?.[ModuleKeywords.Workspace],
        routerLink: ['/data-management/workspace/list'],
        permission: [AppPermissions.ReadWorkspace],
        moduleKeyword: ModuleKeywords.Workspace,
        production: 'test',
      },
    ],
  },
  {
    label: 'modules.relation.relation',
    icon: 'pi pi-share-alt',
    routerLink: ['/relation'],
    permission: [AppPermissions.ReadRelationType],
    items: [
      {
        label: 'modules.relation.relationType.relationTypes',
        icon: ModuleIcons?.[ModuleKeywords.RelationType],
        routerLink: ['/relation/relation-type/list'],
        permission: [AppPermissions.ReadRelationType],
        moduleKeyword: ModuleKeywords.RelationType,
      },
    ],
  },

  {
    label: 'modules.questionManagement.question.questions',
    icon: ModuleIcons.Questions,
    routerLink: ['/question-management'],
    permission: [AppPermissions.ReadQuestion, AppPermissions.ReadQuestionnaire, AppPermissions.ReadQuestionnaireValue],
    items: [
      {
        label: 'modules.questionManagement.question.questionBank',
        icon: ModuleIcons?.[ModuleKeywords.Question],
        routerLink: ['/question-management/question/list'],
        permission: [AppPermissions.ReadQuestion],
        moduleKeyword: ModuleKeywords.Question,
      },
      {
        label: 'modules.questionManagement.questionnaireType.questionnaireTypes',
        icon: ModuleIcons?.[ModuleKeywords.QuestionnaireType],
        routerLink: ['/question-management/questionnaire-type/list'],
        permission: [AppPermissions.ReadQuestionnaireType],
        moduleKeyword: ModuleKeywords.QuestionnaireType,
      },
      {
        label: 'modules.questionManagement.questionnaire.questionnaires',
        icon: ModuleIcons?.[ModuleKeywords.Questionnaire],
        routerLink: ['/question-management/questionnaire/list'],
        permission: [AppPermissions.ReadQuestionnaire],
        moduleKeyword: ModuleKeywords.Questionnaire,
      },
      {
        label: 'modules.questionManagement.campaign.campaigns',
        icon: ModuleIcons?.[ModuleKeywords.Campaign],
        routerLink: ['/question-management/campaign/list'],
        permission: [AppPermissions.ReadCampaign],
        moduleKeyword: ModuleKeywords.Campaign,
        production: 'test',
      },
      {
        label: 'modules.questionManagement.questionnaireRequest.questionnaireRequests',
        icon: ModuleIcons?.[ModuleKeywords.QuestionnaireRequest],
        routerLink: ['/question-management/questionnaire-request/list'],
        permission: [AppPermissions.ReadQuestionnaireRequest],
        moduleKeyword: ModuleKeywords.QuestionnaireRequest,
        production: 'test',
      },
      {
        label: 'modules.questionManagement.questionnaireValue.questionnaireValues',
        icon: ModuleIcons?.[ModuleKeywords.QuestionnaireValue],
        routerLink: ['/question-management/questionnaire-value/list'],
        permission: [AppPermissions.ReadQuestionnaireValue],
        moduleKeyword: ModuleKeywords.QuestionnaireValue,
      },
      // { label: 'My Questionnaires Answers', icon: ModuleIcons?.[ModuleKeywords.MyQuestionnaireValue], routerLink: ['/question/my-questionnaire-value/list'], permission: [AppPermissions.ReadMyQuestionnaireValue], moduleKeyword: ModuleKeywords.MyQuestionnaireValue },
    ],
  },
  {
    label: 'modules.transparencyManagement.transparency',
    icon: 'pi pi-receipt',
    routerLink: ['/transparency-management'],
    permission: [AppPermissions.ReadTransparencyCase, AppPermissions.ReadTransparencyCaseCategory],
    production: 'test',
    items: [
      {
        label: 'modules.transparencyManagement.transparencyCaseCategory.transparencyCaseCategories', //@TODO: check breadcrumbs for adding s
        icon: 'pi pi-clone',
        routerLink: ['/transparency-management/transparency-case-category/list'],
        permission: [AppPermissions.ReadTransparencyCaseCategory],
        moduleKeyword: ModuleKeywords.TransparencyCaseCategory,
        production: 'test',
      },
      {
        label: 'modules.transparencyManagement.transparencyCase.transparencyCase', //@TODO: check breadcrumbs for adding s
        icon: 'pi pi-circle-on',
        routerLink: ['/transparency-management/transparency-case/list'],
        permission: [AppPermissions.ReadTransparencyCase],
        moduleKeyword: ModuleKeywords.TransparencyCase,
        production: 'test',
      },
    ],
  },
  {
    label: 'modules.globalPackage.globalPackage',
    icon: 'sf sf-global-packages',
    routerLink: ['/global-package'],
    permission: [AppPermissions.ReadImportGlobalPackage, AppPermissions.ReadExportGlobalPackage],
    production: 'test',
    items: [
      {
        label: 'modules.globalPackage.exportGlobalPackage.exportGlobalPackages', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.ExportGlobalPackage],
        routerLink: ['/global-package/export-global-package/list'],
        permission: [AppPermissions.ReadExportGlobalPackage],
        moduleKeyword: ModuleKeywords.ExportGlobalPackage,
        production: 'test',
      },
      {
        label: 'modules.globalPackage.importGlobalPackage.importGlobalPackages', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.ImportGlobalPackage],
        routerLink: ['/global-package/import-global-package/list'],
        permission: [AppPermissions.ReadImportGlobalPackage],
        moduleKeyword: ModuleKeywords.ImportGlobalPackage,
        production: 'test',
      },
    ],
  },
  {
    label: 'modules.performance.performance',
    icon: ModuleIcons?.PERFORMANCE,
    routerLink: ['/performance'],
    permission: [AppPermissions.ReadMetric, AppPermissions.ReadMetricValue, AppPermissions.ReadMetricCategory],
    production: 'test',
    items: [
      {
        label: 'modules.performance.metric.metrics', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.Metric],
        routerLink: ['/performance/metric/list'],
        permission: [AppPermissions.ReadMetric],
        moduleKeyword: ModuleKeywords.Metric,
        production: 'test',
      },
      {
        label: 'modules.performance.metricValue.metricValues', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.MetricValue],
        routerLink: ['/performance/metric-value/list'],
        permission: [AppPermissions.ReadMetricValue],
        moduleKeyword: ModuleKeywords.MetricValue,
        production: 'test',
      },
      {
        label: 'modules.performance.metricCategory.metricCategories', //@TODO: check breadcrumbs for adding s
        icon: ModuleIcons?.[ModuleKeywords.MetricCategory],
        routerLink: ['/performance/metric-category/list'],
        permission: [AppPermissions.ReadMetricCategory],
        moduleKeyword: ModuleKeywords.MetricCategory,
        production: 'test',
      },
    ],
  },
  {
    label: 'modules.userManagement.userManagement',
    icon: ModuleIcons.Management,
    routerLink: ['/user-management'],
    permission: [
      AppPermissions.ReadUser,
      AppPermissions.ReadRole,
      AppPermissions.ReadGroup,
      AppPermissions.ReadStaticValue,
    ],
    items: [
      {
        label: 'modules.userManagement.user.users',
        icon: ModuleIcons?.[ModuleKeywords.User],
        routerLink: ['/user-management/user/list'],
        permission: [AppPermissions.ReadUser],
        moduleKeyword: ModuleKeywords.User,
      },
      {
        label: 'modules.userManagement.group.groups',
        icon: ModuleIcons?.[ModuleKeywords.Group],
        routerLink: ['/user-management/group/list'],
        permission: [AppPermissions.ReadGroup],
        moduleKeyword: ModuleKeywords.Group,
      },
      {
        label: 'modules.userManagement.role.roles',
        icon: ModuleIcons?.[ModuleKeywords.Role],
        routerLink: ['/user-management/role/list'],
        permission: [AppPermissions.ReadRole],
        moduleKeyword: ModuleKeywords.Role,
      },
      // { label: 'Services',      icon: 'pi pi-fw pi-server', routerLink: ['/user-management/service/list'], permission: [AppPermissions.ReadRole] },
      // { label: 'Properties',      icon: 'pi pi-fw pi-flag', routerLink: ['/user-management/property/list'], permission: [AppPermissions.ReadRole] },
      {
        label: 'modules.userManagement.staticValue.branding',
        icon: 'sf sf-branding',
        routerLink: ['/user-management/static-values'],
        permission: [AppPermissions.ReadStaticValue],
        moduleKeyword: ModuleKeywords.StaticValue,
      },
    ],
  },
];
export interface MenuItem {
  separator?: any;
  label?: string;
  icon?: string;
  routerLink?: string[];
  permission?: string[]; // Assuming permission is an array of strings
  moduleKeyword?: ModuleKeywords; // Optional moduleKeyword property
  items?: MenuItem[]; // Optional nested items
  class?: string;
  production?: ProductionType;
}
