import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  AuditTrailDto,
  BaseListResponse,
  DataItem,
  DataTypeEnum,
  IFieldDefinition,
  RequestHandlerOptions,
  isNullObj,
} from '@shared/classes';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../api/base-request-controller.service';
import { DataService } from '../api/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService extends BaseRequestControllerService<AuditTrailDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.userOperations, '/audit_trail', [
      'id',
      'code',
      'extraData',
      'type',
      'targetType',
      'targetCode',
      'creationDate',
      'actionTitle',
      'createdUser',
    ]);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  public getBySourceCode(sourceCode: string) {
    const newLocal = this.url + `/${sourceCode}`;
    return this.dataService.getData<BaseListResponse<AuditTrailDto>>(newLocal, this.apiOptions) as Observable<
      BaseListResponse<AuditTrailDto>
    >;
  }

  public checkType(dataType: DataTypeEnum) {
    return dataType === DataTypeEnum.AuditTrailAcl;
  }

  public checkHideLable(lable: string) {
    const lcLable = lable.toLowerCase();
    return lcLable === 'acl' || lcLable === 'recordstatus' || lcLable === 'state';
  }

  public checkRemoveFields(fieldname: string) {
    const f = fieldname.toLowerCase();
    return f != 'version';
  }

  public checkSpecialFields(
    res: any,
    mappedFields: {
      [key: string]: IFieldDefinition;
    }
  ) {
    let results = [...res?.data];

    for (let i = 0; i < results.length; i++) {
      const element = results[i];

      let parsedData = new Set<DataItem>();

      element.extraData.forEach((x) => {
        try {
          if (this.checkRemoveFields(x.fieldName ?? '')) {
            parsedData.add({
              fieldName: x.fieldName,
              oldValue: isNullObj(x.oldValue) ? null : JSON.parse(x.oldValue),
              newValue: isNullObj(x.newValue) ? null : JSON.parse(x.newValue),
            });
          } else {
            results[i].version = parseInt(x.newValue ?? x.oldValue ?? '0');
          }
        } catch (e) {
          console.error('Error parsing field : ', x);
          parsedData.add({
            fieldName: x.fieldName,
            oldValue: x.oldValue,
            newValue: x.newValue,
          });
        }
        results[i].extraData = new Set<DataItem>([...parsedData]);
      });

      let newExtraData = new Set<DataItem>();

      element.extraData.forEach((x) => {
        if (mappedFields[x.fieldName]) {
          if (mappedFields[x.fieldName].dataType === DataTypeEnum.AuditTrailDynamics) {
            this.processDynamicsData(x, i, results, newExtraData);
          }
          // Update Results with New Extra Data
          results[i].extraData = new Set<DataItem>([...results[i].extraData, ...newExtraData]);
        }
      });
    }

    return results;
  }

  processDynamicsData(x: any, index: number, results: any[], newExtraData: Set<DataItem>): void {
    try {
      results[index].extraData = new Set<DataItem>(
        [...results[index].extraData].filter((v) => v.fieldName !== x.fieldName)
      );

      const dynamicsNew = isNullObj(x.newValue) ? {} : JSON.parse(x.newValue);
      const dynamicsOld = isNullObj(x.oldValue) ? {} : JSON.parse(x.oldValue);
      let dNewEntries = Object.entries(dynamicsNew);
      let dOldEntries = Object.entries(dynamicsOld);
      let newMap: Map<any, any> = new Map<any, any>();
      let oldMap: Map<any, any> = new Map<any, any>();

      // Setting up the maps
      dNewEntries.forEach((val) => {
        newMap.set(val[0], val);
      });

      dOldEntries.forEach((val) => {
        oldMap.set(val[0], val);
      });

      // Construct created
      dNewEntries.forEach((val) => {
        const obj: any = val[1];

        if (!oldMap.has(val[0])) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: '',
            newValue: obj?.value ?? '',
          });
        }
      });

      // Construct Updated
      dOldEntries.forEach((val) => {
        const obj: any = val[1];
        if (newMap.has(val[0]) && newMap.get(val[0])[1].value != obj.value) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: obj?.value ?? '',
            newValue: newMap.get(val[0])[1].value ?? '',
          });
        } else if (!newMap.has(val[0])) {
          newExtraData.add({
            fieldName: val[0] + '‎',
            oldValue: obj?.value ?? '',
            newValue: '',
          });
        }
      });
    } catch (e) {}
  }
}
