import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  colorScheme: 'light' | 'dark' = 'light';

  constructor() { }
}
