import { Component, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-ip-input',
  templateUrl: './ip-input.component.html',
  styleUrls: ['./ip-input.component.scss'],
  //providers: [{ provide: DynamicComponentBase, useExisting: IpInputComponent }]
})
export class IpInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  value: string = '...';
  disabledBlocks: boolean[] = [false, false, false, false];
  // ipv4Regex: RegExp = /^(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(?:\/(3[0-2]|[1-2]?[0-9]))?$/;
  constructor() {
    super();
  }

  ngOnInit(): void { }
  setInputOptions() {
    this.setValue();
    this.setDisabledBlocks();
  }

  setDisabledBlocks() {
    if (this.inputOptions.networkInput.disabledBlocks) {
      this.inputOptions.networkInput.disabledBlocks.forEach((x) => {
        this.disabledBlocks[x - 1 < 0 ? 0 : x - 1] = true;
      });
    }
  }

  setValue() {
    if (
      this.inputOptions.networkInput.defaultBlockValues &&
      this.inputOptions.networkInput.defaultBlockValues.length >= 4 &&
      !this.control.value
    ) {
      let val = '';

      for (let i = 0; i < 4; i++) {
        const element = this.inputOptions.networkInput.defaultBlockValues[i];
        if (i == 0) val = element;
        else val = val + '.' + element;
      }
      if (val) this.value = val;
    }
  }
}
