import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DraftRiskDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class DraftRiskDataService extends BaseRequestControllerService<DraftRiskDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.risk, '/draft-risks', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'inheritedLikelihood',
      'riskAssessmentRequest',
      'inheritedRiskImpact',
      'inheritedRisk',
      'inheritedRiskAcceptance',
      'residualRiskAcceptance',
      'inheritedClassification',
      'residualClassification',
      'riskThreats',
      'riskVulnerabilities',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`risk/risk-item/list`);
  }
}