@if (users?.length) {
  <p-avatarGroup>
    @for (user of users?.slice(0, maxLength); track trackByFn($index, user)) {
      <p-avatar
        [pTooltip]="customTooltip ?? user.fullName ?? ''"
        tooltipPosition="top"
        shape="circle"
        [class]="'text-white hover:text-white-alpha-80 border-circle ' + user.colorClass"
        [size]="size"
      >
        <span class="text-base">
          {{ user.shortName }}
        </span>
      </p-avatar>
    }
    @if (users?.length == 1 && showName) {
      <span class="ml-2 line-clamp-1-ellipsis">{{ users?.[0]?.fullName }}</span>
    }
    @if (users.length > maxLength) {
      <p-avatar
        [label]="'+ ' + users.slice(maxLength).length"
        shape="circle"
        class="text-white hover:text-white-alpha-80 bg-gray-500 border-circle"
        [pTooltip]="allUserNames || ''"
        tooltipPosition="top"
        [size]="size"
      ></p-avatar>
    }
  </p-avatarGroup>
}
