@if (mode != 'view') {
  <ng-content></ng-content>
} @else {
  @if (label) {
    @if (showLabelInViewMode) {
      <div class="field w-full">
        <div class="flex flex-row md:flex-nowrap flex-wrap gap-2 align-items-start overflow-hidden">
          <span class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full font-semibold text-500">
            {{ label }} {{ labelSuffix }}
          </span>

          <div class="flex gap-2 align-items-start w-full min-w-0">
            <!-- <i class="pi pi-info-circle"></i> -->
            @if (!multiViewFields) {
              <app-view-switch
                class="w-full view-field-mode"
                [data]="data"
                [viewType]="dataType"
                [useField]="true"
                [options]="options"
              ></app-view-switch>
            }
            @if (multiViewFields) {
              <div [class]="multiViewFieldParentStyleClass + ' flex flex-wrap w-full gap-2 min-w-0'">
                @for (item of data; track item) {
                  <app-view-switch
                    [class]="multiViewFieldStyleClass + ' view-field-mode'"
                    [data]="item"
                    [viewType]="dataType"
                    [useField]="true"
                    [options]="options"
                  ></app-view-switch>
                }
              </div>
            }
          </div>
        </div>
        @if (resetFieldCheckVisable) {
          <app-reset-switch-input
            [checked]="!resetableChecked"
            (onChange)="keepValueCallback($event)"
          ></app-reset-switch-input>
        }
      </div>
    } @else {
      <app-view-switch [data]="data" [viewType]="dataType" [options]="options"></app-view-switch>
    }
  } @else {
    <app-view-switch [data]="data" [viewType]="dataType" [options]="options"></app-view-switch>
  }
  <!-- <ng-container *ngIf="label && !instanceInFormRepeater">
  <div class="grid" >
    <span class="font-bold col-4">
      {{label}}:
    </span>
    <div class="col-8">
      <app-view-switch [data]="data" [viewType]="dataType" ></app-view-switch>
    </div>
  </div>
</ng-container> -->
  <!-- <div class="pl-2">
<app-view-switch [data]="data" [viewType]="dataType" ></app-view-switch>
</div> -->
}
