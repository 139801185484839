<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field">
    <div class="p-inputgroup">
      <span
        class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
        [class.p-float-label]="floatLabel"
      >
        @if (!floatLabel) {
          @if (label && !instanceInFormRepeater) {
            <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
              >{{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
              @if (resetFieldCheckVisable) {
                <br />
                <app-reset-switch-input
                  [checked]="!resetable"
                  (onChange)="setFieldResetable($event)"
                ></app-reset-switch-input>
              }
            </label>
          }
        }
        @if (type == 'number') {
          <p-inputNumber
            [inputStyleClass]="'w-full border-noround-right'"
            class="w-full"
            [inputId]="name"
            [formControl]="fControl"
            [mode]="mode"
            [showButtons]="showButtons"
            [maxFractionDigits]="maxFractionDigits"
            [buttonLayout]="buttonLayout"
            [incrementButtonClass]="incrementButtonClass"
            [incrementButtonIcon]="incrementButtonIcon"
            [decrementButtonClass]="decrementButtonClass"
            [decrementButtonIcon]="decrementButtonIcon"
            [min]="min"
            [max]="max"
            [placeholder]="placeholder || label"
          ></p-inputNumber>
        } @else {
          <input
            appMaxCharacterValidator
            style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important"
            [type]="type"
            pInputText
            [formControl]="fControl"
            [placeholder]="placeholder || label"
          />
        }
        @if (floatLabel) {
          @if (label && !instanceInFormRepeater) {
            <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
              >{{ label }}
              @if (isControlRequired) {
                <span class="text-red-500"> *</span>
              }
              @if (resetFieldCheckVisable) {
                <br />
                <app-reset-switch-input
                  [checked]="!resetable"
                  (onChange)="setFieldResetable($event)"
                ></app-reset-switch-input>
              }
            </label>
          }
        }
      </span>
      <button
        type="button"
        pButton
        pRipple
        icon="pi pi-chevron-down p-dropdown-trigger"
        styleClass="p-button-secondary"
        (click)="menu.toggle($event)"
        class="p-dropdown p-button-secondary h-fit"
        style="align-self: end"
      ></button>
    </div>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('email')) {
        <small class="p-error p-invalid">Enter a Valid Email</small>
      }
      @if (control?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
  </div>
  <p-menu #menu [popup]="true" [model]="items" [appendTo]="'body'"></p-menu>
</app-input-view-switch>
