import { Component, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-ip6-input',
  templateUrl: './ip6-input.component.html',
  styleUrls: ['./ip6-input.component.scss'],
})
export class IP6InputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  value: string = ':::::::';
  disabledBlocks: boolean[] = [false, false, false, false, false, false, false, false];
  // ipv6Regex: RegExp = /^(?:(?:[0-9A-F]{1,4}:){5}[0-9A-F]{1,4}|(?:[0-9A-F]{1,4}:){4}:[0-9A-F]{1,4}|(?:[0-9A-F]{1,4}:){3}(?::[0-9A-F]{1,4}){1,2}|(?:[0-9A-F]{1,4}:){2}(?::[0-9A-F]{1,4}){1,3}|[0-9A-F]{1,4}:(?::[0-9A-F]{1,4}){1,4}|(?:[0-9A-F]{1,4}:){1,5}|:(?::[0-9A-F]{1,4}){1,5}|:)|(?:[0-9A-F]{1,4}:){7}[0-9A-F]{1,4}|(?:[0-9A-F]{1,4}:){6}:[0-9A-F]{1,4}|(?:[0-9A-F]{1,4}:){5}(?::[0-9A-F]{1,4}){1,2}|(?:[0-9A-F]{1,4}:){4}(?::[0-9A-F]{1,4}){1,3}|(?:[0-9A-F]{1,4}:){3}(?::[0-9A-F]{1,4}){1,4}|(?:[0-9A-F]{1,4}:){2}(?::[0-9A-F]{1,4}){1,5}|[0-9A-F]{1,4}:(?::[0-9A-F]{1,4}){1,6}|(?:[0-9A-F]{1,4}:){1,7}:|:(?::[0-9A-F]{1,4}){1,7}|::)(?:\/(12[0-8]|1[0-1][0-9]|[1-9]?[0-9]))?$/;
  constructor() {
    super();
  }

  ngOnInit(): void { }
  setInputOptions() {
    this.setValue();
    this.setDisabledBlocks();
  }

  setDisabledBlocks() {
    if (this.inputOptions.networkInput.disabledBlocks) {
      this.inputOptions.networkInput.disabledBlocks.forEach((x) => {
        this.disabledBlocks[x - 1 < 0 ? 0 : x - 1] = true;
      });
    }
  }

  setValue() {
    if (
      this.inputOptions.networkInput.defaultBlockValues &&
      this.inputOptions.networkInput.defaultBlockValues.length >= 8 &&
      !this.control.value
    ) {
      let val = '';

      for (let i = 0; i < 8; i++) {
        const element = this.inputOptions.networkInput.defaultBlockValues[i];
        if (i == 0) val = element;
        else val = val + ':' + element;
      }
      if (val) this.value = val;
    }
  }
}
