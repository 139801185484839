<div
  class="field flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
  [class.flex-column-reverse]="staticUploadWidth"
>
  @if (floatLabel) {
    @if (label && !instanceInFormRepeater) {
      <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
        >{{ label }}
        @if (isControlRequired && viewMode != 'view') {
          <span class="text-red-500"> *</span>
        }

        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    }
  }
  @if (!floatLabel) {
    @if (label && !instanceInFormRepeater) {
      <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
        >{{ label }}
        @if (isControlRequired && viewMode != 'view') {
          <span class="text-red-500"> *</span>
        }

        @if (resetFieldCheckVisable) {
          <br />
          <app-reset-switch-input
            [checked]="!resetable"
            (onChange)="setFieldResetable($event)"
          ></app-reset-switch-input>
        }
      </label>
    }
  }

  @if (!dragAndDropMode) {
    <div class="col-12 md:col-6 p-0">
      <app-input-view-switch
        [showLabelInViewMode]="showLabelInViewMode"
        [mode]="viewMode"
        [data]="data || control?.value"
        [dataType]="dataType"
        [label]="label"
        [keepValueCallback]="setFieldResetable.bind(this)"
        [resetFieldCheckVisable]="resetFieldCheckVisable"
        [resetableChecked]="resetable"
      >
        <ng-container>
          <app-basic-input
            [label]="''"
            [placeholder]="'Enter Document Url'"
            [control]="fControl"
            [viewMode]="viewMode"
          ></app-basic-input>
        </ng-container>
      </app-input-view-switch>
    </div>
  }
  @if (!dragAndDropMode && viewMode != 'view') {
    <div class="col-12 md:col-6 flex gap-2 py-0 px-3">
      <p-fileUpload
        [mode]="mode"
        name="documentUrl[]"
        [styleClass]="'px-3'"
        [chooseLabel]="'Browse'"
        [multiple]="multiple"
        [accept]="accept"
        [maxFileSize]="maxFileSize"
        [fileLimit]="fileLimit"
        (uploadHandler)="onBasicUpload($event)"
        [customUpload]="true"
        (onSelect)="onSelectFile()"
        #fileBrowser
      ></p-fileUpload>
      @if (showCancelFile) {
        <app-button [action]="cancelFileSelectionAction" [commandData]="fileBrowser"></app-button>
      }
    </div>
  }
  @if (dragAndDropMode && viewMode != 'view') {
    <input
      appMaxCharacterValidator
      type="file"
      (change)="fileChangeEvent($event)"
      #fileInput
      style="display: none"
      [multiple]="multiple"
      [accept]="accept"
    />
    @if (uploadRequestList && (uploadRequestList | keyvalue)?.length > 0) {
      <div
        class="flex flex-row flex-wrap gap-1"
        [class.mt-3]="staticUploadWidth"
        [class.w-12]="staticUploadWidth"
        [class.w-fit]="!staticUploadWidth"
      >
        @for (item of uploadRequestList | keyvalue; track item) {
          <app-attachment-item-view
            [progressBg]="item?.value?.inProgress ? 'bg-green-400' : ''"
            [extraText]="item?.value?.inProgress ? 'Uploading...' : ''"
            [data]="item?.value?.requestResult"
            [externalCode]="externalCode"
            [progress]="item?.value?.progress"
            [inProgress]="item?.value?.inProgress"
            (onCancelRequest)="onCancelUpload(item?.key)"
            (onDeleteItem)="onDeleteItem($event, item)"
          ></app-attachment-item-view>
        }
      </div>
    }
    @if (!staticUploadWidth && fControl.value) {
      <div
        class="w-12 flex flex-row flex-wrap gap-1"
        [class.mt-3]="staticUploadWidth"
        [class.w-12]="staticUploadWidth"
        [class.w-fit]="!staticUploadWidth"
      >
        @for (item of attachmentsFromFormControl; track item) {
          <app-attachment-item-view
            [data]="{ document: item }"
            [externalCode]="externalCode"
            [progress]="item?.value?.progress"
            [inProgress]="item?.value?.inProgress"
            (onCancelRequest)="onCancelUpload(item?.key)"
            (onDeleteItem)="onDeleteItemByDocumentCode(item)"
            [preivewMode]="preivewMode"
            [style]="preivewMode == 'inline' ? 'width:100%;' : ''"
          ></app-attachment-item-view>
        }
      </div>
    }
    <div
      class="max-h-12rem h-12rem"
      [class.w-12]="staticUploadWidth || !fControl.value || !((uploadRequestList | keyvalue)?.length > 0)"
      [class.w-9rem]="!staticUploadWidth && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)"
      [class.min-w-9rem]="!staticUploadWidth && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)"
    >
      <div
        id="file-drop-zone"
        class="border-2 border-dashed border-round max-h-full h-full flex flex-column gap-3 justify-content-center align-items-center cursor-pointer text-center hover:bg-black-alpha-10 relative"
        [class.border-red-400]="control?.dirty && control.invalid"
        [class.surface-border]="!(control?.dirty && control.invalid)"
        [class.bg-black-alpha-10]="isDragOverTarget"
        (click)="fileInput.click()"
      >
        <i class="pi pi-cloud-upload text-7xl pointer-events-disabled select-none text-400"></i>
        @if (!isDragOverTarget) {
          <p class="text-2xl pointer-events-disabled select-none text-400">
            {{
              staticUploadWidth
                ? 'Drag and drop here or'
                : this.multiple && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)
                  ? 'Add'
                  : !this.multiple && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)
                    ? 'Replace'
                    : 'Drag and drop here or'
            }}
            <span class="text-primary">
              {{
                staticUploadWidth
                  ? 'Browse'
                  : this.multiple && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)
                    ? '+'
                    : !this.multiple && (fControl.value || (uploadRequestList | keyvalue)?.length > 0)
                      ? ''
                      : 'Browse'
              }}</span
            >
          </p>
        }
        @if (isDragOverTarget) {
          <p class="text-2xl pointer-events-disabled select-none text-400">Drop Here!</p>
        }
        <div
          class="absolute top-0 left-0 h-full w-full z-2"
          (dragover)="dragOver($event)"
          (dragleave)="dragLeave($event)"
          (drop)="drop($event)"
        ></div>
      </div>
    </div>
    <!-- <app-attachment-item-list-view
          [uploadRequestList]="uploadRequestList"
          [externalCode]="externalCode"
        ></app-attachment-item-list-view> -->
  }
  @if (viewMode == 'view') {
    @if (fControl.value) {
      <div class="w-12 flex flex-row flex-wrap gap-1">
        @for (item of attachmentsFromFormControl; track item) {
          <app-attachment-item-view
            [data]="{ document: item }"
            [externalCode]="externalCode"
            [progress]="item?.value?.progress"
            [inProgress]="item?.value?.inProgress"
            [showDeleteButton]="false"
            (onCancelRequest)="onCancelUpload(item?.key)"
            (onDeleteItem)="onDeleteItemByDocumentCode(item)"
            [preivewMode]="preivewMode"
            [style]="preivewMode == 'inline' ? 'width:100%;' : ''"
          ></app-attachment-item-view>
        }
      </div>
    }
  }
</div>
@if (control?.dirty) {
  @if (control?.hasError('required')) {
    <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
  }
  @if (control?.hasError('pattern')) {
    <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
  }
  @if (control?.hasError('minlength')) {
    <small class="p-error p-invalid">
      {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
    </small>
  }
  @if (control?.hasError('maxlength')) {
    <small class="p-error p-invalid">
      {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
    </small>
  }
  @if (control?.hasError('min')) {
    <small class="p-error p-invalid">
      {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
    </small>
  }
  @if (control?.hasError('max')) {
    <small class="p-error p-invalid"> {{ label }} must be less than or equal to {{ control?.errors?.max?.max }} </small>
  }
  @if (customError) {
    <small class="p-error p-invalid">{{ customErrorLabel }}</small>
  }
}
